import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="text-center py-32 flex flex-col justify-center items-center">
      <span className="text-blue-500 opacity-75 top-1/2 my-0 mx-auto block">
        <i className="fas fa-spinner fa-spin fa-5x"></i>
      </span>
      <p className="pt-5 text-3xl text-blue-400 font-bold">
        Now loading, please wait....
      </p>
    </div>
  );
};

export default LoadingSpinner;
