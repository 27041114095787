import React, { useState, useEffect } from "react";

import useYup from "@usereact/use-yup";
import * as yup from "yup";
import app from "../config/firebase";

import Footer from "../components/Footer.js";
import NavBar from "../components/Navbar";
import swal from "sweetalert";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object().shape({
  driver_name: yup.string().required("Field is required"),
  driver_email: yup.string().email().required("Field is required"),
  driver_address: yup.string().min(10).required("Field is required"),
  driver_phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  driver_license: yup.string().required("Field is required"),
  driver_plate: yup.string().required("Field is required"),
  driver_model: yup.string().required("Field is required"),
});

const DriverPage = () => {
  const initialState = {
    driver_name: "",
    driver_email: "",
    driver_address: "",
    driver_phone: "",
    driver_license: "",
    driver_plate: "",
    driver_model: "",
  };
  const [values, setValues] = useState(initialState);

  const [formError, setFormError] = useState("");
  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setValues({
      ...values,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormError("");
    setValues(initialState);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    validate();

    if (Object.keys(errors).length < 1) {
      try {
        // handleDriverRegister(values);

        const db = app.firestore();
        const { driver_email } = values;

        let ref = db.collection("drivers").where("driver_email", "==", driver_email);

        ref.get().then(async (record) => {
          if (!record.empty) {
            swal(
              "Thank You",
              "Your application has already submitted and is being reviewed.",
              "info"
            );
          } else {
            let ref = db.collection("drivers");
            await ref.add(values);
            swal(
              "Registration Complete",
              "Your application has been submitted.",
              "success"
            );
          }
        });

        resetForm();
      } catch (error) {
        setFormError("An error has occurred, please try again");
      }
    } else {
      setFormError("Please fill in all required fields");
    }
  };

  return (
    <div className="h-screen">
      <NavBar />
      <main className="w-full">
        <div
          className="mt-16 mx-auto flex text-center justify-center items-center"
          style={{
            minHeight: 200,
          }}
        >
          <div className="px-3">
            <h1 className="text-gray-800 font-normal text-5xl md:text-6xl">
              Become a Driver
            </h1>
          </div>
        </div>
        <div
          className="bg-white flex items-center flex-1 w-full pb-32"
          style={{ minHeight: "500px" }}
        >
          <div className="w-10/12 md:w-8/12 mx-auto">
            <div className="py-6">
              <div className="text-gray-800 text-xl text-center">
                Have the freedom to choose when to work.
              </div>
            </div>
            <div className="w-full py-3 px-3 md:px-10 text-left shadow-lg rounded-lg">
              {formError && (
                <div
                  className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Error!</strong>
                  <span className="block sm:inline"> {formError}</span>
                </div>
              )}

              <form className="text-left" onSubmit={handleSubmit}>
                <h6 className="text-xl font-bold mb-3">Personal Information</h6>

                <div className="relative w-full mb-3 mt-8">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="driver_name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-800 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Enter your fullname"
                    onChange={(e) => handleChange(e)}
                    value={values.driver_name}
                    name="driver_name"
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.driver_name && (
                    <p className="text-red-500 text-xs italic">{errors.driver_name}</p>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="driver_address"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    name="driver_address"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Enter a valid address"
                    value={values.driver_address}
                    onChange={(e) => handleChange(e)}
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.driver_address && (
                    <p className="text-red-500 text-xs italic">{errors.driver_address}</p>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="driver_phone"
                  >
                    Phone
                  </label>
                  <input
                    type="text"
                    name="driver_phone"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Enter a valid phone number"
                    value={values.driver_phone}
                    onChange={(e) => handleChange(e)}
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.driver_phone && (
                    <p className="text-red-500 text-xs italic">{errors.driver_phone}</p>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="driver_email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="driver_email"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Enter a valid email"
                    value={values.driver_email}
                    onChange={(e) => handleChange(e)}
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.driver_email && (
                    <p className="text-red-500 text-xs italic">{errors.driver_email}</p>
                  )}
                </div>

                <h6 className="text-xl font-bold mb-3">Vehicle Information</h6>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="driver_license"
                  >
                    Drivers Licence Number
                  </label>
                  <input
                    type="text"
                    name="driver_license"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Enter a valid licence number"
                    value={values.driver_license}
                    onChange={(e) => handleChange(e)}
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.driver_license && (
                    <p className="text-red-500 text-xs italic">{errors.driver_license}</p>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="driver_model"
                  >
                    Vehicle Model
                  </label>
                  <input
                    type="text"
                    name="driver_model"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Enter a valid vehicle model"
                    value={values.driver_model}
                    onChange={(e) => handleChange(e)}
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.driver_model && (
                    <p className="text-red-500 text-xs italic">{errors.driver_model}</p>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="driver_plate"
                  >
                    Vehicle Licence Plate Number
                  </label>
                  <input
                    type="text"
                    name="driver_plate"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Enter a valid plate number"
                    value={values.driver_plate}
                    onChange={(e) => handleChange(e)}
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.driver_plate && (
                    <p className="text-red-500 text-xs italic">{errors.driver_plate}</p>
                  )}
                </div>

                <div className="text-center mt-6">
                  <button
                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="submit"
                    style={{ transition: "all .15s ease" }}
                  >
                    Apply to be a Driver
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DriverPage;
