import React, { useState, useEffect } from "react";

export const GroceryItem = ({
  list,
  item,
  handleIncrease,
  handleDecrease,
  onRemoveItem,
}) => {
  const [product, setProduct] = useState(item);

  useEffect(() => {
    setProduct(item);
  }, [item]);

  const onIncrease = () => {
    const { quantity } = product;

    handleIncrease({
      ...item,
      quantity: quantity + 1,
    });
  };

  const onDecrease = () => {
    const { quantity } = product;

    if (quantity - 1 < 1) {
      return;
    }

    handleDecrease({
      ...item,
      quantity: quantity - 1,
    });
  };

  return (
    <div className="flex items-center mx-3 py-1">
      <p className="flex-wrap capitalize text-sm mr-2">
        <span className="text-xs block">{product.name.toLowerCase()}</span>
        {/* <br /> */}
        {parseFloat(product.cost) > 0 && "$" + parseFloat(product.cost).toFixed(2)}
      </p>
      <div className="flex-1 flex justify-end">
        <div className="w-20 bg-gray-300 rounded p-2 flex justify-center content-center items-center">
          <button
            // className="text-xl font-bold uppercase px-2 py-2 rounded lg:mr-1 lg:mb-0 ml-3 mb-3 hover:text-blue-500 active:text-blue-700"
            className="text-sm font-bold uppercase"
            onClick={onIncrease}
          >
            <i className="fas fa-plus"></i>
          </button>
          <span className="text-center px-2">{product.quantity}</span>
          <button className="text-sm font-bold uppercase" onClick={onDecrease}>
            <i className="fas fa-minus"></i>
          </button>
        </div>
        <button
          className="text-sm text-red-500 pl-3 font-bold uppercase"
          onClick={() => onRemoveItem(product.id)}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>

      {/* <div className="flex-none">
        <button
          className=" text-xl text-red-300 hover:text-red-500 font-bold uppercase px-2 py-2 rounded mr-3 lg:mb-0 ml-3 mb-3"
          onClick={() => onRemoveItem(product.id)}
        >
          <i className="fas fa-times"></i>
        </button>
      </div> */}
    </div>
  );
};
