import useYup from "@usereact/use-yup";
import { format, parse } from "date-fns";
import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import swal from "sweetalert2";
import ReactToPrint from "react-to-print";

import * as yup from "yup";
// import ReactExport from "react-data-export";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

import LoadingSpinner from "../components/LoadingSpinner";
import MerchantForm from "../components/MerchantForm";
import Modal from "../components/Modal";
import OrderDetails from "../components/OrderDetails";
import OrderTable from "../components/OrderTable";

import Table from "../components/DataTable";

import Page404 from "../components/Page404";

import ProductsList from "../components/ProductsList";
import Categories from "../components/Categories";
import CRUDTable from "../components/CRUDTable";

import firebase from "../config/firebase";
import { AuthContext } from "../context/Auth";

import ExportXlsx from "../components/ExportXlsx";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const validationSchema = yup.object().shape({
  order_cost: yup.number().min(0).required(),
  order_status: yup.string().required(),
});

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <div className="inline-block relative">
      <select
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        value={filterValue}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
}

const parishesArray = [
  "Christ Church",
  "St Philip",
  "St Michael",
  "St George",
  "St John",
  "St James",
  "St Thomas",
  "St Joseph",
  "St Peter",
  "St Andrew",
  "St Lucy",
];

const Dashboard = (props) => {
  const auth = useContext(AuthContext);
  const { currentUser } = auth;
  const { pathname } = useLocation();

  const isAdmin = currentUser.admin && pathname === "/users/dashboard";

  const [order, selectOrder] = useState(null);
  const [merchant, selectMerchant] = useState(null);
  const [filters, setFilters] = React.useState([]);
  const [parishFilter, setParishFilter] = useState("Christ Church");
  const [fileDetails, setDetails] = useState(null);
  const [tab, setTab] = useState(0);
  const [orderList, setOrderList] = useState(null);
  const [orderGroups, setOrderGroups] = useState(null);
  const [loadingOrders, setloadingOrders] = useState(false);
  const [merchantList, setMerchantList] = useState(null);
  const [formValues, setFormValues] = useState({ cost: "", status: "" });
  const [isModalOpen, toggleModal] = useState(false);
  const [showPrintModal, togglePrintModal] = useState(false);
  const [showMerchantModal, toggleMerchantModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [showAddCatgory, setShowAddCategory] = useState(false);
  const [products, setProducts] = useState(null);

  const tabsList = ["Orders", "Merchants", "Categories", "Countries"];

  const { validate } = useYup(formValues, validationSchema, {
    validateOnChange: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Parent Component
  const callbackCloseModal = () => {
    toggleMerchantModal(false);
    fetchData();
  };

  const updateStatus = (e) => {
    const { name, checked } = e.target;
    let statusFlag = order.status;
    if (checked) {
      statusFlag = name.replace("status_", "");
    } else {
      //Check if other flags are set?
      if (formValues.status_approved && name !== "status_approved") {
        statusFlag = "approved";
      }
      if (formValues.status_packaged && name !== "status_packaged") {
        statusFlag = "packaged";
      }
      if (formValues.status_collect && name !== "status_collect") {
        statusFlag = "collect";
      }
      if (formValues.status_delivery && name !== "status_delivery") {
        statusFlag = "delivery";
      }
      if (formValues.status_completed && name !== "status_completed") {
        statusFlag = "completed";
      }
    }
    // const statusFlag = name.replace('status_', '')
    // validate();
    setFormValues({
      ...formValues,
      order_status: statusFlag,
      [name]: checked,
    });
  };

  const fetchData = async () => {
    setloadingOrders(true);
    setOrderList([]);

    const db = firebase.firestore();
    //const ref = db.collection("customers")

    let merchantData = await db
      .collection("merchants")
      .orderBy("dateAdded", "desc")
      .get();
    let merchantList = merchantData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setMerchantList(merchantList);

    let customerData = await db.collection("customers").get();
    let customerList = customerData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    let orderData = await db.collection("orders").orderBy("dateAdded", "desc").get();
    let orderList = orderData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    let itemData = await db.collectionGroup("items").get();
    let itemList = itemData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    var merchantGroups = await groupBy(itemList, "merchantId");
    console.log(merchantGroups, Object.values(merchantGroups).length);

    console.time("time to load orders");

    const ordersGroupedByMerchant = Object.entries(merchantGroups)
      .map((item) => {
        // console.log("merchant id", item[0]);
        const orderGroups = groupBy(item[1], "orderId");
        // console.log(orderGroups);
        // console.log({ ...Object.values(orderGroups) });
        // console.log(Object.values(orderGroups)[0]);

        let merchantInfo = merchantList.filter((merchant) => merchant.id === item[0])[0];
        // let merchantId = item[0]
        let arrayInfo = [];
        Object.keys(orderGroups).forEach((item) => {
          // console.log(item)
          let orderInfo = orderList.filter((order) => order.id === item)[0];
          if (
            orderInfo.status !== "pending" &&
            orderInfo.status !== "completed" &&
            orderInfo.status !== "cancelled"
          ) {
            let customerInfo = customerList.find(
              (customer) => customer.id === orderInfo.customerId
            );

            orderInfo.order_country =
              orderInfo.order_country || customerInfo.user_country || "";

            orderInfo.order_contact_parish =
              orderInfo.order_contact_parish || customerInfo.user_parish || "";

            customerInfo.user_country = customerInfo.user_country
              ? customerInfo.user_country
              : "";
            // Get the items ordered for this order and merchant
            let cart_elements = merchantInfo
              ? itemList.filter(
                  (item) =>
                    item.orderId === orderInfo.id && item.merchantId === merchantInfo.id
                )
              : itemList.filter((item) => item.orderId === orderInfo.id);
            arrayInfo.push({
              // cart_items: { ...Object.values(customerGroups) }[0],
              ...orderInfo,
              cartItems: cart_elements,
              customer: customerInfo,
              customerName: customerInfo?.user_name,
              merchantName: merchantInfo ? merchantInfo.companyName : "None",
              merchantInfo,
            });
          }
        });

        return arrayInfo;
      })
      .flat(2)
      .sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)); //Flattening an array reduces it from multi-dimensional to uni-dimensional

    setOrderGroups(ordersGroupedByMerchant);

    await orderList.map(async (currentOrder) => {
      currentOrder.customer = customerList.filter(
        (customer) => customer.id === currentOrder.customerId
      )[0];
      currentOrder.cartItems = itemList.filter(
        (item) => item.orderId === currentOrder.id
      );

      setOrderList((orders) => [...orders, currentOrder]);
    });
    console.timeEnd("time to load orders");
    setloadingOrders(false);
  };

  let groupBy = function (array, key) {
    return array.reduce(function (result, item) {
      (result[item[key]] = result[item[key]] || []).push(item);
      // (result[] = result[]).push(item);
      return result;
    }, {});
  };

  const fetchInventory = async (merchantId) => {
    const db = firebase.firestore();

    const inventoryData = await db
      .collection("merchants")
      .doc(merchantId)
      .collection("inventory")
      .get();

    let inventory = inventoryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setProducts(inventory);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleModal = () => {
    // fetchData()
    selectOrder(null);
    selectMerchant(null);
    toggleModal(!isModalOpen);
  };

  const handleCancelOrder = async () => {
    // toggleCancelModal(!isCancelModalOpen)

    const db = firebase.firestore();
    //Update order information
    swal
      .fire({
        title: "<p style='color:#d33'>Are you sure?</p>",
        text: "Once deleted, you will not be able to recover this record!",
        icon: "error",
        confirmButtonColor: "#d33",
      })
      .then(async (value) => {
        if (value) {
          try {
            // Modify order status to cancelled
            await db.collection("orders").doc(order.id).update({ status: "cancelled" });

            //Send email notifying customer
            await axios.post(
              process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/sendnotification",
              {
                email: order.customer.user_email,
                status: "cancelled",
                cost: 0,
                orderno: order.id,
                title: "WiFetch: Order Declined",
              }
            );
            //Refresh data
            fetchData();

            toggleModal(!isModalOpen);
          } catch (error) {
            // alert(error);
            console.error(error);
            // setFormError('Sorry but we do not recognize that email/password combination. Please try again')
          }
        }
      });
  };

  const handleSaveOrder = async () => {
    const db = firebase.firestore();
    //Update order information
    // return;
    //Prompt user for cost if they chose to send a request for payment
    if (formValues.order_status === "packaged") {
      //Prompt user

      swal
        .fire({
          text: "Please confirm the price to be paid for this order",
          input: "text",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Save",
          confirmButtonAriaLabel: "Save",
          cancelButtonText: "Cancel",
          cancelButtonAriaLabel: "Cancel",
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (parseFloat(value) > 1) {
                resolve();
              } else {
                resolve("Please enter a valid number greater than 1");
              }
            });
          },
        })
        .then(async (result) => {
          // if(value)
          if (!result.value || !Number(result.value)) {
            return;
          }
          // return;
          const orderFee = parseFloat(result.value);
          try {
            await db.collection("orders").doc(order.id).update({
              status: formValues.order_status,
              status_confirmed: formValues.status_confirmed,
              status_approved: formValues.status_approved,
              status_packaged: formValues.status_packaged,
              status_collect: formValues.status_collect,
              status_delivery: formValues.status_delivery,
              cost: orderFee,
            });

            toggleModal(!isModalOpen);

            await axios.post(
              process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/sendnotification",
              {
                email: order.customer.user_email,
                status: formValues.order_status,
                cost: orderFee,
                orderno: order.id,
                title: "WiFetch: Order Status Updated",
              }
            );
            fetchData();

            toggleModal(!isModalOpen);
          } catch (error) {
            console.error(error);
          }
        });
    } else {
      try {
        await db.collection("orders").doc(order.id).update({
          status: formValues.order_status,
          status_confirmed: formValues.status_confirmed,
          status_approved: formValues.status_approved,
          status_packaged: formValues.status_packaged,
          status_collect: formValues.status_collect,
          status_delivery: formValues.status_delivery,
        });

        toggleModal(!isModalOpen);

        if (formValues.order_status !== "completed") {
          //Send mail according to order status
          await axios.post(
            process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/sendnotification",
            {
              email: order.customer.user_email,
              status: formValues.order_status,
              cost: 0,
              orderno: order.id,
              title: "WiFetch: Order Status Updated",
            }
          );
        }

        fetchData();

        toggleModal(!isModalOpen);
      } catch (error) {
        console.error(error);
      }
    }
    return;
  };

  const handleAddMerchant = async (data) => {
    // e.preventDefault();

    // validate();
    const db = firebase.firestore();
    const ref = db.collection("merchants");

    try {
      //If user is an admin, add the merchant with a status of approved

      if (currentUser && currentUser.admin) {
        data.status = "approved";
        const { id } = await ref.add(data);
        swal
          .fire(
            "Vendor approved",
            "Your invitation has been sent. The vendor will get a notification to register for the store.",
            "success"
          )
          .then(async (result) => {
            if (currentUser && currentUser.admin) {
              firebase
                .auth()
                .currentUser.getIdTokenResult()
                .then(async (idTokenResult) => {
                  await axios.post(
                    process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/admin/sendinvite",
                    {
                      merchant_email: data.companyEmail,
                      merchant_name: data.contactName,
                      merchant_id: id,
                      merchant_status: "invited",
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${idTokenResult.token}`,
                      },
                    }
                  );
                });
            }

            // resetForm();
            // handleClose();
          });

        //Else just add merchant with status of pending
      } else {
        data.status = "pending";
        await ref.add(data);
        swal
          .fire(
            "Registration Complete",
            "Your application has been submitted. Verification can take approximately two(2) business days.",
            "success"
          )
          .then((result) => {
            props.history.push("/");
          });
      }
    } catch (error) {
      console.error("Error occurred adding merchant", error);
    }
  };

  const handleUpdateMerchant = async (data, doSendInvite = true) => {
    // e.preventDefault();

    // validate();
    const db = firebase.firestore();
    const ref = db.collection("merchants").doc(data.id);
    // console.log(data)
    // return;
    try {
      await ref.update(data);

      if (data.status === "approved" && doSendInvite) {
        swal
          .fire(
            "Success!",
            "Thanks, an email has been sent to instruct them to get started adding to their inventory!",
            "success"
          )
          .then(async (value) => {
            // Send email notifying customer
            firebase
              .auth()
              .currentUser.getIdTokenResult()
              .then(async (idTokenResult) => {
                await axios.post(
                  process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/admin/sendinvite",
                  {
                    merchant_email: data.companyEmail,
                    merchant_name: data.contactName,
                    merchant_id: data.id,
                    merchant_status: "invited",
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${idTokenResult.token}`,
                    },
                  }
                );
              });

            //Refresh data
            fetchData();
            toggleMerchantModal(false);
            // toggleModal(!isModalOpen);
          });
      } else {
        swal
          .fire("Update Complete", "Vendor has successfully been updated.", "success")
          .then(() => {
            fetchData();
            toggleMerchantModal(false);
          });
      }

      // fetchData();
    } catch (error) {
      console.error("Error occurred updating vendor", error);
    }
  };

  const generateSummary = (filterText) => {
    console.time("generate summary");
    const newOrdersList = orderList;
    let orders_groupByParish = newOrdersList.filter(
      (item) =>
        item.customer?.user_parish === filterText &&
        item.status !== "cancelled" &&
        item.status !== "completed" &&
        item.status !== "pending"
    );
    // let orders_groupByParish = newOrdersList.filter((item) => item.status !== 'cancelled')
    // console.log(orders_groupByParish)
    const orders_groupByCustomer = groupBy(orders_groupByParish, "customerId");
    // console.log(orders_groupByCustomer)
    const customerOrders_array = Object.keys(orders_groupByCustomer).map((i, index) => ({
      orders: orders_groupByCustomer[i],
      customerId: i,
      customerName: orders_groupByCustomer[i][0].customer.user_name,
      customerEmail: orders_groupByCustomer[i][0].customer.user_email,
      customerHomeNumber: orders_groupByCustomer[i][0].customer.user_home_number,
      customerMobileNumber: orders_groupByCustomer[i][0].customer.user_mobile_number,
      customerAddress: orders_groupByCustomer[i][0].customer.user_address,
      customerParish: orders_groupByCustomer[i][0].customer.user_parish,
      customerDirections: orders_groupByCustomer[i][0].order_directions,
      // customerCoordinates: orders_groupByCustomer[i][0].pickuplocation
      //   ? orders_groupByCustomer[i][0].pickuplocation.join(",") : '',
      customerDropOffLocation: orders_groupByCustomer[i][0].order_dropofflocation
        ? Array.isArray(orders_groupByCustomer[i][0].order_dropofflocation)
          ? orders_groupByCustomer[i][0].order_dropofflocation.join(",")
          : orders_groupByCustomer[i][0].order_dropofflocation
        : "",
    }));
    let orderSummary = [];
    Object.keys(orders_groupByCustomer).map((item) =>
      orderSummary.push(customerOrders_array.filter((elem) => elem.customerId === item))
    );

    setDetails(orderSummary);
    // console.log(orderSummary)
    togglePrintModal(true);
  };

  const openInviteMerchantForm = () => {
    toggleMerchantModal(true);
  };

  const order_Columns = [
    {
      Header: "Date",
      accessor: "dateAdded",
      disableFilters: true,
      Cell: (row, data) =>
        format(
          parse(row.row.original?.dateAdded, "yyyy-MM-dd HH:mm:ss", new Date()),
          "PPpp"
        ),
    },
    {
      Header: "Order #",
      accessor: "id",
    },
    {
      Header: "Customer",
      accessor: "customer.user_name",
      // width: 200,
      filterMethod: (filter, row) =>
        row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
    },
    {
      Header: "Contact",
      id: "customer.contact_numbers",
      disableFilters: true,
      Cell: (row, data) => (
        <p>
          {row.row.original.customer?.user_home_number}
          <br />
          {row.row.original.customer?.user_mobile_number}
        </p>
      ),
      filterMethod: (filter, row) =>
        row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
    },
    {
      Header: "Merchant",
      accessor: "merchantName",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    // {
    //   Header: "Type",
    //   accessor: "type",
    //   Filter: SelectColumnFilter,
    //   filter: "includes",
    // },
    {
      Header: "Parish",
      accessor: "order_contact_parish",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Country",
      accessor: "order_country",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ cell: { row } }) => (
        <div className="flex justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold uppercase text-xs py-2 px-2 rounded shadow"
            onClick={() => {
              selectOrder(row.original);
              setFormValues({
                // order_cost: row.original.cost,
                order_status: row.original.status,
                status_confirmed: row.original.status_confirmed,
                status_approved: row.original.status_approved,
                status_packaged: row.original.status_packaged,
                status_collect: row.original.status_collect,
                status_delivery: row.original.status_delivery,
                status_completed: row.original.status_completed,
              });
              setModalContent({
                modalTitle: "Order Details",
                customerId: row.original.customerId,
                confirmText: "Ok",
              });
              toggleModal(!isModalOpen);
            }}
          >
            View
          </button>
        </div>
      ),
    },
  ];

  const merchantColumns = [
    {
      Header: "Date",
      accessor: "dateAdded",
      disableFilters: true,
      Cell: (row, data) =>
        format(
          parse(row.row.original?.dateAdded, "yyyy-MM-dd HH:mm:ss", new Date()),
          "PPpp"
        ),
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "Contact Person",
      accessor: "contactName",
    },
    {
      Header: "Number",
      accessor: "contactNumber",
      disableFilters: true,
    },
    {
      Header: "Email",
      accessor: "companyEmail",
      disableFilters: true,
    },
    {
      Header: "Logo",
      accessor: "logo",
      disableFilters: true,
      Cell: (row, data) => (
        <div>
          <img
            src={row.row.original.image}
            style={{ height: "100px" }}
            alt="Company Logo"
          />
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (row) => (
        <span
          className={
            row.row.original.status === "approved" ? "text-green-500 font-semibold" : ""
          }
        >
          {row.row.original.status}
        </span>
      ),
      disableFilters: true,
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ cell: { row } }) => (
        <div className="flex justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold uppercase text-xs py-2 px-2 rounded shadow"
            onClick={() => {
              selectMerchant(row.original);

              fetchInventory(row.original.id);

              // setTab(tabsList.length);
              toggleModal(!isModalOpen);
            }}
          >
            View
          </button>
        </div>
      ),
    },
  ];

  //This initial state is used by the Table component as props for the react-table hook
  const initialState = {
    sortBy: [{ id: "dateAdded", desc: true }],
    pageSize: tab === 0 ? 50 : 20,
  };

  const componentRef = useRef();
  const orderRef = useRef();

  // setDetails(groupBy(parishOrders, 'customerId'))
  // console.log(orderList)

  if (!currentUser.admin) {
    return <Page404 />;
  } else {
    return (
      <div className="px-4 mx-auto min-h-full h-auto  bg-white content-center items-center justify-center">
        {orderList && !loadingOrders ? (
          <>
            <h1 className="text-3xl uppercase font-bold text-blue-300 pt-10">
              Admin Dashboard
            </h1>

            <div className="w-full flex flex-wrap">
              <div className="w-full md:w-1/6 overflow-scroll md:overflow-auto">
                <ul className="flex md:flex-col mr-3 border-b-0 md:border border-gray-300">
                  {tabsList.map((item, index) => (
                    <li
                      key={index}
                      className={
                        index === tab
                          ? "py-2 px-6 border text-sm md:border-b border-b-0 border-gray-400 bg-white"
                          : "py-2 px-6 bg-white text-sm border border-gray-400 text-gray-500 bg-gray-200"
                      }
                      onClick={() => setTab(index)}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="w-full md:w-5/6">
                {tab === 0 && (
                  <div className="w-full pt-3">
                    <div className="flex items-center justify-center">
                      <p className="p-3 text-sm hidden md:inline">Search:</p>
                      <Flatpickr
                        // data-enable-time
                        placeholder="Click to search between dates"
                        className="flex-1 shadow appearance-none border rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={filters.filterDates}
                        // maxDate={'2020-04-03'}
                        // options={{ maxDate: format(new Date(), 'yyyy-MM-dd') }}
                        options={{ mode: "range" }}
                        // onChange={date => { console.log(date[0], date[1]) }}
                        onChange={(date) =>
                          setFilters((prevStyle) => ({
                            ...prevStyle,
                            filterDates: date,
                          }))
                        }
                      />
                      <button
                        className="md:m-3 bg-blue-500 text-white active:bg-blue-700 text-sm px-2 md:px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        onClick={() => {
                          generateSummary(parishFilter);
                        }}
                      >
                        Summary
                      </button>
                      <ExportXlsx merchants={merchantList} />
                    </div>

                    {orderGroups && (
                      <Table
                        // title="Order Dashboard"
                        columns={order_Columns}
                        minRows={0}
                        initialState={initialState}
                        data={orderGroups.filter((item) =>
                          filters && filters.filterDates?.length > 1
                            ? Date.parse(item.dateAdded) <
                                Date.parse(filters.filterDates[1]) &&
                              Date.parse(item.dateAdded) >
                                Date.parse(filters.filterDates[0])
                            : item
                        )}
                      />
                    )}
                  </div>
                )}
                {tab === 1 && (
                  <div>
                    <div className="flex justify-end">
                      <button
                        className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        onClick={() => {
                          openInviteMerchantForm();
                        }}
                      >
                        Invite Merchant
                      </button>
                    </div>
                    {merchantList && (
                      <Table
                        // title="Order Dashboard"
                        columns={merchantColumns}
                        minRows={0}
                        initialState={initialState}
                        data={merchantList.filter(
                          (item) => item.status?.toLowerCase() !== "cancelled"
                        )}
                      />
                    )}
                  </div>
                )}

                {tab === 2 && (
                  <div>
                    <div className="flex justify-end">
                      <button
                        className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        onClick={() => setShowAddCategory(true)}
                      >
                        Add Category
                      </button>
                    </div>
                    <Categories
                      showAddCategory={showAddCatgory}
                      setShowAddCategory={setShowAddCategory}
                    />
                  </div>
                )}

                {tab === 3 && (
                  <div>
                    <CRUDTable
                      dataLabel="countries"
                      prefix={{ title: "country" }}
                      displayFields={["country_name"]}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <LoadingSpinner />
        )}

        <Modal
          isOpen={showPrintModal}
          toggleModal={() => togglePrintModal(false)}
          title="Order Summary"
          onConfirm={() => togglePrintModal(false)}
        >
          <ReactToPrint
            trigger={() => (
              <button className="w-full p-3 my-3 text-center text-blue-500 border-t border-b border-gray-400">
                Click to print
              </button>
            )}
            content={() => componentRef.current}
          />
          <div id="divIdToPrint" className="px-2" ref={componentRef}>
            {/* <PrintButton id={"divIdToPrint"} label={"Print single page"} /> */}

            <div className="w-full">
              <ul className="flex cursor-pointer w-full">
                {parishesArray.map((item, index) => (
                  <li
                    key={index}
                    className={
                      item === parishFilter
                        ? "flex-grow py-2 px-6 border text-xs border-b-0 border-gray-400 bg-white rounded-t-lg"
                        : "flex-1 py-2 px-6 bg-white text-xs rounded-t-lg text-gray-500 bg-gray-200"
                    }
                    onClick={() => {
                      setParishFilter(item);
                      generateSummary(item);
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            {fileDetails && fileDetails.length > 0 ? (
              fileDetails.map((item, index) => {
                console.timeEnd("generate summary", item);
                return (
                  <OrderDetails
                    key={index}
                    merchantList={merchantList}
                    orderInfo={item[0]}
                  />
                );
              })
            ) : (
              <p className="text-xl text-center text-gray-600 py-16">
                There are no outstanding orders for {parishFilter}
              </p>
            )}
          </div>
        </Modal>

        <Modal
          title={order ? "Order Info # " + order.id : "Merchant Info"}
          isOpen={isModalOpen}
          toggleModal={handleToggleModal}
          content={modalContent}
          onConfirm={handleToggleModal}
        >
          {merchant && (
            <div>
              <div className="flex py-5 border-b border-gray-400">
                <div className="w-1/4">
                  {/* <label className="block uppercase text-gray-700 text-xs font-bold my-2">
                    Company Logos
                  </label> */}
                  <img
                    src={merchant.image}
                    style={{ height: "200px" }}
                    alt="Company Logo"
                  />
                </div>
                <div className="w-2/4 p-2">
                  <label className="block uppercase text-gray-700 text-lg font-bold my-2">
                    Merchant Information
                  </label>
                  <div className="flex flex-col md:flex-row">
                    <div className="pt-2 text-sm font-normal w-full md:w-1/2">
                      <span className="font-semibold uppercase">Vendor Name</span>
                      <p>{merchant.companyName}</p>
                    </div>
                    <div className="pt-2 text-sm font-normal w-full md:w-1/2">
                      <span className="font-semibold uppercase">Delivery Fee</span>
                      <p>
                        {merchant.deliveryFee
                          ? `$ ${(+merchant.deliveryFee).toFixed(2)} BBD`
                          : "Not listed"}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row">
                    <div className="pt-2 text-sm font-normal w-full md:w-1/2">
                      <span className="font-semibold uppercase">Contact Name</span>
                      <p>{merchant.contactName}</p>
                    </div>
                    <div className="pt-2 text-sm font-normal w-full md:w-1/2">
                      <span className="font-semibold uppercase">Service Fee</span>
                      <p>
                        {merchant.serviceFee || merchant.serviceFee === 0
                          ? `${merchant.serviceFee}%`
                          : "Not listed"}
                      </p>
                    </div>
                  </div>

                  <div className="pt-2 text-sm font-normal">
                    <span className="font-semibold uppercase">Contact #</span>
                    <p>{merchant.contactNumber}</p>
                  </div>
                  {merchant.companyDescription?.length > 0 && (
                    <div className="pt-2 text-sm font-normal">
                      <span className="font-semibold uppercase">Description</span>
                      <p className="text-sm font-normal">{merchant.companyDescription}</p>
                    </div>
                  )}
                </div>
                <div className="flex-1 flex items-start justify-end">
                  {/* {merchant.status === "pending" && (
                    <button
                      className="mr-4 bg-green-500 text-white active:bg-blue-700 font-semibold py-2 px-4 rounded shadow"
                      onClick={() => {
                        swal
                          .fire({
                            title: "Approve Vendor",
                            text: `Are you sure you want to approve ${merchant.companyName}?`,
                            icon: "warning",
                            showCancelButton: true,
                            reverseButtons: true,
                          })
                          .then(({ value }) => {
                            if (value) {
                              handleApproveMerchant();
                            }
                          });
                      }}
                    >
                      Approve
                    </button>
                  )} */}
                  <button
                    // className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    className="bg-blue-500 text-white active:bg-blue-700 font-semibold py-2 px-4 rounded shadow"
                    onClick={() => {
                      // console.log(merchant)
                      //   generateSummary(parishFilter);
                      toggleMerchantModal(!showMerchantModal);
                    }}
                  >
                    Update Info
                  </button>
                </div>
              </div>

              {products && (
                <ProductsList
                  merchantInfo={merchant}
                  products={products}
                  refreshList={fetchData}
                />
              )}

              {/* <div className="container px-4 mb-10 mx-auto px-12 min-h-full h-auto  bg-white content-center items-center justify-center">
                <div className="flex justify-between items-center content-center pt-10">
                  <h1 className="text-xl uppercase font-bold text-blue-400">
                    Manage Inventory
                    </h1>
                  <button
                    className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    onClick={() => {
                      toggleProductModal(!showProductModal);
                    }}
                  >
                    Add Product
                    </button>
                </div>


              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  className="bg-transparent text-red-500 font-normal py-2 px-4 mt-5 rounded"
                  onClick={handleCancelMerchant}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-normal py-2 px-4 mt-5 rounded"
                  onClick={handleApproveMerchant}
                >
                  Approve
                </button>
              </div> */}
            </div>
          )}
          {order && (
            <>
              <ReactToPrint
                trigger={() => (
                  <button className="w-full p-3 my-3 text-center text-blue-500 border-t border-b border-gray-400 capitalize">
                    Print this order
                  </button>
                )}
                content={() => orderRef.current}
              />

              <div className="w-full px-4 pb-3" ref={orderRef}>
                <div className="pt-3">
                  <p className="text-sm">
                    Order # <span className="font-bold underline">{order.id}</span>
                  </p>
                  <p className="text-sm">Date created: {order.dateAdded}</p>
                  <OrderTable
                    merchantList={merchantList}
                    orderInfo={{
                      ...order,
                      customerName: order.customer?.user_name || order.order_contact_name,
                      customerEmail:
                        order.customer?.user_email || order.order_contact_email,
                      customerHomeNumber: order.customer?.user_home_number,
                      customerMobileNumber:
                        order.customer?.user_mobile_number || order.order_contact_number,
                      customerAddress: order.customer?.user_address,
                      customerParish:
                        order.order_contact_parish || order.customer?.user_parish,
                      customerCountry:
                        order.order_country || order.customer?.user_country,
                      customerDirections: order.order_directions,
                      customerDropOffLocation: order.order_dropofflocation
                        ? Array.isArray(order.order_dropofflocation)
                          ? order.order_dropofflocation.join(",")
                          : order.order_dropofflocation
                        : "",
                    }}
                  />
                </div>

                <p className="text-xl text-blue-500 font-normal pt-3">
                  Update Order Status
                </p>
                <p className="text-sm font-normal">
                  Click on the checkboxes below to update the status of the order and
                  notify the customer
                </p>

                <form>
                  <div className="flex flex-row border border-gray-400">
                    <div className="flex-1 text-gray-700 px-4 py-2 m-2">
                      <p className="text-lg font-semibold pb-3">Notify Customer</p>
                      <div className="mb-6">
                        <label className="block text-gray-800">
                          <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            name="status_confirmed"
                            checked={formValues.status_confirmed}
                            onChange={updateStatus}
                            disabled
                          />
                          <span className="text-md">Confirmed</span>
                        </label>
                      </div>
                      <div className="mb-6">
                        <label className="block text-gray-800">
                          <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            name="status_approved"
                            checked={formValues.status_approved}
                            onChange={updateStatus}
                          />
                          <span className="text-md">Send approval message</span>
                        </label>
                      </div>
                      <div className="mb-6">
                        {/* <div className="md:w-1/3"></div> */}
                        <label className="block text-gray-800">
                          <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            name="status_packaged"
                            checked={formValues.status_packaged}
                            onChange={updateStatus}
                          />
                          <span className="text-md">Send request to payment</span>
                          <span className="text-xs block text-blue-500 ml-5">
                            You will need to add a cost
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="flex-1 text-gray-700 px-4 py-2 m-2">
                      <p className="text-lg font-semibold pb-3">Delivery Notification</p>
                      <div className=" mb-6">
                        {/* <div className="md:w-1/3"></div> */}
                        <label className="block text-gray-800">
                          <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            name="status_collect"
                            checked={formValues.status_collect}
                            onChange={updateStatus}
                          />
                          <span className="text-md">Ready to Collect</span>
                        </label>
                      </div>
                      <div className="mb-6">
                        {/* <div className="md:w-1/3"></div> */}
                        <label className="block text-gray-800">
                          <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            name="status_delivery"
                            checked={formValues.status_delivery}
                            onChange={updateStatus}
                          />
                          <span className="text-md">Send en-route message</span>
                        </label>
                      </div>
                      <div className="mb-6">
                        {/* <div className="md:w-1/3"></div> */}
                        <label className="block text-gray-800">
                          <input
                            className="mr-2 leading-tight"
                            type="checkbox"
                            name="status_completed"
                            checked={formValues.status_completed}
                            onChange={updateStatus}
                          />
                          <span className="text-md">Mark completed</span>
                        </label>
                      </div>
                    </div>
                    <div className="flex-1 text-gray-700 px-4 py-2 m-2">
                      <p className="text-sm mb-6">
                        If necessary, please add any additional notes for the driver
                      </p>
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Notes
                      </label>
                      <textarea
                        name="order_notes"
                        value={formValues.order_notes}
                        rows={5}
                        onChange={(e) => handleChange(e)}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Any changes to customer information or their dropoff location?"
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="bg-transparent text-red-500 font-normal py-2 px-4 mt-5 rounded"
                      onClick={handleCancelOrder}
                    >
                      Cancel Order
                    </button>

                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-normal py-2 px-4 mt-5 rounded"
                      onClick={handleSaveOrder}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </Modal>

        <Modal
          medium
          isOpen={showMerchantModal}
          toggleModal={() => toggleMerchantModal(false)}
          title={merchant ? "Update Merchant" : "Invite Merchant"}
          onConfirm={() => toggleMerchantModal(false)}
        >
          <MerchantForm
            editing={merchant && Object.entries(merchant).length > 0 ? true : false}
            merchantInfo={merchant}
            onAdd={handleAddMerchant}
            onEdit={handleUpdateMerchant}
            handleClose={callbackCloseModal}
            isAdmin={isAdmin}
          />
        </Modal>
      </div>
    );
  }
};

export default Dashboard;
