import React from "react";
import firebase from "firebase";
import app from "../config/firebase.js";
import googleButton from "../assets/img/google-button.png";

const SocialLogins = ({ handleFailedLogin }) => {
  const loginWithGoogle = async () => {
    try {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      await app.auth().signInWithPopup(googleProvider);
    } catch (e) {
      //console.log("error occurred", e);
      if (e.code !== "auth/popup-closed-by-user") {
        handleFailedLogin("Failed to login with Google Identification");
      }
    }
  };
  return (
    <button onClick={loginWithGoogle}>
      <img alt="googleSigninButton" src={googleButton} />
    </button>
  );
};

export default SocialLogins;
