import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React from "react";

const CheckoutForm = ({ success, order, errorMsg }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      const { id } = paymentMethod;

      try {
        await axios.post(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/charge", {
          token: { id },
          charge: {
            amount: (parseInt(order.cost) * 100) / 2,
            currency: "USD",
          },
        });
        success();
      } catch (error) {
        errorMsg();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <h2 className="p-3">Price: ${parseFloat(order.cost).toFixed(2)} BBD</h2>

      <CardElement options={{ hidePostalCode: true }} />
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded"
        disabled={!stripe}
      >
        Pay Now
      </button>
    </form>
  );
};

// you should use env variables here to not commit this
// but it is a public key anyway, so not as sensitive
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CardComponent = ({ orderInfo, onSuccess, onError }) => {
  // const [status, setStatus] = React.useState("ready");

  // if (status === "success") {
  //     return <div className="bg-teal-100 border-t-1 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
  //         <div className="flex">
  //             <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
  //             <div>
  //                 <p className="font-bold">Purchase has been completed successfully</p>
  //                 <p className="text-sm">Our delivery couriers will be in touch with you shortly</p>
  //             </div>
  //         </div>
  //     </div>;
  // }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        order={orderInfo}
        // success={() => {
        //     setStatus("success");
        // }}
        success={onSuccess}
        errorMsg={onError}
      />
    </Elements>
  );
};

export default CardComponent;
