import React from "react";
import { MobilePDFReader } from "react-read-pdf";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

import termsofusePDF from "../assets/files/Wifetch_termsofuse.pdf";

export default function TermsOfUse() {
  return (
    <>
      <Navbar />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div className="container relative mx-6 md:mx-16">
            <div className="flex flex-wrap mt-6 rounded shadow-lg p-6 bg-gray-400">
              <div className="w-full flex flex-col content-center items-center justify-center">
                <div className="m-16" style={{ overflow: "scroll", height: 600 }}>
                  <MobilePDFReader url={termsofusePDF} isShowFooter={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
