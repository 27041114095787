import React, { useState, useEffect } from "react";
import parse from "csv-parse";
import firebase from "../config/firebase";
import { format } from "date-fns";
import Table from "../components/DataTable";
import swal from "sweetalert2";
import axios from "axios";

const UploadProducts = ({ merchantId, onClose }) => {
  const [value, setValue] = useState("");
  const [fileData, setFileData] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [exampleFileLink, setExampleFileLink] = useState(null);

  const getExampleLink = () => {
    try {
      const storage = firebase.storage();
      const storageRef = storage.ref("example file.csv");

      storageRef.getDownloadURL().then((url) => setExampleFileLink(url));
    } catch (e) {
      console.log("failed to get example link", e.message);
    }
  };

  useState(() => {
    getExampleLink();
  }, []);

  useEffect(() => {
    //NOTE: used props to get merchant id
    setSelectedMerchant({ id: merchantId });
  }, [merchantId]);

  const capitalizeProductName = (productName) => {
    const lowercase = productName.toLowerCase();
    const capitalize = lowercase.charAt(0).toUpperCase() + lowercase.slice(1);

    return capitalize;
  };

  const sanitizeData = (fileData) => {
    const cleanData = fileData.filter(({ product_title }) => product_title !== "");
    return cleanData;
  };

  const handleUploadedFile = (e) => {
    const { files, value } = e.target;

    setValue(value);
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      const csv = target.result;

      parse(
        csv,
        {
          columns: ["product_title", "product_price", "product_description"],
          from_line: 2,
          cast: (value, context) => {
            console.log("column", context.column);
            if (context.column === "product_title") {
              return capitalizeProductName(value);
            } else if (context.column === "product_price") {
              return (+value).toFixed(2) || value;
            } else {
              return value;
            }
          },
        },
        function (err, output) {
          if (err) {
            swal.fire({
              icon: "error",
              title: "Error",
              text: "There was an error parsing the file",
            });

            return console.log("error occurred", err);
          }

          const cleanData = sanitizeData(output);
          setFileData(cleanData);
        }
      );
    };

    reader.onerror = () => console.log("error reading file contents"); // eslint-disable-line no-console
    reader.onabort = () => console.log("aborted file read"); // eslint-disable-line no-console

    reader.readAsText(files[0]);
  };

  const handleUploadClick = async (e) => {
    e.preventDefault();

    swal
      .fire({
        title: "Upload?",
        text: "Are you sure you want to upload this file?",
        showCancelButton: true,
        confirmButtonText: "Yes, Upload",
      })
      .then((result) => {
        if (result.value) {
          const updatedData = addDateToRecords();
          uploadData(updatedData);
        }
      });
  };

  const addDateToRecords = () =>
    fileData.map((data) => ({
      dateAdded: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      ...data,
    }));

  const uploadData = async (updatedData) => {
    swal.fire({
      title: "Processing upload",
      // text: "Processing upload",
      icon: "info",

      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
    try {
      const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();

      const response = await axios.post(
        process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/admin/uploadproducts",
        {
          list: updatedData,
          merchantid: selectedMerchant.id,
        },
        {
          headers: {
            Authorization: `Bearer ${idTokenResult.token} `,
          },
        }
      );

      if (response.status === 200) {
        swal.close();
        swal.fire({
          icon: "success",
          title: "Success",
          text: "File successfully uploaded",
        });
        onClose();
      }
    } catch (e) {
      console.error("error uploading file", e.message);
      swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error uploading the file",
      });
    }
  };

  const columns = [
    {
      Header: "Product Title",
      accessor: "product_title",
    },
    {
      Header: "Product Price",
      accessor: "product_price",
      disableFilters: true,
    },
    {
      Header: "Product Description",
      accessor: "product_description",
      disableFilters: true,
    },
  ];

  const initialState = {
    sortBy: [{ id: "product_title", desc: true }],
  };

  const isReady = fileData.length !== 0 && selectedMerchant;

  console.log("example link", exampleFileLink);
  return (
    <div className="container mx-auto">
      <div className="flex flex-col">
        <div className="mt-4">
          <p className="mb-2 block text-gray-700 text-lg font-bold">Choose a file</p>
          <p className="text-gray-600 text-sm">
            Please use a file with the correct format.{" "}
            {exampleFileLink && (
              <a
                className="hover:underline text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
                href={exampleFileLink}
              >
                See an example
              </a>
            )}
          </p>
          <input type="file" onChange={handleUploadedFile} value={value} />
          <p className="text-gray-600 text-sm">
            Please note that the file must be in CSV format
          </p>
          {/* {merchantHasInventory && (
            <div className="text-red-500 mt-2">
              Warning: Merchant currently has a product list!
            </div>
          )} */}
        </div>
        <div className="flex justify-end items-center mt-4">
          {!isReady && (
            <p className="text-red-500 text-sm mx-3">
              Please select a file first before clicking upload
            </p>
          )}
          <button
            disabled={!isReady}
            type="button"
            onClick={handleUploadClick}
            className={
              isReady
                ? "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                : "bg-gray-400 text-gray-600 py-2 px-4 rounded"
            }
          >
            Upload
          </button>
        </div>
      </div>

      <div className="mt-8">
        {fileData?.length > 0 && (
          <div className="w-100">
            <Table
              title="Preview File Contents"
              columns={columns}
              minRows={0}
              data={fileData}
              initialState={initialState}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadProducts;
