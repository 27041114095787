/* eslint-disable react-hooks/exhaustive-deps */
import useYup from "@usereact/use-yup";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import * as yup from "yup";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  product_title: yup.string().required("Field is required"),
  product_price: yup
    .string()
    .test("product price", "Must be greater than 1", (value) => Number(value) > 0),
  product_description: yup.string().min(10).required("Field is required"),
  product_photo: yup.object().shape({
    file: yup
      .object()
      .shape({
        name: yup.string(),
        type: yup.string().when("name", (name, schema) => {
          return (
            name &&
            schema.test("fileType", "File must be JPG, JPEG or PNG", (value) =>
              ["image/jpg", "image/jpeg", "image/png"].includes(value)
            )
          );
        }),
      })
      .label("File"),
  }),
});

const ProductForm = (props) => {
  const initialState = {
    product_title: "",
    product_price: 1,
    product_description: "",
  };
  const [values, setValues] = useState(initialState);

  // const [productPhoto, setProductPhoto] = useState({});
  const [image, setImage] = useState(null);

  const [formError, setFormError] = useState("");

  const [isLoading, setLoading] = useState(false);

  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  useEffect(() => {
    // resetForm();
    setValues(props.productInfo ? props.productInfo : initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    //TODO:Add input masking on the telephone numbers

    setValues({
      ...values,
      [name]: value,
    });
    validate();
  };

  const handlePhotoChange = (e) => {
    const { files } = e.target;
    const photoFile = files[0];
    if (photoFile) {
      setValues({
        ...values,
        product_photo: { file: { name: photoFile.name, type: photoFile.type } },
      });
      setImage(photoFile);
      // setImage(URL.createObjectURL(photoFile));
    }
  };

  const resetForm = () => {
    setFormError("");
    setImage(null);
    setValues(initialState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validate();
    // return
    let uploadData = values;

    if (Object.keys(errors).length < 1 && !isLoading) {
      try {
        // eslint-disable-next-line no-empty
        // props.onAdd(uploadData);
        setLoading(true);

        if (props.editing) {
          uploadData.dateUpdated = format(new Date(), "yyyy-MM-dd HH:mm:ss");
          await props.onUpdate(uploadData, image);
        } else {
          uploadData.dateAdded = format(new Date(), "yyyy-MM-dd HH:mm:ss");
          await props.onAdd(uploadData, image);
        }
        resetForm();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setFormError("Please fill in all required fields");
    }
  };

  const handleCancel = () => {
    let uploadData = values;
    if (props.editing) {
      props.onRemove(uploadData.id);
    } else {
      props.onCancel();
    }
    resetForm();
  };

  return (
    // <div className="shadow flex content-center items-center justify-center mt-16 px-3 py-4 bg-white border border-gray-300 rounded">
    <div>
      {formError && (
        <div
          className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {formError}</span>
        </div>
      )}
      <div className="text-left">
        {/* <p className="text-sm py-3">
          Please drag the map marker to indicate the dropoff location
        </p> */}

        <form className="w-full" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3 my-2 md:mb-0">
              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                Product title
              </label>
              <input
                type="text"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Name of product"
                onChange={(e) => handleChange(e)}
                value={values.product_title}
                name="product_title"
                style={{ transition: "all .15s ease" }}
              />
              {errors.product_title && (
                <p className="text-red-500 text-xs">{errors.product_title}</p>
              )}
            </div>

            <div className="w-full px-3 my-2 md:mb-0">
              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                Product price
              </label>
              <input
                type="number"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Price of product"
                onChange={(e) => handleChange(e)}
                value={values.product_price}
                name="product_price"
                style={{ transition: "all .15s ease" }}
              />
              {errors.product_price && (
                <p className="text-red-500 text-xs">{errors.product_price}</p>
              )}
            </div>

            <div className="w-full px-3 my-2 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Product description
              </label>
              <textarea
                name="product_description"
                value={values.product_description}
                onChange={(e) => handleChange(e)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Description of product"
                rows="3"
              ></textarea>
              {errors.product_description && (
                <p className="text-red-500 text-xs italic">
                  {errors.product_description}
                </p>
              )}
            </div>

            <div className="w-full py-3 text-center flex flex-col content-center items-center justify-center bg-grey-lighter">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="company-address"
              >
                Product Photo
              </label>
              {(image || values.image) && !errors.product_photo && (
                <img
                  className="mx-auto"
                  src={image ? URL.createObjectURL(image) : values.image}
                  style={{ width: "100px" }}
                  alt="Preview of product"
                />
              )}
              {/* {values.product_photo && (
                <p className="text-xs">{values.product_photo.file.name}</p>
              )} */}

              {errors.product_photo &&
                values.product_photo &&
                errors.product_photo.file &&
                errors.product_photo.file.type && (
                  <p className="text-red-500 text-xs">{errors.product_photo.file.type}</p>
                )}
              <label className="w-64 flex flex-col items-center my-2 px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                <svg
                  className="w-8 h-8"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span className="mt-2 text-base leading-normal">Upload your logo</span>
                <input
                  name="companyLogo"
                  type="file"
                  className="hidden"
                  onChange={handlePhotoChange}
                />
              </label>
              {errors.product_photo &&
                errors.product_photo.file &&
                errors.product_photo.file.name && (
                  <p className="text-red-500 text-xs">{errors.product_photo.file.name}</p>
                )}
            </div>
          </div>

          <div className="flex justify-between">
            <button
              type="button"
              className="text-red-500 font-bold py-2 px-4 mt-5 rounded"
              onClick={handleCancel}
              disabled={isLoading}
            >
              {props.editing ? "Remove" : "Cancel"}
            </button>

            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProductForm;
