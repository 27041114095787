import React, { useState, useEffect } from "react";
import { format, parse } from "date-fns";
import Table from "./DataTable";
import Modal from "./Modal";
import swal from "sweetalert2";
import firebase from "../config/firebase";

const CRUDTable = ({ dataLabel, prefix, displayFields }) => {
  console.log(dataLabel, prefix, displayFields);

  const dataPrefix = prefix.title;
  const initialValues = {};

  const [categories, setCategories] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  // const [error, setError] = useState(null);
  // const [selectedRow, setSelectedRow] = useState(null);
  const [rowID, setRowID] = useState(null);
  // const [displayName, setDisplayName] = useState("");
  const [fieldValues, setFieldValues] = useState(initialValues);
  // const [showEditCategory, setShowEditCategory] = useState(false);

  useEffect(() => {
    //set categories here
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(6)
        .substring(1);
    };
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + "-" + s4();
  };

  // const clearValues = () => {
  //   setDisplayName("");
  //   setCategoryValue("");
  //   setCategoryID("");
  // };

  const resetForm = () => {
    setRowID(null);

    setFieldValues(initialValues);
  };

  const closeModal = () => {
    setModalVisible(false);
    // setShowEditCategory(false);
    setRowID(null);
    // clearValues();
    resetForm();
  };

  const capitalize = (value) => {
    const lowercase = value.toLowerCase();
    const capitalize = lowercase.charAt(0).toUpperCase() + lowercase.slice(1);

    return capitalize;
  };

  const fetchCategories = async () => {
    console.log("fetching data for " + dataLabel + " collection");
    const firestore = firebase.firestore();
    const categoriesRef = await firestore.collection(dataLabel).get();
    const categoryList = categoriesRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    console.log(categoryList);
    setCategories(categoryList);
  };

  // const validate = (value) => {
  //   if (categoryValue === "") {
  //     return "Field is required";
  //   }

  //   return null;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // validate();
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };

  const showError = (message) => {
    console.error(message);
    swal.fire({
      title: "An error occurred",
      text: "An occurred",
      icon: "error",
    });
  };

  const showSuccess = () => {
    swal.fire({
      title: "Success",
      icon: "success",
    });
  };

  // const isCategoryNew = () => {
  //   const filteredCategories = categories.filter(
  //     ({ category_name }) => category_name === categoryValue
  //   );

  //   if (filteredCategories.length > 0) {
  //     return false;
  //   }

  //   return true;
  // };

  const addCategory = async () => {
    const firestore = firebase.firestore();
    // const updateValues = fieldValues
    try {
      // if (!isCategoryNew()) {
      //   swal.fire({
      //     title: "An error occurred",
      //     text: capitalize(dataPrefix) + " already exists",
      //     icon: "error",
      //   });
      // } else {
      await firestore.collection(dataLabel).add({
        ...fieldValues,
        id: guid(),
        // data_title: categoryValue.toLowerCase(),
        // display_title: displayName,
        dateAdded: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      });

      closeModal();
      showSuccess();
      fetchCategories();
      // }
    } catch (e) {
      showError(e.message);
    }
  };

  const editCategory = async () => {
    const firestore = firebase.firestore();

    try {
      await firestore
        .collection(dataLabel)
        .doc(rowID)
        .update({ ...fieldValues });

      fetchCategories();
      closeModal();
      showSuccess();
    } catch (e) {
      showError(e.message);
    }
  };

  const deleteCategory = async (category_id) => {
    const firestore = firebase.firestore();
    try {
      await firestore.collection(dataLabel).doc(category_id).delete();

      closeModal();
      showSuccess();
      fetchCategories();
    } catch (e) {
      showError(e.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(fieldValues, rowID);
    // return;
    // const validationError = validate(categoryValue);
    // setError(validationError);

    // if (validationError) {
    //   return;
    // }

    if (rowID) {
      editCategory();
    } else {
      addCategory();
    }
    fetchCategories();
  };

  const handleToggleModal = () => {
    console.log(fieldValues);
    setModalVisible(true);
  };

  const columns = [
    {
      Header: "Date Added",
      accessor: "dateAdded",
      disableFilters: true,
      Cell: (row, data) =>
        row.row.original?.dateAdded
          ? format(
              parse(row.row.original?.dateAdded, "yyyy-MM-dd HH:mm:ss", new Date()),
              "PPpp"
            )
          : "",
    },
    {
      Header: "Name",
      accessor: "country_name",
    },
    // {
    //   Header: "Display Name",
    //   accessor: "display_title",
    //   disableFilters: true,
    // },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell: { row } }) => {
        const { id } = row.original;
        return (
          <div className="flex justify-center">
            <button
              className="border border-gray-400 bg-gray-200 hover:bg-blue-500 text-gray-600 hover:text-white font-semibold uppercase text-xs py-2 px-2 rounded-l shadow"
              onClick={() => {
                // setCategoryValue(data_title);
                setRowID(id);
                // setShowEditCategory(true);
                // setDisplayName(display_title);
                // setSelectedRow(row.original)
                setFieldValues(row.original);
                handleToggleModal();
              }}
            >
              Edit
            </button>
            <button
              className="border border-gray-400 bg-gray-200 hover:bg-red-500 text-gray-600 hover:text-white font-semibold uppercase text-xs py-2 px-2 rounded-r shadow"
              onClick={() => {
                // const { data_title, id } = row.original;
                swal
                  .fire({
                    title: `Delete row`,
                    text: `Deletion of this row is irreversable. Are you sure you want to delete?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, Delete",
                    reverseButtons: true,
                  })
                  .then((result) => {
                    if (result.value) {
                      deleteCategory(id);
                      showSuccess();
                    } else {
                      console.log("cancelled");
                    }
                  });
              }}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex justify-end">
        <button
          className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
          onClick={() => {
            resetForm();
            handleToggleModal();
          }}
        >
          Add {capitalize(dataPrefix)}
        </button>
      </div>
      <Table
        columns={columns}
        minRows={0}
        initialState={{
          sortBy: [{ id: "dateAdded" }],
        }}
        data={categories}
      />
      <Modal
        small
        isOpen={isModalVisible}
        title={rowID ? "Edit " + capitalize(dataPrefix) : "Add " + capitalize(dataPrefix)}
        toggleModal={() => closeModal()}
        onConfirm={() => closeModal()}
      >
        <form onSubmit={handleSubmit}>
          <div>
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              {capitalize(dataPrefix)} {rowID}
              <input
                value={fieldValues[dataPrefix + "_name"]}
                // id="categoryName"
                name={`${dataPrefix}_name`}
                onChange={handleChange}
                className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
                placeholder={`Name of ${capitalize(dataPrefix)}`}
              />
              {/* {error && <p className="text-red-500 text-xs">{error}</p>} */}
            </label>
            {/* <div className="ml-8">
              <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Display Name
              </p>
              <p className="block tracking-wide text-gray-700 mb-2">{displayName}</p>
            </div> */}
          </div>
          <div className="flex justify-end">
            <button
              className={`bg-gray-600 text-white bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
              type="submit"
              style={{ transition: "all .15s ease" }}
            >
              {rowID ? "Save" : "Add"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CRUDTable;
