import React, { useState, useContext, useEffect } from "react";
// import { Link } from "react-router-dom";
import { format, parse } from "date-fns";
import swal from "sweetalert";

import Table from "../components/DataTable";
import ProductForm from "../components/ProductForm";
import Modal from "../components/Modal";
import Page404 from "../components/Page404";

import firebase from "../config/firebase";
import { AuthContext } from "../context/Auth";

const MerchantDashboard = () => {
  const [item, setItem] = useState(null);
  const [products, setProducts] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [isModalOpen, toggleModal] = useState(false);

  const auth = useContext(AuthContext);
  const { currentUser } = auth;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const db = firebase.firestore();

    const merchantData = await db
      .collection("merchants")
      .where("companyEmail", "==", currentUser.email)
      // .where("status", "==", "pending")
      .get();

    if (merchantData.docs.length > 0) {
      const merchantInfo = merchantData.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const inventoryData = await db
        .collection("merchants")
        .doc(merchantInfo[0].id)
        .collection("inventory")
        // .orderBy("dateAdded", "desc")
        .get();

      let inventory = inventoryData.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProducts(inventory);
      setMerchant({ ...merchantInfo[0] });
    } else {
      // const order = [];
      // setItems({ ...order[0] });
    }
  };

  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  };

  const handleToggleModal = () => {
    // fetchData()
    setItem(null);
    // selectMerchant(null);
    toggleModal(!isModalOpen);
  };

  const uploadPhotoAsync = (uri, fileName) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      // const response = await fetch(uri);
      // const file = await response.blob();

      let uploadRef = firebase.storage().ref(fileName);

      await uploadRef.put(uri);
      let downloadURL = await uploadRef.getDownloadURL();
      res(downloadURL);
    });
  };

  //Add new item to shopping list
  const handleAddItem = async (itemObj, img) => {
    const db = firebase.firestore();
    const objId = guid();

    if (img && Object.keys(img).length < 1) {
      const remoteUri = await uploadPhotoAsync(img, `${objId}`);
      itemObj.image = remoteUri;
    }

    try {
      await db
        .collection("merchants")
        .doc(merchant.id)
        .collection("inventory")
        .add(itemObj);

      handleToggleModal();
      fetchData();
    } catch (error) {
      console.error(error);
      // setFormError(error.message);
    }
  };

  const handleUpdateItem = async (itemObj, img) => {
    const db = firebase.firestore();
    // const newItemID = guid();

    if (itemObj) {
      const objId = guid();

      if (img && Object.keys(img).length < 1) {
        const remoteUri = await uploadPhotoAsync(img, `${objId}`);
        itemObj.image = remoteUri;
      }

      try {
        // await db.collection("customers").doc(currentUser.uid).collection('cart').add({ name: newItem, quantity: 1 });
        await db
          .collection("merchants")
          .doc(merchant.id)
          .collection("inventory")
          .doc(itemObj.id)
          .update(itemObj);
      } catch (error) {
        // alert(error);
        console.error(error);
      }

      handleToggleModal();
      fetchData();
      // setItems([...items, { id: newItemID, name: newItem, quantity: 1 }])
    }

    // setNewItem('')
  };

  //Delete item from shopping list
  const handleRemoveItem = (itemId) => {
    const db = firebase.firestore();

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        db.collection("merchants")
          .doc(merchant.id)
          .collection("inventory")
          .doc(itemId)
          .delete();

        handleToggleModal();
        fetchData();
      }
    });
  };

  const columns = [
    {
      Header: "Date",
      accessor: "dateAdded",
      disableFilters: true,
      Cell: (row, data) =>
        format(
          parse(row.row.original?.dateAdded, "yyyy-MM-dd HH:mm:ss", new Date()),
          "PPpp"
        ),
    },
    {
      Header: "Title",
      accessor: "product_title",
    },
    {
      Header: "Price",
      accessor: "product_price",
      disableFilters: true,
    },
    {
      Header: "Description",
      accessor: "product_description",
      disableFilters: true,
    },
    {
      Header: "Logo",
      accessor: "logo",
      disableFilters: true,
      Cell: (row, data) => (
        <div>
          {row.row.original.image ? (
            <img src={row.row.original.image} style={{ height: "100px" }} alt="Product" />
          ) : (
            "None"
          )}
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ cell: { row } }) => (
        <div className="flex justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold uppercase text-xs py-2 px-2 rounded shadow"
            onClick={() => {
              setItem(row.original);
              toggleModal(!isModalOpen);
            }}
          >
            View
          </button>
        </div>
      ),
    },
  ];

  if (!currentUser.merchant) {
    return <Page404 />;
  } else {
    return (
      <div className="container px-4 mb-10 mx-auto w-9/12 min-h-full h-auto  bg-white content-center items-center justify-center">
        <div className="flex justify-between items-center content-center pt-10">
          <h1 className="text-3xl uppercase font-bold text-blue-300">
            Manage your Store
          </h1>
          <button
            className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            onClick={() => {
              //   generateSummary(parishFilter);
              handleToggleModal();
            }}
          >
            Add Product
          </button>
        </div>

        {products && (
          <Table
            // title="Order Dashboard"
            columns={columns}
            minRows={0}
            initialState={{
              sortBy: [{ id: "dateAdded", desc: true }],
            }}
            data={products}
          />
        )}
        {/* {item && ( */}
        <Modal
          small
          title={item ? "Edit Item" : "Add Item"}
          isOpen={isModalOpen}
          toggleModal={handleToggleModal}
          // content={modalContent}
          onConfirm={handleToggleModal}
        >
          <ProductForm
            editing={item && Object.entries(item).length > 0 ? true : false}
            productInfo={item}
            onAdd={handleAddItem}
            onUpdate={handleUpdateItem}
            onRemove={handleRemoveItem}
            onCancel={handleToggleModal}
          />
        </Modal>
        {/* )} */}
      </div>
    );
  }
};

export default MerchantDashboard;
