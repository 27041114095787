import React, { useState } from "react";
import axios from "axios";
import useYup from "@usereact/use-yup";
import * as yup from "yup";

import Footer from "../components/Footer.js";
import NavBar from "../components/Navbar.js";

const validationSchema = yup.object().shape({
  contact_subject: yup.string().required("Field is required"),
  contact_email: yup.string().email().required("Field is required"),
  contact_message: yup.string().min(10).required("Field is required"),
});

const ContactPage = () => {
  const [values, setValues] = useState({
    contact_subject: "",
    contact_email: "",
    contact_message: "",
  });
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setValues({
      ...values,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormError("");
    setValues({ contact_subject: "", contact_email: "", contact_message: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { contact_email, contact_subject, contact_message } = values;
    validate();

    // return;
    if (Object.keys(errors).length < 1) {
      try {
        //Send email via firebase function
        await axios.post(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/sendmail", {
          email: contact_email,
          title: contact_subject,
          message: contact_message,
        });

        setFormSuccess(
          "Thanks for your message! We will get back to you within 1 or 2 business days."
        );
        resetForm();
      } catch (error) {
        // alert(error);
        // console.error(error, email, password);
        setFormError("An error has occurred, please try again");
      }
    } else {
      setFormError("Please fill in all required fields");
    }
  };

  return (
    <div className="bg-yellow-400">
      <NavBar transparent />
      <main>
        <div
          className="mt-16 mx-auto flex text-center justify-center items-center"
          style={{
            minHeight: 200,
          }}
        >
          <div className="px-3">
            <h1 className="text-gray-800 font-normal text-5xl md:text-6xl">Contact Us</h1>
          </div>
        </div>
        <div
          className="bg-white flex items-center flex-1 w-full pb-32"
          style={{ minHeight: "500px" }}
        >
          <div className="w-10/12 md:w-8/12 mx-auto">
            <div className="py-6">
              <div className="text-gray-800 text-xl text-center">
                Do you need help with a specific issue or just want to get in touch?
              </div>
            </div>
            <div className="w-full py-3 px-3 md:px-10 text-center md:text-left">
              {formSuccess && (
                <div
                  className="w-100 m-3 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Success!</strong>
                  <span className="block sm:inline"> {formSuccess}</span>
                </div>
              )}

              {formError && (
                <div
                  className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Error!</strong>
                  <span className="block sm:inline"> {formError}</span>
                </div>
              )}

              <form className="text-left" onSubmit={handleSubmit}>
                <div className="relative w-full mb-3 mt-8">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="contact_subject"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-800 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="What do you want to contact us about?"
                    onChange={(e) => handleChange(e)}
                    value={values.contact_subject}
                    name="contact_subject"
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.contact_subject && (
                    <p className="text-red-500 text-xs">{errors.contact_subject}</p>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="contact_email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="contact_email"
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Enter a valid email"
                    value={values.contact_email}
                    onChange={(e) => handleChange(e)}
                    style={{ transition: "all .15s ease" }}
                  />
                  {errors.contact_email && (
                    <p className="text-red-500 text-xs">{errors.contact_email}</p>
                  )}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="contact_message"
                  >
                    Message
                  </label>
                  <textarea
                    rows="4"
                    cols="80"
                    name="contact_message"
                    value={values.contact_message}
                    onChange={(e) => handleChange(e)}
                    className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow-lg focus:outline-none focus:shadow-outline w-full"
                    placeholder="Please type your message to us here"
                  />
                  {errors.contact_message && (
                    <p className="text-red-500 text-xs">{errors.contact_message}</p>
                  )}
                </div>

                <div className="text-right mt-6">
                  <button
                    className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="submit"
                    style={{ transition: "all .15s ease" }}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;
