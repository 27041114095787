import React, { useContext } from "react";
import swal from "sweetalert";

import firebase from "../config/firebase";

import { AuthContext } from "../context/Auth.js";

import ProfileForm from "../components/ProfileForm";

const Profile = ({ history }) => {
  const auth = useContext(AuthContext);
  const { currentUser } = auth;

  const handleSaveProfile = async (data) => {
    const db = firebase.firestore();
    const ref = db.collection("customers").doc(currentUser.uid);
    try {
      delete data.user_pickuplocation;
      await ref.set({ ...data, user_email: currentUser.email });
      // handleConfirmOrder(user_pickuplocation);
      swal(
        "Success!",
        "Thanks your information has been successfully updated!",
        "success"
      ).then((value) => {
        history.goBack();
      });
      // setFormSuccess('Thanks your information has been successfully updated')
    } catch (error) {
      // alert(error);
      console.error(error);
      swal("Sorry", "Something went wrong. Please try again!", "error");
      // setFormError('Sorry but we do not recognize that email/password combination. Please try again')
    }
  };

  const warnCancel = async (data) => {
    try {
      swal({
        title: "Close Profile!",
        text: "Changes made will be lost.",
        icon: "info",
        buttons: ["Back", "Continue"],
      }).then(async (value) => {
        if (value) {
          try {
            history.goBack();
          } catch (error) {
            console.error(error);
          }
        }
      });
    } catch (error) {
      console.error(error);
      swal("Sorry", "Something went wrong. Please try again!", "error");
    }
  };

  return (
    <div className="w-6/12 shadow content-center items-center justify-center my-6 mx-auto px-3 py-4 bg-white border border-gray-300 rounded">
      <h2 className="text-xl py-3 font-light">Update Profile</h2>
      <ProfileForm onAdd={handleSaveProfile} onCancel={warnCancel} />
    </div>
  );
};

export default Profile;
