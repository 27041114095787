import React from "react";

const OrderDetails = ({ orderInfo, merchantList }) => {
  // console.log(orderInfo);
  return (
    <table className="w-full table-fixed rounded bg-white border-solid border-1 border-gray-400 text-left my-6">
      <thead>
        <tr>
          <th className="px-4 py-2 uppercase text-sm text-gray-700 border-solid border-1 border-gray-400 font-normal">
            Name
          </th>
          <th className="px-4 py-2 uppercase text-sm text-gray-700 border-solid border-1 border-gray-400 font-normal">
            Email
          </th>
          <th className="px-4 py-2 uppercase text-sm text-gray-700 border-solid border-1 border-gray-400 font-normal">
            Phone
          </th>

          <th className="px-4 py-2 uppercase text-sm text-gray-700 border-solid border-1 border-gray-400 font-normal">
            Parish
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border-solid border-1 border-gray-400 px-4 py-2 text-sm capitalize">
            {orderInfo.customerName}
          </td>
          <td className="border-solid border-1 border-gray-400 px-4 py-2 text-sm break-all">
            {orderInfo.customerEmail}
          </td>
          <td className="border-solid border-1 border-gray-400 px-4 py-2 text-sm break-all">
            Home: {orderInfo.customerHomeNumber}
            <br />
            Mobile: {orderInfo.customerHomeNumber}
          </td>

          <td className="border-solid border-1 border-gray-400 px-4 py-2 text-sm break-all capitalize">
            {orderInfo.customerParish}
          </td>
        </tr>
        <tr>
          <td
            colSpan="4"
            className="border-solid border-1 border-gray-400 px-4 py-2 text-sm capitalize"
          >
            <span className="uppercase">Address</span>: {orderInfo.customerAddress}
          </td>
        </tr>
        <tr>
          <td
            colSpan="4"
            className="border-solid border-1 border-gray-400 px-4 py-2 text-sm capitalize"
          >
            <span className="uppercase">DropOff Location</span>:{" "}
            {orderInfo.customerDropOffLocation}
          </td>
        </tr>
        <tr>
          <td
            colSpan="4"
            className="border-solid border-1 border-gray-400 px-4 py-2 text-sm capitalize"
          >
            <span className="uppercase">Directions</span>: {orderInfo.customerDirections}
          </td>
        </tr>
        {/* <tr>
                    <td
                        colSpan="4"
                        className="border-solid border-1 border-gray-400 px-4 py-2 text-sm capitalize"
                    >
                        Payment Method:
                          </td>
                </tr> */}
        <tr>
          <td
            colSpan="4"
            className="border-solid border-1 border-gray-400 px-4 py-2 text-sm"
          >
            <span className="font-semibold uppercase">Order Number(s):</span>
            <ul className="list-decimal ml-5">
              {orderInfo.orders.map((elem, index) => (
                <li key={index}>{elem.id}</li>
              ))}
            </ul>
          </td>
        </tr>
        <tr>
          <td
            colSpan="4"
            className="border-solid border-1 border-gray-400 px-4 py-2 text-sm"
          >
            <span className="font-semibold uppercase">Items ordered:</span>
            {orderInfo.orders.map((elem) =>
              elem.cartItems.map((item, index) => {
                const merchantInfo = merchantList.find(
                  (elem) => elem.id === item.merchantId
                );
                return (
                  <li key={index}>
                    {item.name}
                    {item.cost
                      ? parseFloat(item.cost) > 0
                        ? "($" + parseFloat(item.cost).toFixed(2) + ")"
                        : ""
                      : ""}{" "}
                    x {item.quantity}{" "}
                    {item.cost
                      ? parseFloat(item.cost) > 0
                        ? "($" + parseFloat(item.cost * item.quantity).toFixed(2) + ")"
                        : ""
                      : ""}
                    {merchantInfo && "(" + merchantInfo?.companyName + ")"}
                  </li>
                );
              })
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderDetails;
