import React, { useEffect, useState } from "react";

export const StoreContext = React.createContext();

export const StoreProvider = ({ children }) => {
  const storedCart = JSON.parse(localStorage.getItem("customerOrder"));
  const selectedCountry = JSON.parse(localStorage.getItem("countryFilter"));
  const [customerOrder, setCustomerOrder] = useState(storedCart ? storedCart : {});
  const [searchFilter, setSearchFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState(
    selectedCountry ? selectedCountry : "Barbados"
  );

  useEffect(() => {
    localStorage.setItem("customerOrder", JSON.stringify(customerOrder));
  }, [customerOrder]);

  useEffect(() => {
    localStorage.setItem("countryFilter", JSON.stringify(countryFilter));
  }, [countryFilter]);

  return (
    <StoreContext.Provider
      value={{
        customerOrder,
        setCustomerOrder,
        countryFilter,
        setCountryFilter,
        searchFilter,
        setSearchFilter,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
