import React, { useCallback } from "react";

export const PAYMENT_BUTTON_ID_DEFAULT = "payment-button";

const MmoneyPayment = ({ orderInfo, onSuccess, onError }) => {
  //QbdOmNPqsMjj3r60YhCyO-0zyo_BbsxBOuTElo0hHHUuT2sQ-hcbio6iq_cr6y1R
  const apiKey = process.env.REACT_APP_MMONEY_API_KEY || "";

  console.log("apikey", apiKey);

  const onCancel = () => console.log("Payment cancelled");
  const currency = "BBD";
  const amount = orderInfo?.cost || 0.0;
  const merchantOrderID = orderInfo?.id;

  const onPaid = (data) => {
    onSuccess(data);
  };

  const onPaymentError = (error) => {
    console.error(error);
    onError();
  };

  const config = {
    amount,
    currency,
    merchant_order_id: merchantOrderID,
    api_key: apiKey,
    onPaid: onPaid,
    onCancel,
    onError: onPaymentError,
  };

  const ref = useCallback((node) => {
    if (node !== null && window.mMoney) {
      const mMoney = window.mMoney;
      mMoney.payment.button.render(config, node);
    }
  }, []); // eslint-disable-line

  return (
    <>
      <div>
        {/* <h2 className="p-3">Price: ${parseFloat(amount).toFixed(2)} BBD</h2> */}
        {apiKey ? (
          <div ref={ref} id="payment-button" />
        ) : (
          <div>Error loading payment button</div>
        )}
      </div>
    </>
  );
};

export default MmoneyPayment;
