import useYup from "@usereact/use-yup";
import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

import SocialLogins from "../components/SocialLogins.js";

import app from "../config/firebase.js";
import { AuthContext } from "../context/Auth.js";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

const Login = (props) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [formError, setFormError] = useState("");
  const [redirectLink, setRedirectLink] = useState(null);

  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: false,
  });

  useEffect(() => {
    const { state } = props.location;
    if (state) {
      setRedirectLink(state.redirectedFrom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    if (currentUser.admin) {
      return <Redirect to="/users/dashboard" />;
    } else if (currentUser.merchant) {
      return <Redirect to="/merchants/dashboard" />;
    } else {
      if (redirectLink) {
        return <Redirect to={redirectLink} />;
      } else {
        return <Redirect to="/vendors/grocery" />;
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const { email, password } = values;

    validate().then(async ({ errors, isValid }) => {
      if (isValid) {
        try {
          await app
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(async (value) => {
              await app
                .auth()
                .currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                  if (idTokenResult.claims.admin) {
                    // Show admin UI.
                    // isAdmin = true;
                    props.history.push("/users/dashboard");
                  }

                  if (idTokenResult.claims.merchant) {
                    // Show admin UI.
                    // isMerchant = true;
                    props.history.push("/merchants/dashboard");
                  }
                });
            })
            .catch(function (error) {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // ...
              console.error(errorCode, errorMessage);
              setFormError(
                "Sorry but we do not recognize that email/password combination. Please try again"
              );
            });
        } catch (error) {
          // alert(error);
          console.error(error);
          setFormError(
            "Sorry but we do not recognize that email/password combination. Please try again"
          );
        }
      } else {
        setFormError("Please fill in all required fields");
      }
    });
  };

  return (
    <>
      <Navbar />
      <main>
        <section className="absolute w-full h-full">
          <div className="absolute top-0 w-full h-full"></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full md:w-8/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                  {formError && (
                    <div
                      className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <strong className="font-bold">Error!</strong>
                      <span className="block sm:inline"> {formError}</span>
                    </div>
                  )}

                  <div className="rounded-t mb-0 px-6 py-3">
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-2xl font-bold">Sign In</h6>
                      <div className="text-md mt-3 text-gray-600 mb-3">
                        Please sign in to start shopping
                      </div>
                    </div>
                  </div>

                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    {/* <div className="text-gray-500 text-center mb-3 font-bold">
                                            <small>Or sign in with credentials</small>
                                        </div> */}
                    <form onSubmit={handleLogin}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Email"
                          value={values.email}
                          onChange={(e) => handleChange(e)}
                          style={{ transition: "all .15s ease" }}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs italic">{errors.email}</p>
                        )}
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Password"
                          value={values.password}
                          onChange={(e) => handleChange(e)}
                          style={{ transition: "all .15s ease" }}
                        />
                        {errors.password && (
                          <p className="text-red-500 text-xs italic">{errors.password}</p>
                        )}
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                    <div className="flex flex-col items-center mt-2">
                      <p className="text-md text-gray-600 mb-2">
                        or sign in using a social account
                      </p>
                      <SocialLogins handleFailedLogin={setFormError} />
                      <p className="text-md text-gray-600 mt-2">
                        Don't have an account?{" "}
                        <Link to="/signup" className="text-blue-500">
                          Sign Up
                        </Link>
                      </p>

                      <Link to="/forgotpassword" className="text-blue-500 text-md">
                        Forgot Password
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer absolute />
        </section>
      </main>
    </>
  );
};

export default Login;
