import React from "react";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

//<a href="https://www.freepik.com/free-photos-vectors/background">Background photo created by benzoix - www.freepik.com</a>

export default function About() {
  return (
    <>
      <Navbar />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div className="container relative mx-6 md:mx-16">
            <div className="flex flex-wrap mt-6 ">
              <div className="w-full text-center">
                <h1 className="text-gray-800 font-normal text-5xl md:text-6xl">
                  We do <br />
                  it for you.
                </h1>
                <p className="my-4 text-lg leading-relaxed text-gray-800">
                  Customer focused. Solution driven. Results based.
                </p>
                <p className="w-full md:w-3/4 mt-4 mx-auto text-md text-gray-800 font-light leading-10">
                  WiFetch is transforming the way goods move around in the Caribbean
                  locally, enabling anyone to have anything delivered on-demand. Our
                  revolutionary local Logistics platform connects customers with local
                  Fetchers who can deliver anything from any of our partner vendors within
                  hours. We empower communities to shop local and remotely and empower
                  businesses through our API to offer delivery at the most economical/most
                  efficient cost.
                </p>
                <p className="mt-4 text-3xl capitalize text-gray-800">
                  What can WiFetch for you?
                </p>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="py-10 bg-white">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center mt-6">
              <div className="w-9/12 px-4 mr-auto ml-auto text-center">
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  How It Works
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  Whatever you need, WiFetch & WiDeliver
                </p>
                <img
                  src={require("../assets/img/wifetch_howitworks.jpeg")}
                  alt="How it works"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div className="flex justify-center items-center">
            <div className="w-9/12 flex flex-col md:flex-row">
              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Our Mission
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-800">
                  Our mission is to empower the caribbean and provide opportunities and
                  access for everyone We want everyone's business to be noticeable and
                  successful. We want to help you feel connected in a sometimes
                  disconnected world. We want to help YOU grow.
                </p>
              </div>
              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
                <h3 className="text-3xl mb-2 font-semibold leading-normal">Our Values</h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-800">
                  At WiFetch our values are extremely important to us and are at the core
                  of our business. We believe in providing solutions for all of our
                  clients, with the client being the epicentre of our business model.
                </p>
              </div>
              {/* <div className="w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">Our Values</h3>
                  <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-800">
                    At WiFetch our values are extremely important to us and are at the
                    core of our business. We believe in providing solutions for all of our
                    clients, with the client being the epicentre of our business model.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-6">
            <section className="pt-5 pb-12">
              <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-center text-center mb-24">
                  <div className="w-full lg:w-6/12 px-4">
                    <h2 className="text-4xl text-white font-semibold">Meet The Team</h2>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row">
                  <div className="w-full lg:mb-0 mb-12 px-4">
                    <div className="px-3">
                      <img
                        alt="..."
                        src={require("../assets/img/wifetch_lilydash.jpg")}
                        className="shadow-lg rounded mx-auto"
                        // style={{ minWidth: "100px", maxWidth: '300px' }}
                      />
                      <div className="pt-6 text-center">
                        <h5 className="text-xl text-white font-bold">Lily Dash</h5>
                        <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                          Co-Founder
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full  lg:mb-0 mb-12 px-4">
                    <div className="px-3">
                      <img
                        alt="..."
                        src={require("../assets/img/wifetch_sophie.jpg")}
                        className="shadow-lg rounded mx-auto"
                        // style={{ minWidth: "200px", maxWidth: '300px' }}
                      />
                      <div className="pt-6 text-center">
                        <h5 className="text-xl text-white font-bold">Sophie Bannister</h5>
                        <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                          Co-Founder
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* <section className="relative block py-24 lg:pt-0 bg-gray-900">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                            <div className="w-full lg:w-6/12 px-4">
                                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                                    <div className="flex-auto p-5 lg:p-10">
                                        <h4 className="text-2xl font-semibold">
                                            Want to work with us?
                    </h4>
                                        <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                                            Complete this form and we will get back to you in 24 hours.
                    </p>
                                        <div className="relative w-full mb-3 mt-8">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="full-name"
                                            >
                                                Full Name
                      </label>
                                            <input
                                                type="text"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                                placeholder="Full Name"
                                                style={{ transition: "all .15s ease" }}
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="email"
                                            >
                                                Email
                      </label>
                                            <input
                                                type="email"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                                placeholder="Email"
                                                style={{ transition: "all .15s ease" }}
                                            />
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                htmlFor="message"
                                            >
                                                Message
                      </label>
                                            <textarea
                                                rows="4"
                                                cols="80"
                                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                                                placeholder="Type a message..."
                                            />
                                        </div>
                                        <div className="text-center mt-6">
                                            <button
                                                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                type="button"
                                                style={{ transition: "all .15s ease" }}
                                            >
                                                Send Message
                      </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      </main>
      <Footer />
    </>
  );
}
