import React, { useEffect, useState } from "react";

const CategoryList = ({ data, handleClick, category }) => {
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    console.log(category);
    setSelectedCategory(category);
  }, [category]); //eslint-disable-line

  const colors = ["orange", "blue", "green", "red"];

  const getColor = (index) => colors[index % colors.length];

  const displayCategoryList = (limit) => {
    // console.log(categoriesList)
    // const limit = 16;
    const categoriesToDisplay = data.slice(0, limit);

    const formattedCategoriesToDisplay = groceryToTop(categoriesToDisplay);

    const display = formattedCategoriesToDisplay.map(
      ({ category_name, display_name }, index) => {
        const color = getColor(index);
        let icon = "";
        switch (category_name) {
          // case "auto":
          //   icon = "fas fa-car";
          //   break;
          case "beauty":
            icon = category_name;
            break;
          case "exercise":
            icon = category_name;
            break;
          // case "fashion":
          //   icon = "fas fa-tshirt";
          //   break;
          // case "home":
          //   icon = "fas fa-home";
          //   break;
          // case "jewellery":
          //   icon = "fas fa-gem";
          //   break;
          case "pharmacy":
            icon = category_name;
            break;
          case "hardware":
            icon = category_name;
            break;
          case "drinks":
            icon = category_name;
            break;
          case "eats":
            icon = category_name;
            break;
          case "fish":
            icon = category_name;
            break;
          case "farm":
            icon = category_name;
            break;
          case "gifts":
            icon = category_name;
            break;
          case "tech":
            icon = category_name;
            break;
          case "mall":
            icon = category_name;
            break;
          case "grocery":
            icon = category_name;
            break;
          default:
            icon = "grocery";
        }

        return (
          <div
            key={index}
            className="md:w-1/5 w-1/2 p-2 cursor-pointer"
            onClick={() => handleClick(category_name)}
          >
            <div
              className={
                category_name === selectedCategory
                  ? "group text-center shadow-lg rounded p-4 m-1 bg-primary-yellow hover:bg-opacity-75"
                  : "group text-center shadow-lg rounded p-4 m-1 hover:bg-primary-yellow hover:bg-opacity-75"
              }
            >
              <div className={`group text-${color}-400 flex justify-center`}>
                <div>
                  <img
                    style={{ height: 50 }}
                    src={require(`../assets/icons/${icon}.svg`)}
                    alt="icon SVG"
                  />
                </div>
              </div>
              <div className="mt-4">
                <h2 className="text-gray-900 title-font text-lg font-medium">
                  {display_name}
                </h2>
              </div>
            </div>
          </div>
        );
      }
    );

    return display;
  };

  const groceryToTop = (categoriesList) => {
    const groceryIndex = categoriesList.findIndex(
      ({ category_name }) => category_name?.toLowerCase() === "grocery"
    );

    if (groceryIndex >= 0) {
      const newDisplayList = categoriesList.filter(
        ({ category_name }) => category_name?.toLowerCase() !== "grocery"
      );

      const groceryCategory = categoriesList[groceryIndex];
      newDisplayList.unshift(groceryCategory);
      return newDisplayList;
    }

    return categoriesList;
  };

  return (
    <div className="flex overflow-x-scroll md:overflow-hidden md:flex-wrap box-border">
      {displayCategoryList(15)}
    </div>
  );
};

export default CategoryList;
