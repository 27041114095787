import React from "react";

import Footer from "./Footer";
// import Navbar from "./Navbar.old";
import Navbar from "./Navbar";

const PageWrapper = (props) => {
  // console.log(props)
  return (
    <div className="flex flex-col w-full h-screen min-h-screen overflow-auto">
      <Navbar />

      <main className="flex-grow h-auto bg-gray-300 min-w-full pb-16">
        {props.children}
      </main>

      <Footer />
    </div>
  );
};

export default PageWrapper;
