import React, { useEffect, useContext } from "react";
// import useYup from "@usereact/use-yup";

import { Link } from "react-router-dom";
import { GroceryItem } from "../components/GroceryItem";
import DirectionsForm from "../components/DirectionsForm";
// import SocialLogins from "../components/SocialLogins.js";
// import Modal from "../components/Modal";
// import ProfileForm from "../components/ProfileForm";

// import * as yup from "yup";

import firebase from "../config/firebase";
import { AuthContext } from "../context/Auth";

import { StoreContext } from "../context/store";
import swal from "sweetalert2";
import { format } from "date-fns";
import axios from "axios";
import app from "../config/firebase.js";

// const validationSchema = yup.object().shape({
//   email: yup.string().email().required(),
//   password: yup.string().min(6).required(),
// });

const Cart = (props) => {
  const auth = useContext(AuthContext);
  const { currentUser } = auth;

  const store = useContext(StoreContext);
  const { customerOrder, setCustomerOrder } = store;

  const [items, setItems] = React.useState(null);
  const [userProfile, setUserProfile] = React.useState({});
  // const [step, setStep] = React.useState(0);
  // const [isModalOpen, toggleModal] = React.useState(false);
  // const [modalType, setModalType] = React.useState("");

  // const [values, setValues] = useState({
  //   order_directions: "",
  //   order_dropofflocation: "",
  //   email: "",
  //   password: "",
  //   user_name: "",
  //   user_email: "",
  //   user_address: "",
  //   user_home_number: "",
  //   user_mobile_number: "",
  //   user_parish: "",
  // });

  // const { errors, validate } = useYup(values, validationSchema, {
  //   validateOnChange: false,
  // });

  // const [formError] = useState("");
  // const [loginFormError, setLoginFormError] = useState("");

  // Handles input change event in the DirectionForm component
  const handleChange = (e) => {
    const { name, value } = e.target;
    // validate();
    setItems({
      ...customerOrder,
      [name]: value,
    });
  };

  // Handler for the autofill of saved direction data in the form.
  // const handleDirectionsUpdate = (updatedInfo) => {
  //   setValues({
  //     ...values,
  //     order_directions: updatedInfo.order_directions,
  //     order_dropofflocation: updatedInfo.order_dropofflocation,
  //   });
  // };

  const fetchData = async () => {
    //Display cart items from localstorage
    setItems(customerOrder);

    //If user is logged in, grab user profile
    if (currentUser) {
      const db = firebase.firestore();
      const ref = db.collection("customers").doc(currentUser.uid);

      ref
        .get()
        .then((doc) => {
          if (doc.exists) {
            // setProfile(true);
            // setValues({ ...doc.data(), id: doc.id });
            setUserProfile({ ...doc.data(), id: doc.id });
          } else {
            console.log("no profile information found");
            // setProfile(false)
            // setProfile(false);
          }
        })
        .catch(function (error) {
          console.error("Error getting user profile:", error);
        });
    }
  };

  const handleAnonymousUserLogin = async () => {
    await app
      .auth()
      .signInAnonymously()
      .then(function (result) {
        return result;
      })
      .catch(function (error) {
        console.error("Error creating anonymous user:", error);
      });
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCustomerOrder(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleIncreaseQty = async (newItem) => {
    // return;

    let cartItems = items.cartItems.map((item) =>
      item.id === newItem.id ? { ...item, quantity: newItem.quantity } : item
    );

    let orderUpdate = {
      ...items,
      cartItems: cartItems,
    };
    setItems((items) => orderUpdate);

    // localStorage.setItem("orderItems", JSON.stringify(orderUpdate));
  };

  const handleDecreaseQty = async (newItem) => {
    let cartItems = items.cartItems.map((item) =>
      item.id === newItem.id ? { ...item, quantity: newItem.quantity } : item
    );

    let orderUpdate = {
      ...items,
      cartItems: cartItems,
    };

    setItems((items) => orderUpdate);

    // localStorage.setItem("orderItems", JSON.stringify(orderUpdate));
  };

  //Delete item from shopping list
  const handleRemoveItem = (itemId) => {
    let cartItems = items.cartItems;

    let newCartItems = cartItems.filter((item) => item.id !== itemId);

    setItems((items) => ({
      ...items,
      cartItems: newCartItems,
    }));

    // localStorage.setItem(
    //   "orderItems",
    //   JSON.stringify({
    //     ...items,
    //     cartItems: newCartItems,
    //   })
    // );
  };

  //Proceed to checkout
  // const handleProceedToCheckout = () => {
  //   if (!values.order_dropofflocation) {
  //     swal.fire("Error", "Directions for dropoff are required.", "error");
  //     return;
  //   }

  //   const updatedItemsInfo = {
  //     ...items,
  //     directions: values.order_directions,
  //     pickuplocation: values.order_dropofflocation,
  //   };

  //   localStorage.setItem("orderItems", JSON.stringify(updatedItemsInfo));
  //   localStorage.setItem("cartStep", 1);
  //   fetchData();
  // };

  //Proceed to checkout
  const handlePlaceOrder = async () => {
    if (!items.cartItems) {
      swal.fire("Items Required", "Please add items to your order.", "info");
      return;
    }

    if (currentUser) {
      if (!items.order_dropofflocation || !items.order_directions) {
        swal.fire(
          "All fields required",
          "Please enter your contact information and delivery information",
          "error"
        );
        return;
      }
    } else {
      if (
        !items.order_contact_name ||
        !items.order_contact_email ||
        !items.order_contact_number ||
        !items.order_contact_parish ||
        !items.order_dropofflocation ||
        !items.order_directions
      ) {
        swal.fire(
          "All fields required",
          "Please enter your contact information and delivery information",
          "error"
        );
        return;
      }
    }

    // console.log(items)
    // return;

    let userInfo = app.auth().currentUser;

    if (!userInfo) {
      //If user is not logged in, create an anonymous account
      userInfo = await handleAnonymousUserLogin();
      // console.log(userInfo);
      //NOTE: This function calls itself so that it is re-run after the anonymous user account is created
      //TODO: Replace this function call with a modal giving the user the option to login or skip
      handlePlaceOrder();
    } else {
      //When user is logged in (anonymously or as a regular user), create the order and submit order
      let updateItems = {
        ...items,
        customerId: userInfo.uid,
        quantity: items.cartItems.length,
        dateAdded: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        dateUpdated: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        status: "confirmed",
        status_confirmed: true,
        cost: items.cost,
      };

      // console.log(updateItems)
      // return;

      const db = firebase.firestore();
      await db.collection("orders").doc(items.id).set(updateItems);

      if (updateItems && (!currentUser || !userProfile.user_name)) {
        await db.collection("customers").doc(userInfo.uid).set({
          user_name: updateItems.order_contact_name,
          user_mobile_number: updateItems.order_contact_number,
          user_email: updateItems.order_contact_email,
          user_parish: updateItems.order_contact_parish,
          user_country: updateItems.order_country,
        });
      }

      // return
      // await db.collection("customers")
      //   .doc(userInfo.uid)
      //   .set({
      //     user_name: updateItems.order_contact_name || userProfile.user_name,
      //     user_mobile_number: updateItems.order_contact_number || userProfile.user_mobile_number,
      //     user_email: updateItems.order_contact_email || userProfile.user_email,
      //     user_parish: updateItems.order_contact_parish || userProfile.user_parish
      //   });
      // Get a new write batch
      let batch = db.batch();

      items.cartItems.forEach((item) => {
        batch.set(
          db.collection("orders").doc(items.id).collection("items").doc(item.id),
          item
        );
      });

      // Commit the batch
      batch.commit().then(() => {
        swal
          .fire({
            title: "Thank you!",
            html:
              "<p>Your order confirmation number is: <p/> <br/> <b>" +
              items.id +
              "</b><br/>. <p>Please save the above confirmation number as it will be helpful to track your order. We will get back to you within 24 hours except for bank holidays and days when the stores are closed when deliveries will arrive the next business day.</p>",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Continue Shopping!",
            cancelButtonText: "View Cart",
            reverseButtons: true,
          })
          .then(async (result) => {
            swal.showLoading();

            let contact_email = "";
            if (currentUser?.email) {
              contact_email = currentUser.email;
            } else {
              contact_email = updateItems.order_contact_email;
            }

            //Send email via firebase function
            const orders = customerOrder?.cartItems?.map(({ name, quantity }) => ({
              name,
              quantity,
            }));

            await axios.post(
              process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/sendnotification",
              {
                email: contact_email,
                status: "confirmed",
                cost: 0,
                orderno: items.id,
                title: "WiFetch: Order Confirmation",
                orders,
              }
            );

            // localStorage.setItem("orderItems", "{}");
            // localStorage.setItem("cartStep", 0);
            //Clear the cart items and localstorage
            setCustomerOrder({});

            swal.hideLoading();

            if (result.value) {
              props.history.push("/vendors/grocery");
            } else {
              props.history.push("/shopping/cart");
            }
          });
      });
    }

    // } else {
    //   swal.fire(
    //     "Login Required",
    //     "Login is required to complete your order, thank you.",
    //     "info"
    //   );
    // }
  };

  // const handleToggleModal = () => {
  //   // fetchData();
  //   toggleModal(!isModalOpen);
  // };

  // const getModalTitle = () => {
  //   switch (modalType) {
  //     case "signin":
  //       return "Sign In";
  //     case "signup":
  //       return "Sign Up";
  //     default:
  //       return;
  //   }
  // };

  // const handleLogin = async (event) => {
  //   event.preventDefault();
  //   const { email, password } = values;
  //   try {
  //     await app
  //       .auth()
  //       .signInWithEmailAndPassword(email, password)
  //       .then(async (value) => {
  //         //Show profile or request profile
  //         setLoginFormError("");
  //         toggleModal(!isModalOpen);
  //       })
  //       .catch(function (error) {
  //         // Handle Errors here.
  //         var errorCode = error.code;
  //         var errorMessage = error.message;
  //         // ...
  //         console.error(errorCode, errorMessage);
  //         setLoginFormError(
  //           "Sorry but we do not recognize that email/password combination. Please try again"
  //         );
  //       });
  //   } catch (error) {
  //     // alert(error);
  //     console.error(error);
  //     setLoginFormError(
  //       "Sorry but we do not recognize that email/password combination. Please try again"
  //     );
  //   }
  // };

  // const handleSignUp = async (event) => {
  //   event.preventDefault();
  //   const { email, password } = values;
  //   if (!email || !password) {
  //     setLoginFormError("Please include an email and password.");
  //     return;
  //   } else {
  //     setLoginFormError("");
  //   }

  //   swal
  //     .fire({
  //       title: "Complete Registration",
  //       html:
  //         "<p>Continue to agree to our <a href='/termsofuse' style='color:#4299e1' target='_blank'>Terms of Service</a>" +
  //         ", <a href='/privacypolicy' target='_blank' style='color:#4299e1'>Privacy Policy</a> and finalize registration.<p><br/>" +
  //         "We will send an optional verification email once registration is complete.",
  //       icon: "info",
  //       showCloseButton: true,
  //       showCancelButton: true,
  //       confirmButtonText: "Agree",
  //       cancelButtonText: "Cancel",
  //       reverseButtons: true,
  //     })
  //     .then(async (result) => {
  //       if (result.value) {
  //         try {
  //           await app.auth().createUserWithEmailAndPassword(email, password);

  //           await app.auth().currentUser.sendEmailVerification();

  //           toggleModal(!isModalOpen);
  //         } catch (error) {
  //           // alert(error);
  //           setLoginFormError(
  //             "An error has occured, please try again or check if already registered."
  //           );
  //         }
  //       }
  //     });
  // };

  // const handleSaveProfile = async (data) => {
  //   const db = firebase.firestore();
  //   const ref = db.collection("customers").doc(currentUser.uid);
  //   try {
  //     delete data.user_pickuplocation;
  //     await ref.set({ ...data, user_email: currentUser.email });
  //     swal.fire({
  //       title: "Profile Saved",
  //       text: "Your profile has been saved and you may now continue.",
  //       icon: "success",
  //     });
  //     fetchData();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="w-full py-6 bg-white min-h-full flex">
      <div className="container mx-auto">
        {/* Editable Order Items List **************************/}
        <div className="flex items-center justify-start">
          <Link to="/shopping/cart" className="text-blue-500 underline text-sm px-2 mr-3">
            Cart
          </Link>
          <p className="text-xs">
            <i className="fas fa-chevron-right"></i>
          </p>
          <p className="text-sm px-3">Shipping</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-2/3 p-5 bg-white rounded shadow-xl mr-4">
            <div className="flex-1">
              <DirectionsForm
                userInfo={userProfile}
                orderInfo={customerOrder}
                handleChange={handleChange}
              />
            </div>
            <div className="flex justify-between mt-6">
              <Link
                to="/shopping/cart"
                className="text-blue-500 text-sm px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              >
                <i className="fas fa-chevron-left"></i> Return to Cart
              </Link>
              {/* <Link
                to="/shopping/checkout"
                className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              >
                Submit Order
              </Link> */}
              <button
                onClick={handlePlaceOrder}
                className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              >
                Place Order
              </button>
            </div>
          </div>
          <div className="w-full md:w-1/3 p-3 bg-white rounded shadow-xl">
            <h2 className="text-gray-800 text-xl  p-3">Shopping Cart</h2>
            <ul
              className="w-full pt-3 flex-grow border-t border-gray-300 overflow-y-scroll"
              style={{ height: "330px" }}
            >
              {items && items.cartItems && items.cartItems.length > 0 ? (
                items.cartItems.map((item, index) => (
                  <li
                    key={index}
                    className=" border-dashed border-b border-gray-300 last:border-none"
                  >
                    <GroceryItem
                      list={items}
                      item={item}
                      handleIncrease={handleIncreaseQty}
                      handleDecrease={handleDecreaseQty}
                      onRemoveItem={handleRemoveItem}
                    />
                  </li>
                ))
              ) : (
                <div className="h-auto p-10 text-center">
                  <span>No items to display</span>
                </div>
              )}
            </ul>

            {/* <div className="my-3 p-2 border-b border-t border-gray-300">
              <div className="flex justify-between ">
                <p>Subtotal (BBD)</p>
                <p className="font-semibold">??</p>
              </div>
              <div className="flex justify-between ">
                <p>Delivery Fee (BBD)</p>
                <p className="font-semibold">$45.00</p>
              </div>
            </div> */}
            <div className="flex justify-center text-center my-3 p-2">
              {/* <p className="text-xl">Total (BBD)</p>
              <p>Unknown</p> */}
              <p className="text-sm">
                We will get back to you within 24 hours except for bank holidays and days
                when the stores are closed when deliveries will arrive the next business
                day.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        small
        title={getModalTitle()}
        isOpen={isModalOpen}
        toggleModal={handleToggleModal}
        onConfirm={handleToggleModal}
      >
        <div className="p-1">
          <div>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white border-0">
              {loginFormError && (
                <div
                  className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Error!</strong>
                  <span className="block sm:inline"> {loginFormError}</span>
                </div>
              )}

              {(modalType === "signup" || modalType === "signin") && (
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
                        placeholder="Email"
                        value={values.email}
                        onChange={(e) => handleChange(e)}
                        style={{ transition: "all .15s ease" }}
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs italic">{errors.email}</p>
                      )}
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Password"
                        value={values.password}
                        onChange={(e) => handleChange(e)}
                        style={{ transition: "all .15s ease" }}
                      />
                      {errors.password && (
                        <p className="text-red-500 text-xs italic">{errors.password}</p>
                      )}
                    </div>

                    <div className="text-center mt-6">
                      {modalType === "signin" && (
                        <button
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                          onClick={handleLogin}
                        >
                          Sign In
                        </button>
                      )}
                      {modalType === "signup" && (
                        <button
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                          onClick={handleSignUp}
                        >
                          Sign Up
                        </button>
                      )}
                    </div>
                  </form>
                  {modalType === "signin" && (
                    <div className="flex flex-col items-center mt-2">
                      <Link to="/forgotpassword" className="text-blue-500 text-md">
                        Forgot Password
                      </Link>
                    </div>
                  )}
                </div>
              )}


            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default Cart;
