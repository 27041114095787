import useYup from "@usereact/use-yup";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import swal from "sweetalert2";
import app from "../config/firebase";

import firebase from "../config/firebase";
import { AuthContext } from "../context/Auth.js";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  user_name: yup.string().required("Name is required"),
  user_home_number: yup
    .string()
    .matches(phoneRegExp, "Home number is not valid")
    .required("Home number is required"),
  user_mobile_number: yup.string(),
  user_address: yup.string().required("Address is required"),
  user_country: yup.string().required("Country is required"),
  user_parish: yup.string().required("Parish is required"),
});

const parishes = {
  Barbados: [
    "Christ Church",
    "St Philip",
    "St Michael",
    "St George",
    "St John",
    "St James",
    "St Thomas",
    "St Joseph",
    "St Peter",
    "St Andrew",
    "St Lucy",
  ],
  Trinidad: [
    "Caroni",
    "Mayaro",
    "Nariva",
    "Saint Andrew",
    "Saint David",
    "Saint George",
    "Saint Patrick",
    "Victoria",
  ],
  Guyana: ["Essequibo", "Demerara", "Berbice"],
  Jamaica: [
    "Clarendon",
    "Hanover",
    "Kingston",
    "Manchester",
    "Portland",
    "Saint Andrew",
    "Saint Ann",
    "Saint Catherine",
    "Saint Elizabeth",
    "Saint James",
    "Saint Mary",
    "Saint Thomas",
    "Trelawny",
    "Westmoreland",
  ],
};

const ProfileForm = (props) => {
  const [countryList, setCountryList] = useState([]);

  const [values, setValues] = useState({
    user_name: "",
    user_email: "",
    user_address: "",
    user_home_number: "",
    user_mobile_number: "",
    user_parish: "",
    user_country: "",
  });
  const [formError, setFormError] = useState("");
  const [formSuccess] = useState("");

  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: false,
  });

  const resetForm = () => {
    setFormError("");
    // setValues({
    //   user_name: "",
    //   user_email: "",
    //   user_address: "",
    //   user_home_number: "",
    //   user_mobile_number: "",
    //   user_parish: "",
    // });
  };

  const { currentUser } = useContext(AuthContext);

  const fetchData = async () => {
    const db = firebase.firestore();
    const ref = db.collection("customers").doc(currentUser.uid);

    ref
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setValues({ ...doc.data(), id: doc.id });
        }
      })
      .catch(function (error) {
        //console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCountries = async () => {
    const db = firebase.firestore();

    const dataRef = await db.collection("countries").orderBy("country_name").get();

    const dataCollection = dataRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setCountryList(dataCollection);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    //TODO:Add input masking on the telephone numbers
    setValues({
      ...values,
      [name]: value,
    });

    // validate();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    validate().then(({ errors, isValid }) => {
      if (isValid) {
        try {
          props.onAdd(values);
          resetForm();
        } catch (error) {
          setFormError("An error has occurred, please try again");
        }
      } else {
        setFormError("Please fill in all required fields");
      }
    });
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      await app
        .auth()
        .currentUser.sendEmailVerification()
        .then(() => {
          swal.fire({
            title: "Email Verification",
            text: "Your verification link has been resent.",
            icon: "info",
          });
        })
        .catch((error) => {
          console.log("sending verification email failed.");
        });
    } catch (error) {
      // alert(error);
      //console.log(error);
    }
  };

  return (
    // <div className="shadow flex content-center items-center justify-center mt-16 px-3 py-4 bg-white border border-gray-300 rounded">
    <div>
      {formSuccess && (
        <div
          className="bg-teal-100 border-t-1 border-teal-500 rounded-b text-teal-900 px-4 py-3 mb-3 shadow-md"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Success!</p>
              <p className="text-sm">{formSuccess}</p>
            </div>
          </div>
        </div>
      )}
      {formError && (
        <div
          className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {formError}</span>
        </div>
      )}
      <div className="text-left">
        <form className="w-full max-w-lg" onSubmit={handleSubmit}>
          {values.user_email ? (
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Email
                </label>
                <div name="user_email" className="py-3">
                  <div className="inline-block">{values.user_email}</div>

                  {!currentUser.isEmailVerified ? (
                    <div className="inline-block px-3">
                      {" "}
                      <span className="tooltip">
                        <svg
                          className="tooltip fill-current text-red-500"
                          width="16px"
                          height="16px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 5h2v6H9V5zm0 8h2v2H9v-2z" />
                        </svg>
                        <span className="transition duration-500 ease-in-out tooltip-text bg-blue-100 p-3 -mt-6 ml-6 rounded">
                          Click{" "}
                          <button
                            className="cursor-pointer text-blue-500"
                            onClick={handleResend}
                          >
                            here
                          </button>{" "}
                          to resend your email verification link.
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className="inline-block px-3">
                      <span className="tooltip">
                        <svg
                          className="fill-current text-teal-500"
                          width="16px"
                          height="16px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z" />
                        </svg>
                        <span
                          onClick={handleResend}
                          className="transition duration-500 ease-in-out tooltip-text bg-green-200 p-3 -mt-6 ml-6 rounded"
                        >
                          Your email is verified.
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Name
              </label>
              {/* <input type="text" placeholder="Enter your full name" name="user_name" value={values.user_name} onClick={e => handleChange(e)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" /> */}
              <input
                type="text"
                name="user_name"
                className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded leading-tight text-sm shadow focus:outline-none focus:shadow-outline w-full"
                placeholder="Enter your full name"
                value={values.user_name}
                onChange={(e) => handleChange(e)}
                style={{ transition: "all .15s ease" }}
              />
              {errors.user_name && (
                <p className="text-red-500 text-xs italic">{errors.user_name}</p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-2 mb-2">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Home #
              </label>
              <input
                name="user_home_number"
                value={values.user_home_number}
                onChange={(e) => handleChange(e)}
                className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                placeholder="(246) 123-4567"
              />
              {errors.user_home_number && (
                <p className="text-red-500 text-xs italic">{errors.user_home_number}</p>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Mobile #
              </label>
              <input
                name="user_mobile_number"
                value={values.user_mobile_number}
                onChange={(e) => handleChange(e)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                placeholder="(246) 123-4567"
              />
              {errors.user_mobile_number && (
                <p className="text-red-500 text-xs italic">{errors.user_mobile_number}</p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-3">
            <div className="w-full px-2">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Address
              </label>
              <textarea
                name="user_address"
                value={values.user_address}
                onChange={(e) => handleChange(e)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Please provide a valid address"
                rows="3"
              ></textarea>
              {errors.user_address && (
                <p className="text-red-500 text-xs italic">{errors.user_address}</p>
              )}
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Country
              </label>
              <div className="relative">
                <select
                  name="user_country"
                  value={values.user_country}
                  onChange={(e) => handleChange(e)}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value="">--Select Country--</option>
                  {countryList.map(({ country_name }, index) => (
                    <option key={index} value={country_name}>
                      {country_name}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errors.user_country && (
                <p className="text-red-500 text-xs italic">{errors.user_country}</p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap mb-6">
            <div className="w-full mb-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Parish
              </label>
              <div className="relative">
                <select
                  name="user_parish"
                  value={values.user_parish}
                  onChange={(e) => handleChange(e)}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value="">--Select One--</option>
                  {parishes[values.user_country] &&
                    parishes[values.user_country].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              {errors.user_parish && (
                <p className="text-red-500 text-xs italic">{errors.user_parish}</p>
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm;
