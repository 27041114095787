import React, { useState, useEffect } from "react";
import Table from "./DataTable";
import Modal from "./Modal";
import swal from "sweetalert2";
import firebase from "../config/firebase";

const Categories = ({ showAddCategory, setShowAddCategory }) => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [categoryValue, setCategoryValue] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [showEditCategory, setShowEditCategory] = useState(false);

  useEffect(() => {
    //set categories here
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearValues = () => {
    setDisplayName("");
    setCategoryValue("");
    setCategoryID("");
  };

  const closeModal = () => {
    setShowAddCategory(false);
    setShowEditCategory(false);
    clearValues();
  };

  const capitalize = (value) => {
    const lowercase = value.toLowerCase();
    const capitalize = lowercase.charAt(0).toUpperCase() + lowercase.slice(1);

    return capitalize;
  };

  const fetchCategories = async () => {
    const firestore = firebase.firestore();
    const categoriesRef = await firestore.collection("categories").get();
    const categoryList = categoriesRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setCategories(categoryList);
  };

  const validate = (value) => {
    if (categoryValue === "") {
      return "Category name is required";
    }

    return null;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setCategoryValue(value);

    setError(validate(value));
    if (value !== "") {
      const capitalizedCategory = capitalize(value);
      setDisplayName(`Wi${capitalizedCategory}`);
    } else {
      setDisplayName("");
    }
  };

  const showError = (message) => {
    console.error(message);
    swal.fire({
      title: "An error occurred",
      text: "An occurred",
      icon: "error",
    });
  };

  const showSuccess = () => {
    swal.fire({
      title: "Success",
      icon: "success",
    });
  };

  const isCategoryNew = () => {
    const filteredCategories = categories.filter(
      ({ category_name }) => category_name === categoryValue
    );

    if (filteredCategories.length > 0) {
      return false;
    }

    return true;
  };

  const addCategory = async () => {
    const firestore = firebase.firestore();
    try {
      if (!isCategoryNew()) {
        swal.fire({
          title: "An error occurred",
          text: "Category already exists",
          icon: "error",
        });
      } else {
        await firestore
          .collection("categories")
          .add({ category_name: categoryValue.toLowerCase(), display_name: displayName });

        closeModal();
        showSuccess();
        fetchCategories();
      }
    } catch (e) {
      showError(e.message);
    }
  };

  const editCategory = async () => {
    const firestore = firebase.firestore();

    try {
      await firestore
        .collection("categories")
        .doc(categoryID)
        .set({ category_name: categoryValue.toLowerCase(), display_name: displayName });

      fetchCategories();
      closeModal();
      showSuccess();
    } catch (e) {
      showError(e.message);
    }
  };

  const deleteCategory = async (category_id) => {
    const firestore = firebase.firestore();
    try {
      await firestore.collection("categories").doc(category_id).delete();

      closeModal();
      showSuccess();
      fetchCategories();
    } catch (e) {
      showError(e.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationError = validate(categoryValue);
    setError(validationError);

    if (validationError) {
      return;
    }

    if (showEditCategory) {
      editCategory();
    } else {
      addCategory();
    }
    fetchCategories();
  };

  const columns = [
    {
      Header: "Category",
      accessor: "category_name",
    },
    {
      Header: "Display Name",
      accessor: "display_name",
      disableFilters: true,
    },
    {
      Header: "",
      accessor: "edit",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell: { row } }) => {
        const { category_name, display_name, id } = row.original;
        return (
          <div className="flex justify-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-semibold uppercase text-xs py-2 px-2 rounded shadow"
              onClick={() => {
                setCategoryValue(category_name);
                setCategoryID(id);
                setShowEditCategory(true);
                setDisplayName(display_name);
              }}
            >
              Edit
            </button>
          </div>
        );
      },
    },
    {
      Header: "",
      accessor: "delete",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell: { row } }) => (
        <div className="flex justify-center">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-semibold uppercase text-xs py-2 px-2 rounded shadow"
            onClick={() => {
              const { category_name, id } = row.original;
              swal
                .fire({
                  title: `Delete ${category_name}`,
                  text: `Deletion of the category ${category_name} is irreversable. Are you sure you want to delete?`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes, Delete",
                  reverseButtons: true,
                })
                .then((result) => {
                  if (result.value) {
                    deleteCategory(id);
                    showSuccess();
                  } else {
                    console.log("cancelled");
                  }
                });
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        minRows={0}
        initialState={{
          sortBy: [{ id: "category_name" }],
        }}
        data={categories}
      />
      <Modal
        medium
        isOpen={showEditCategory || showAddCategory}
        title={showEditCategory ? "Edit Category" : "Add Category"}
        toggleModal={() => closeModal()}
        onConfirm={() => closeModal()}
      >
        <form className="w-full border-b border-gray-400" onSubmit={handleSubmit}>
          <div>
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="categoryName"
            >
              Category
              <input
                value={categoryValue}
                id="categoryName"
                name="category_name"
                onChange={handleChange}
                className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
                placeholder="Name of category"
              />
              {error && <p className="text-red-500 text-xs">{error}</p>}
            </label>
            <div className="ml-8">
              <p className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Display Name
              </p>
              <p className="block tracking-wide text-gray-700 mb-2">{displayName}</p>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className={`bg-gray-600 text-white bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
              type="submit"
              style={{ transition: "all .15s ease" }}
            >
              {showEditCategory ? "Save" : "Add"}
            </button>
          </div>
        </form>
        {/* <CRUDTable dataLabel="countries" /> */}
      </Modal>
    </div>
  );
};

export default Categories;
