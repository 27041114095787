import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import axios from "axios";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import wifetchtute from "../assets/img/wifetchordertutorial.mp4";
import firebase from "../config/firebase";

import { GroceryItem } from "../components/GroceryItem";
import Modal from "../components/Modal";
import LoadingSpinner from "../components/LoadingSpinner";
import ProfileForm from "../components/ProfileForm";
import DirectionsForm from "../components/DirectionsForm";

import { AuthContext } from "../context/Auth";
import { StoreContext } from "../context/store";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import NoAvilImg from "../assets/img/no-img-avail.png";

const Shopping = (props) => {
  const [items, setItems] = useState(null);
  // const [inventoryFilter, setInventoryFilter] = useState("");
  // const [pageSize, setPageSize] = useState(40)
  // const [pageIndex, setPageIndex] = useState(0)
  const [products, setProducts] = useState(null);
  const [fullProductList, setFullProductList] = useState([]);
  const [merchantList, setMerchantList] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [isModalOpen, toggleModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [listType, setListType] = useState("");
  const [newItem, setNewItem] = useState("");
  // const [categoryList, setCategoryList] = useState([]); //eslint-disable-line
  const pageSize = 40;

  const auth = useContext(AuthContext);
  const { currentUser } = auth;

  const store = useContext(StoreContext);
  const { customerOrder, setCustomerOrder } = store;

  const urlToken = props.match?.params?.token;
  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(6)
        .substring(1);
    };
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + "-" + s4();
  };

  const fetchData = async () => {
    const db = firebase.firestore();

    //Get list of merchants
    const merchantData = await db
      .collection("merchants")
      .where("status", "==", "approved")
      // .doc(merchantId)
      // .collection("inventory")
      .get();

    let merchantList = merchantData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setMerchantList(merchantList);
  };

  // const fetchCategories = async () => {
  //   const db = firebase.firestore();

  //   const categoriesRef = await db.collection("categories").get();

  //   const categories = categoriesRef.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));

  //   setCategoryList(categories);
  // };

  useEffect(() => {
    setItems(customerOrder);

    fetchData();
    // fetchCategories();
    const listType = props.match.params.category;

    if (listType) {
      setListType(listType);
    } else {
      setListType("grocery");
    }
    const merchantId = props.match.params.token;
    if (merchantId) {
      displayShoppingList(merchantId);
    } else {
      props.history.push("/vendors/grocery");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (items) {
      setCustomerOrder(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const nextPage = async (last) => {
    const field = "product_title";
    // const pageSize = 10
    const db = firebase.firestore();
    const ref = db.collection("merchants").doc(merchantId).collection("inventory");

    const nextResults = await ref
      .orderBy(field)
      .startAfter(last[field])
      .limit(pageSize)
      .get();
    let merchantInventory = nextResults.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    if (merchantInventory.length > 0) {
      setProducts(merchantInventory);
    }
  };

  const prevPage = async (first) => {
    const field = "product_title";
    // const pageSize = 10
    const db = firebase.firestore();
    const ref = db.collection("merchants").doc(merchantId).collection("inventory");

    const nextResults = await ref
      .orderBy(field)
      .endBefore(first[field])
      .limitToLast(pageSize)
      .get();
    let merchantInventory = nextResults.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    if (merchantInventory.length > 0) {
      setProducts(merchantInventory);
    }
  };

  const searchVendorProducts = async (merchantId, filterText) => {
    // console.time("searching shopping list");
    // const db = firebase.firestore();

    // const subString = filterText.toUpperCase();
    // const end = start.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

    // const inventoryData = await db
    //   .collection("merchants")
    //   .doc(merchantId)
    //   .collection("inventory")
    //   .orderBy("product_title")
    //   .startAt(subString)
    //   .limit(pageSize)
    //   .get();

    // let merchantInventory = inventoryData.docs.map((doc) => ({
    //   ...doc.data(),
    //   id: doc.id,
    // }));

    // let merchantInventory = fullProductList.map(obj => console.log(obj.product_title));
    let merchantInventory = fullProductList.filter((obj) =>
      obj.product_title.toLowerCase().includes(filterText.toLowerCase())
    );

    setProducts(merchantInventory.slice(0, pageSize));

    setMerchantId(merchantId);
  };

  const displayShoppingList = async (merchantId) => {
    console.time("display shopping list");
    const db = firebase.firestore();

    const inventoryData = await db
      .collection("merchants")
      .doc(merchantId)
      .collection("inventory")
      .orderBy("product_title")
      .limit(pageSize)
      .get();

    let fullInventoryData = db
      .collection("merchants")
      .doc(merchantId)
      .collection("inventory")
      .orderBy("product_title")
      .get()
      .then((data) => {
        fullInventoryData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setFullProductList(fullInventoryData);
      });

    let merchantInventory = inventoryData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setProducts(merchantInventory);

    setMerchantId(merchantId);

    console.timeEnd("display shopping list");
  };

  const handleIncreaseQty = async (newItem) => {
    // return;

    let cartItems = items.cartItems.map((item) =>
      item.id === newItem.id ? { ...item, quantity: newItem.quantity } : item
    );

    let orderUpdate = {
      ...items,
      cartItems: cartItems,
    };
    setItems(orderUpdate);

    // localStorage.setItem("orderItems", JSON.stringify(orderUpdate));
  };

  const handleDecreaseQty = async (newItem) => {
    let cartItems = items.cartItems.map((item) =>
      item.id === newItem.id ? { ...item, quantity: newItem.quantity } : item
    );

    let orderUpdate = {
      ...items,
      cartItems: cartItems,
    };

    setItems(orderUpdate);

    // localStorage.setItem("orderItems", JSON.stringify(orderUpdate));
  };

  //Add new item to shopping list
  const handleAddItem = async (merchantItem) => {
    let newItems;
    if (Object.entries(items).length < 1) {
      newItems = await handleCreateOrder(listType);
      setItems(newItems);
    } else {
      newItems = items;
    }

    let nameOfItem = "";
    if (newItem) {
      //if item is not from a merchant
      nameOfItem = newItem;
    } else if (merchantItem) {
      //if item is selected from a merchant
      nameOfItem = merchantItem.title;
    } else {
      return;
    }

    const newItemID = guid();
    if (nameOfItem?.length > 0) {
      let cartItems = newItems.cartItems;
      const itemObj = {
        id: newItemID,
        name: nameOfItem,
        quantity: 1,
        merchantId: merchantId, //Force all entered items to have the merchant id of whatever was selected
        customerId: currentUser && currentUser.uid ? currentUser.uid : "",
        dateAdded: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        cost: merchantItem?.product_price || 0.0,
        orderId: newItems.id,
      };

      cartItems.unshift(itemObj);

      let orderUpdate = {
        ...newItems,
        cartItems: cartItems,
      };
      setItems(orderUpdate);

      // setCustomerOrder(orderUpdate)
      // localStorage.setItem("orderItems", JSON.stringify(orderUpdate));
    }

    setNewItem("");
    // fetchData()
  };

  //Delete item from shopping list
  const handleRemoveItem = (itemId) => {
    let cartItems = items.cartItems;

    let newCartItems = cartItems.filter((item) => item.id !== itemId);

    setItems({
      ...items,
      cartItems: newCartItems,
    });

    localStorage.setItem(
      "orderItems",
      JSON.stringify({
        ...items,
        cartItems: newCartItems,
      })
    );
  };

  //Update customer profile information
  const handleSaveProfile = async (data) => {
    const db = firebase.firestore();
    const ref = db.collection("customers").doc(currentUser.uid);
    try {
      await ref.set({ ...data, user_email: currentUser.email });

      swal(
        "Success!",
        "Thanks your information has been successfully updated!",
        "success"
      ).then((value) => {
        showOrderForm();
      });
    } catch (error) {
      // alert(error);
      swal("Sorry", "Something went wrong. Please try again!", "error");
      // setFormError('Sorry but we do not recognize that email/password combination. Please try again')
    }
  };

  //Submit the shopping list
  const handleSubmitList = async () => {
    //Check if user has added cart items
    if (Object.entries(items).length < 1 || items.cartItems.length < 1) {
      swal(
        "Please add items to your list",
        "You need to add items to your list in order to submit your order!",
        "error"
      );
      return;
    }

    setListType("");
    props.history.push("/shopping/options");
  };

  const handleCreateOrder = async (orderType) => {
    const newOrderID = guid();

    const orderData = {
      id: newOrderID,
      customerId: currentUser && currentUser.uid ? currentUser.uid : "",
      type: orderType,
      dateAdded: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      dateUpdated: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      status: "pending",
      status_confirmed: false,
      status_approved: false,
      status_paid: false,
      status_packaged: false,
      status_collect: false,
      status_delivery: false,
      status_completed: false,
      cartItems: [],
      cost: 0,
      directions: "",
      dropofflocation: "",
    };

    return orderData;
  };

  // calculate total bill from items
  const calculateBillTotal = () => {
    let total = 0.0;

    const { cartItems } = items;

    for (let i = 0; i < cartItems.length; i++) {
      const cost = cartItems[i].cost;
      const quantity = cartItems[i].quantity || 1;

      if (cost === 0) {
        total = 0.0;
        break;
      }

      const itemCost = cost * quantity;
      total += itemCost;
    }

    return total;
  };

  const handleConfirmOrder = async (data) => {
    const { dropOfflocation, directions } = data;
    // const { latitude, longitude, directions } = data;
    const db = firebase.firestore();
    await db
      .collection("orders")
      .doc(items.id)
      .update({
        customerId: currentUser.uid,
        quantity: items.cartItems.length,
        // pickuplocation: [latitude, longitude],
        pickuplocation: dropOfflocation,
        directions: directions,
        dateAdded: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        dateUpdated: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        status: "confirmed",
        status_confirmed: true,
        cost: items.cost,
      });

    swal2
      .fire({
        title: "Thank you!",
        html:
          "<p>Your order confirmation number is: <p/> <br/> <b>" +
          items.id +
          "</b><br/>. <p>Please save the above confirmation number as it will be helpful to track your order. We will get back to you within 24 hours except for bank holidays and days when the stores are closed when deliveries will arrive the next business day.</p>",
        icon: "success",
      })
      .then(async (value) => {
        handleToggleModal();

        const recipients = [currentUser.email];

        if (listType?.toLowerCase() === "eats") {
          const additionalRecipients = getRecipients();

          additionalRecipients.forEach((recipient) => {
            recipients.push(recipient);
          });
        }

        const orders = items?.cartItems?.map(({ name, quantity }) => ({
          name,
          quantity,
        }));

        //Send email via firebase function
        await axios.post(
          process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/sendnotification",
          {
            email: recipients,
            status: "confirmed",
            cost: 0,
            orderno: items.id,
            title: "WiFetch: Order Confirmation",
            orders,
          }
        );
      });
  };

  //Cancel this order
  // const handleCancelOrder = async () => {
  //   //Check if the order has been created. If not, deselect the list type
  //   if (Object.entries(items).length > 0) {
  //     swal({
  //       title: "Are you sure?",
  //       text: "Once deleted, you will not be able to recover this record!",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then(async (value) => {
  //       if (value) {
  //         try {
  //           localStorage.setItem("orderItems", "");
  //           //Refresh data
  //           fetchData();
  //         } catch (error) {
  //           console.error(error);
  //         }
  //       }
  //     });
  //   } else {
  //     // setListType("");
  //     setMerchantId(null);
  //     props.history.push("/categories/grocery");
  //   }
  // };

  const handleToggleModal = () => {
    fetchData();
    toggleModal(!isModalOpen);
  };

  // const showProfileForm = () => {
  //   setModalType("profile");
  //   toggleModal(!isModalOpen);
  // };

  const showOrderDirections = () => {
    setModalType("orderDirections");
    toggleModal(!isModalOpen);
  };

  const showOrderForm = () => {
    // setModalType("order");
    const total = calculateBillTotal();

    setItems({
      ...items,
      cost: total,
    });
    setModalType("confirm");
    if (isModalOpen === false) {
      toggleModal(!isModalOpen);
    }
  };

  const getModalTitle = () => {
    switch (modalType) {
      case "order":
        return "Where to Deliver?";
      case "confirm":
        return "Confirm your Order";
      case "orderDirections":
        return "";
      case "profile":
      default:
        return "Confirm your Profile";
    }
  };

  const getRecipients = () => {
    if (process.env.NODE_ENV === "production") {
      return ["lily@wifetch.com", "sophie@wifetch", "tiana@wifetch"];
    }

    return [];
  };

  // console.log(products)

  // const filteredArray = products
  //   ?.sort(function (a, b) {
  //     if (a.product_title.trim() < b.product_title.trim()) {
  //       return -1;
  //     }
  //     if (a.product_title.trim() > b.product_title.trim()) {
  //       return 1;
  //     }
  //     return 0;
  //   })
  //   .filter((item) =>
  //     inventoryFilter.length > 0
  //       ? item.product_title.trim().toLowerCase().includes(inventoryFilter.toLowerCase())
  //       : item.product_title.trim().toLowerCase()
  //   );

  return (
    <div className="flex flex-col min-h-full items-center justify-center">
      <div
        className="w-full h-32 bg-gray-600 mb-6"
        style={{
          backgroundImage:
            "url(https://images.pexels.com/photos/868110/pexels-photo-868110.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full h-full bg-gray-800 bg-opacity-25 flex items-end p-6">
          <p className="text-white text-md">
            <Link to="/vendors" className="text-blue-300">
              Vendors
            </Link>
            <i className="fas fa-chevron-right px-3"></i>
            <span className="text-3xl">
              {merchantList?.find((item) => item.id === merchantId)?.companyName}
            </span>
          </p>
        </div>
      </div>
      {merchantList && (
        <div className="mx-4 py-6 text-sm text-center text-gray-600 container">
          <p>
            {merchantList?.find((item) => item.id === merchantId)?.companyDescription}
          </p>
        </div>
      )}
      <div className="container flex flex-col md:flex-row">
        <div
          className="w-full md:w-3/4 md:mx-3 p-4 bg-white shadow-lg"
          // style={{ minHeight: "500px" }}
        >
          {!products && !urlToken && (
            <div className="flex flex-col content-center justify-center items-center text-center py-10">
              <h1 className="w-1/2 text-2xl uppercase font-bold text-gray-500">
                Choose a vendor
              </h1>
              <h2 className="w-1/2 text-xl py-3 text-gray-400">
                Visit our vendor page to see product lists
              </h2>
              <h2 className="w-1/2 text-xl text-gray-400">OR</h2>
              <h2 className="w-1/2 text-xl py-3 text-gray-400">
                Please type what you wish to order in the cart
              </h2>
            </div>
          )}
          {!products && urlToken && <LoadingSpinner />}
          {products && (
            <div className="w-full">
              <div className="relative">
                <input
                  type="search"
                  className="w-full shadow appearance-none border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Search products..."
                  // disabled={!products || !products?.length > 0}
                  onChange={(e) => {
                    // setInventoryFilter(e.target.value)
                    searchVendorProducts(merchantId, e.target.value);
                  }}
                />
              </div>

              <div className="flex w-full justify-between py-6">
                <p className="text-sm text-gray-600">
                  {/* Page {pageIndex} :: Showing {pageIndex * 50} - {(pageIndex * 50) + 50} of {products.length} items */}
                </p>
                <div className="flex">
                  <button
                    className="text-sm rounded rounded-r-none  px-4 py-2  
                      hover:bg-gray-200  
                      bg-gray-100 
                      text-gray-700
                      border
                      border-gray-600 transition"
                    onClick={() => prevPage(products[0])}
                  >
                    <div className="flex justify-center items-center leading-5">
                      <i className="fas fa-chevron-left px-2"></i>
                      Prev
                    </div>
                  </button>
                  <button
                    className="text-sm rounded rounded-l-none  px-4 py-2  
                    hover:bg-gray-200  
                    bg-gray-100 
                    text-gray-700
                    border
                    border-gray-600 transition"
                    onClick={() => nextPage(products[products.length - 1])}
                  >
                    <div className="flex justify-center items-center leading-5">
                      Next
                      <i className="fas fa-chevron-right px-2"></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap justify-center pt-6">
                {products ? (
                  products.length > 0 ? (
                    products.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col md:w-1/4 w-1/2 p-4 w-full"
                      >
                        <div className="flex-1 mt-4 text-center">
                          <div className="block relative h-48 rounded overflow-hidden bg-gray-400 bg-opacity-25 flex justify-center items-center">
                            <img
                              src={item.image || NoAvilImg}
                              alt={item.companyName}
                              className="h-48 object-cover object-center block"
                              // style={{ height: 200, width: 200 }}
                            />
                          </div>
                          <h3 className="capitalize text-gray-600 text-sm tracking-widest title-font mb-1">
                            {item.product_title}
                          </h3>
                        </div>
                        {Number(item?.product_price) > 0 && (
                          <span className="px-2 text-center">
                            $ {Number(item?.product_price).toFixed(2)}
                          </span>
                        )}
                        <button
                          className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                          onClick={() =>
                            // console.log('click to add')
                            handleAddItem({
                              title: item.product_title,
                              merchant_id: merchantId,
                              product_price: +item.product_price || 0,
                            })
                          }
                        >
                          Add to Cart
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col content-center justify-center items-center text-center py-10">
                      <h1 className="w-1/2 text-2xl uppercase font-bold text-gray-500">
                        No listing available
                      </h1>
                      <h2 className="w-1/2 text-xl py-3 text-gray-400">
                        Please type what you wish to order in the field to the right
                      </h2>
                      <span
                        className="cursor-pointer text-blue-500 underline"
                        onClick={showOrderDirections}
                      >
                        Click here to see how to add items to your order.
                      </span>
                    </div>
                  )
                ) : (
                  <LoadingSpinner />
                )}
              </div>
              <div className="flex w-full justify-between py-6">
                <p className="text-sm text-gray-600">
                  {/* Page {pageIndex} :: Showing {pageIndex * 50} - {(pageIndex * 50) + 50} of {products.length} items */}
                </p>
                <div className="flex">
                  <button
                    className="text-sm rounded rounded-r-none  px-4 py-2  
                      hover:bg-gray-200  
                      bg-gray-100 
                      text-gray-700
                      border
                      border-gray-600 transition"
                    onClick={() => prevPage(products[0])}
                  >
                    <div className="flex justify-center items-center leading-5">
                      <i className="fas fa-chevron-left px-2"></i>
                      Prev
                    </div>
                  </button>
                  <button
                    className="text-sm rounded rounded-l-none  px-4 py-2  
                    hover:bg-gray-200  
                    bg-gray-100 
                    text-gray-700
                    border
                    border-gray-600 transition"
                    onClick={() => nextPage(products[products.length - 1])}
                  >
                    <div className="flex justify-center items-center leading-5">
                      Next
                      <i className="fas fa-chevron-right px-2"></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="w-full mt-5 md:mt-0 md:w-1/4 md:mx-3 p-4 bg-white shadow-lg"
          // style={{ minHeight: "500px" }}
        >
          <div className="w-full flex flex-col" style={{ minHeight: "500px" }}>
            <div className="flex-1">
              <div className="flex-none my-3 justify-between">
                <h2 className="font-semibold text-center">Your Cart</h2>
                <form
                  className="flex w-full"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAddItem();
                  }}
                >
                  <input
                    className="min-w-0 shadow appearance-none border rounded py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={newItem}
                    placeholder={"Type a single item"}
                    onChange={(e) => setNewItem(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    // className="w-1/5 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase py-2 px-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
                  >
                    Add
                  </button>
                </form>
              </div>

              <div
                class="w-full flex flex-col overflow-y-scroll"
                style={{ height: "330px" }}
              >
                {items?.cartItems && items.cartItems.length > 0 ? (
                  items.cartItems
                    .reduce((acc, current) => {
                      if (!acc.includes(current.merchantId)) acc.push(current.merchantId);
                      return acc;
                    }, [])
                    .map((merchantId) => {
                      return (
                        <div>
                          <h3 className="font-semibold bg-gray-300 uppercase px-3">
                            {
                              merchantList?.find((item) => item.id === merchantId)
                                ?.companyName
                            }
                          </h3>
                          <ul>
                            {
                              //sort the items by merchantId
                              items.cartItems
                                .sort((a, b) =>
                                  a.merchantId > b.merchantId
                                    ? 1
                                    : b.merchantId > a.merchantId
                                    ? -1
                                    : 0
                                )
                                .filter((item) => item.merchantId === merchantId)
                                .map((item, index) => (
                                  <li
                                    key={index}
                                    className="last:border-b-0 odd:bg-gray-200"
                                  >
                                    <GroceryItem
                                      list={items}
                                      item={item}
                                      handleIncrease={handleIncreaseQty}
                                      handleDecrease={handleDecreaseQty}
                                      onRemoveItem={handleRemoveItem}
                                    />
                                  </li>
                                ))
                            }
                          </ul>
                        </div>
                      );
                    })
                ) : (
                  <div className="h-auto p-10 text-center">
                    <p className="text-gray-600 py-3">Your {items?.type} list is empty</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-none flex justify-end p-2">
              <button
                className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={handleSubmitList}
              >
                Confirm Order
              </button>
            </div>
          </div>
        </div>

        <Modal
          small={modalType !== "orderDirections"}
          medium={modalType === "orderDirections"}
          title={getModalTitle()}
          isOpen={isModalOpen}
          toggleModal={handleToggleModal}
          onConfirm={handleToggleModal}
        >
          <div className="p-1">
            {modalType === "profile" && (
              <ProfileForm onAdd={handleSaveProfile} onCancel={handleToggleModal} />
            )}

            {modalType === "order" && (
              <DirectionsForm onAdd={handleConfirmOrder} onCancel={handleToggleModal} />
            )}

            {modalType === "confirm" && (
              <div className="flex flex-col justify-between px-4 pb-2">
                <p className="text-xl mt-2 mb-4">Does this complete your order?</p>
                {items.cartItems?.length ? (
                  <ul
                    className="w-full p-3 mb-3 bg-gray-200 overflow-y-scroll"
                    style={{ height: "200px" }}
                  >
                    {items.cartItems?.map(({ name, quantity, cost }, key) => (
                      <li key={key} className="text-black">
                        <div className="flex">
                          <p className="w-2/3">
                            {name} x {quantity}
                          </p>
                          <p className="flex-1 pl-2">
                            {cost ? `$${(cost * quantity).toFixed(2)}` : ""}
                          </p>
                        </div>
                      </li>
                    ))}
                    {/* {order && order.map((item, index) => { return (item.cartItems.filter(elem => elem.customerId == modalContent.customerId).map((elem, key) => <p className="text-black">{elem.name}</p>)) })} */}
                  </ul>
                ) : (
                  <p className="text-lg py-3">No items were added to this order</p>
                )}
                {/* <p className="text-md py-6">If you wish to cancel your order you can click the button below</p> */}
                <div className="flex justify-end">
                  <p>{items.cost > 0 ? `Total: $${items?.cost.toFixed(2)} BBD` : ""}</p>
                </div>
                <div className="flex justify-between w-full items-center">
                  <button
                    className="mt-3 text-red-500 bg-transparent text-xs md:text-sm font-bold uppercase px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={handleToggleModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded"
                    onClick={() => setModalType("order")}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
            {modalType === "orderDirections" && (
              <div className="flex justify-center">
                <video id="background-video" loop autoPlay>
                  <source src={wifetchtute} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Shopping;
