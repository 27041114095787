import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import firebase from "../config/firebase";

import LoadingSpinner from "../components/LoadingSpinner.js";
import ComingSoon from "../components/ComingSoon.js";
import CategoryList from "../components/CategoryList.js";
import Modal from "../components/Modal";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

import { StoreContext } from "../context/store";

export default function Landing({ history }) {
  const [categoryList, setCategoryList] = useState(null);
  const [merchantList, setMerchantList] = useState(null);
  const [showDisclaimer, toggleDisclaimer] = useState(
    !localStorage.getItem("showDisclaimer") ? true : false
  );

  const store = useContext(StoreContext);
  const { countryFilter } = store;

  useEffect(() => {
    fetchCategories();
    fetchMerchants();
  }, []); //eslint-disable-line

  const fetchMerchants = async () => {
    const db = firebase.firestore();

    const merchantData = await db
      .collection("merchants")
      .where("status", "==", "approved")
      // .doc(merchantId)
      // .collection("inventory")
      .get();

    let merchantList = merchantData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setMerchantList(merchantList);
  };

  const fetchCategories = async () => {
    const db = firebase.firestore();

    const categoriesRef = await db
      .collection("categories")
      .orderBy("category_name")
      .get();

    const categories = categoriesRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    const filteredCategories = categories.filter(
      ({ category_name }) => category_name !== "other"
    );
    setCategoryList(filteredCategories);
  };

  const handleDismissNotice = () => {
    toggleDisclaimer(false);
    localStorage.setItem("showDisclaimer", JSON.stringify(false));
  };

  const categoryMerchantFilter = (merchantCategory, category) => {
    if (!merchantCategory) {
      return false;
    }

    const filteredCategory = categoryList?.filter(
      ({ category_name }) => category_name === category
    );
    const categoryID = filteredCategory[0]?.id;

    if (merchantCategory?.value === categoryID) {
      return true;
    }
    return false;
  };

  // TODO: Transition this static array to firestore
  // const shoppingCategories = ["grocery", "pharmacy", "hardware", "beverage", "restaurants", "fish", "farm", "gifts"];

  // console.log(formattedCategoriesToDisplay)

  const filteredVendors = merchantList
    ?.sort(function (a, b) {
      if (a.companyName < b.companyName) {
        return -1;
      }
      if (a.companyName > b.companyName) {
        return 1;
      }
      return 0;
    })
    .filter((item) => categoryMerchantFilter(item?.category, "grocery"))
    .filter((item) =>
      countryFilter !== ""
        ? item.companyCountry?.label?.toLowerCase() === countryFilter.toLowerCase()
        : item
    );

  const handleSelectCategory = (category_name) => {
    history.push(`/vendors/${category_name}`);
  };

  return (
    <>
      <Navbar />
      <main className="bg-white">
        <div
          className="flex flex-col relative content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div className="flex flex-col text-center w-full bg-primary-yellow">
            <h1 className="text-md py-3 uppercase font-medium title-font text-gray-900">
              Shop By Category
            </h1>
          </div>
          <div className="container relative mx-3">
            {categoryList ? (
              <CategoryList data={categoryList} handleClick={handleSelectCategory} />
            ) : (
              <LoadingSpinner />
            )}

            <section className="text-gray-700 body-font">
              <div className="container px-5 py-12 mx-auto">
                <div className="flex flex-col text-center w-full">
                  <h1 className="text-lg font-medium title-font mb-4 text-gray-900">
                    Showing Groceries
                  </h1>
                </div>
                <div className="flex flex-wrap -m-4 justify-center">
                  {filteredVendors ? (
                    filteredVendors.length > 0 ? (
                      filteredVendors.slice(0, 8).map((item, index) => (
                        <div
                          key={index}
                          className="lg:w-1/4 md:w-1/2 p-4 w-full cursor-pointer"
                          onClick={() => {
                            history.push(
                              "/shopping/lists/" + item.category?.label + "/" + item.id
                            );
                          }}
                        >
                          <div className="block relative h-48 rounded overflow-hidden bg-gray-400 bg-opacity-25 flex justify-center items-center">
                            <img
                              src={item.image}
                              alt={item.companyName}
                              className="object-cover object-center block"
                              // style={{ height: 200, width: 200 }}
                            />
                          </div>
                          <div className="mt-4 text-center">
                            <h3 className="text-gray-600 text-sm tracking-widest title-font mb-1">
                              {item.companyName}
                            </h3>

                            <button className="text-sm border-2 rounded-lg border-yellow-400 p-2 m-3 w-2/3">
                              Start Shopping
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <ComingSoon
                        title="Coming Soon..."
                        subtitle="Sorry, we do not have any vendors for this category yet. Try switching to another category or different territory"
                      />
                    )
                  ) : (
                    <LoadingSpinner />
                  )}
                </div>
                <div className="flex flex-col text-center w-full pt-16">
                  <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                    WiFetch. We do it for you. Caribbean Marketplace
                  </h1>
                  <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                    WiFetch is transforming the way goods move around in the Caribbean
                    locally, enabling anyone to have anything delivered on-demand. Our
                    revolutionary local Logistics platform connects customers with local
                    Fetchers who can deliver anything from any of our partner vendors
                    within hours. We empower communities to shop local and remotely and
                    empower businesses through our API to offer delivery at the most
                    economical/most efficient cost.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
      <Modal
        medium
        isOpen={showDisclaimer}
        toggleModal={handleDismissNotice}
        // title="Welcome to our new website!"
        onConfirm={handleDismissNotice}
      >
        <div className="flex flex-col justify-around content-center items-center h-full">
          <div className="flex-1 flex flex-col justify-center items-center py-6 md:py-3 text-center">
            <img
              className="pb-3"
              src={require("../assets/img/wifetch_bannerlogo.png")}
              style={{ maxHeight: 100 }}
              alt={"Wi Fetch logo"}
            />
            <h2 className="text-center text-xl md:text-2xl font-bold text-gray-800 py-3">
              Welcome to WiFetch!
            </h2>
            <p className="text-center text-md md:w-2/3">
              We have a new layout to help you shop better. Kindly note that while you get
              used to the new layout, please let us know of{" "}
              <Link to="/contactus" className="text-blue-500 underline">
                any issues that you face
              </Link>{" "}
              because your user experience matters to us.
            </p>
          </div>
          <div className="w-full flex flex-col justify-center items-center">
            <button
              className="text-xl bg-blue-500 hover:bg-blue-700 text-white font-semibold py-3 mb-10 px-4 rounded"
              onClick={handleDismissNotice}
            >
              Ok, I'll try it out
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
