import React from "react";
import { Link } from "react-router-dom";

const FooterLarge = () => {
  return (
    <>
      <section className="text-gray-700 body-font border-b border-t border-gray-400">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              WiFetch is Powered by UNDP
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              UNDP and WiFETCH will connect businesses - who have lost customers - to
              buyers; it will also continue ensuring safe deliveries at home, assisting
              Barbadians who face special difficulties in procuring for their daily needs,
              by integrating hotline and volunteer services.
            </p>
            <div className="mt-6">
              <a
                className="w-auto bg-transparent border-red-500 border-2 text-red-500 text-sm font-semibold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none"
                href="https://www.bb.undp.org/content/barbados/en/home/presscenter/pressreleases/20191/digital-economy-provides-crisis-jobs-and-safe-services/"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Announcement
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white border-b border-gray-400">
        <div className="w-full text-center pb-16">
          <p className="pt-6 mb-6 text-4xl text-gray-800 font-light font-semibold">
            Safe & Secure
          </p>
          <ul className="list-style-none w-full flex flex-wrap content-center justify-center items-center">
            <li className="inline-block mx-3">
              <img
                src={require("../assets/img/mastercard.png")}
                style={{ height: 70 }}
                alt={""}
              />
            </li>
            <li className="inline-block mx-3">
              <img
                src={require("../assets/img/visa.png")}
                style={{ height: 80 }}
                alt={""}
              />
            </li>
            <li className="inline-block mx-3">
              <img
                src={require("../assets/img/americanexpress.png")}
                style={{ height: 100 }}
                alt={""}
              />
            </li>
            <li className="inline-block mx-3">
              <img
                src={require("../assets/img/paypal.png")}
                style={{ height: 100 }}
                alt={""}
              />
            </li>
            <li className="inline-block mx-3">
              <img
                src={require("../assets/img/mmoney.png")}
                style={{ height: 50 }}
                alt={""}
              />
            </li>
            <li className="inline-block mx-3">
              <img
                src={require("../assets/img/scotiabank.png")}
                style={{ height: 50 }}
                alt={""}
              />
            </li>
            <li className="inline-block mx-3">
              <img
                src={require("../assets/img/cs_icon.png")}
                style={{ height: 50 }}
                alt={""}
              />
            </li>
            <li className="inline-block mx-3">
              <img
                src={require("../assets/img/rbc_icon.png")}
                style={{ height: 50 }}
                alt={""}
              />
            </li>
          </ul>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-600">
            All prices in Barbados dollars
          </p>
        </div>
      </section>
      <footer className="text-gray-700 body-font">
        <div className="container px-5 py-6 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-no-wrap flex-wrap flex-col">
          <div className="flex-grow flex flex-wrap md:mt-0 mt-10 md:text-left text-left justify-center">
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className="uppercase title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                WiFetch Inc
              </h2>
              <nav className="list-none mb-10">
                <li className="text-gray-600 hover:text-gray-800">Suite 1, Durants</li>
                <li className="text-gray-600 hover:text-gray-800">Christ Church</li>
                <li className="text-gray-600 hover:text-gray-800">Barbados</li>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className="uppercase title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                Company
              </h2>
              <nav className="list-none mb-3">
                <li>
                  <Link to="/about" className="text-gray-600 hover:text-blue-600">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/privacypolicy" className="text-gray-600 hover:text-blue-600">
                    Privacy
                  </Link>
                </li>
                <li>
                  <Link to="/termsofuse" className="text-gray-600 hover:text-blue-600">
                    Terms Of Use
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" className="text-gray-600 hover:text-blue-600">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/merchant_apply"
                    className="text-gray-600 hover:text-blue-600"
                  >
                    Become a Vendor
                  </Link>
                </li>
                <li>
                  <Link to="/driver_apply" className="text-gray-600 hover:text-blue-600">
                    Become a Driver
                  </Link>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full px-4">
              <h2 className="uppercase title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                Need Help?
              </h2>
              <nav className="list-none mb-10">
                <li className="text-gray-600 hover:text-gray-800">
                  Phone: (246) 264-8994
                </li>
                <li className="text-gray-600 hover:text-gray-800">
                  Email: info@wifetch.com
                </li>
                <li className="text-gray-600 hover:text-gray-800">
                  Hours of Operation: 7am to 7pm (Mon to Sun)
                </li>
              </nav>
            </div>
          </div>
        </div>
        <div className="bg-gray-200">
          <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row justify-center">
            <p className="text-gray-500 text-sm text-center sm:text-left">
              {new Date().getFullYear()} &copy;
              <a
                href="https://twitter.com/knyttneve"
                rel="noopener noreferrer"
                className="text-gray-600 ml-1"
                target="_blank"
              >
                WiFetch.com
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterLarge;
