import React, { useEffect } from "react";
// import { AuthContext } from "../context/Auth";
import swal from "sweetalert";

import MerchantForm from "../components/MerchantForm";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import app from "../config/firebase";

const MerchantSignUp = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddMerchant = async (data) => {
    // e.preventDefault();

    // validate();
    const db = app.firestore();
    const ref = db.collection("merchants");
    try {
      data.status = "pending"; // new vendors require a pending status
      await ref.add(data);

      swal(
        "Registration Complete",
        "Your application has been submitted. Verification can take approximately two(2) business days.",
        "success"
      ).then((value) => {
        props.history.push("/");
      });
    } catch (error) {
      console.error(error);
    }

    // const objId = guid();

    // // return;

    // if (Object.keys(errors).length < 1) {
    //   let uploadData = values;

    //   const db = app.firestore();

    //   let merchantRecords = await db
    //     .collection("merchants")
    //     .where("companyEmail", "==", uploadData.companyEmail)
    //     .get();
    //   let merchantInfo = merchantRecords.docs.map((doc) => ({
    //     ...doc.data(),
    //     id: doc.id,
    //   }));

    //   if (merchantInfo.length > 0) {
    //     swal("Error", "Sorry but it looks like you have already registered", "error");
    //     return;
    //   }

    //   if (Object.keys(businessLogo).length < 1) {
    //     const remoteUri = await uploadPhotoAsync(businessLogo, `${objId}`);
    //     uploadData.image = remoteUri;
    //   }

    //   uploadData.dateAdded = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    //   uploadData.status = "pending";

    //   const ref = db.collection("merchants");
    //   try {
    //     await ref.add(uploadData);
    //     // merchantCheck();
    //     // uploadBusinessLogo();
    //     resetForm();
    //     swal(
    //       "Registration Complete",
    //       "Your application has been submitted. Verification can take approximately two(2) business days.",
    //       "success"
    //     ).then((value) => {
    //       history.push("/");
    //     });
    //   } catch (error) {
    //     console.error(error);
    //     setFormError(error.message);
    //   }
    // } else {
    //   swal(
    //     "Oops",
    //     "Sorry but you may not have submitted all of the required fields. Please try again",
    //     "error"
    //   );
    // }
  };

  return (
    <div>
      <div className="h-screen w-full">
        <Navbar />
        <main>
          <div
            className="w-full px-8 my-10 mx-auto flex-1 flex flex-row justify-around items-center"
            style={{
              minHeight: 200,
            }}
          >
            <div className="px-3 text-center">
              <h1 className="text-gray-800 font-normal text-5xl md:text-6xl">
                Become a Vendor
              </h1>
              <p className="text-lg capitalize">Start selling on WiFetch</p>
            </div>
          </div>
          <div className="w-full pb-32">
            <div className="w-full flex justify-center">
              <div className="md:w-6/12 bg-white relative mx-3 py-3 px-5 shadow-lg rounded-lg">
                <MerchantForm
                  // editing={merchant && Object.entries(merchant).length > 0 ? true : false}
                  // merchantInfo={null}
                  onAdd={handleAddMerchant}
                  // onEdit={() => console.log("Edit merchant")}
                  // handleClose={callbackCloseModal}
                />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default MerchantSignUp;
