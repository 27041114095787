import useYup from "@usereact/use-yup";
import React, { useState } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";
import swal from "sweetalert2";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import SocialLogins from "../components/SocialLogins";

import app from "../config/firebase";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

const SignUp = ({ history }) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [formError, setFormError] = useState("");
  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    const { email, password } = values;

    validate().then(async ({ errors, isValid }) => {
      if (isValid) {
        swal
          .fire({
            title: "Complete Registration",
            html:
              "<p>Continue to agree to our <a href='/termsofuse' style='color:#4299e1' target='_blank'>Terms of Service</a>" +
              ", <a href='/privacypolicy' target='_blank' style='color:#4299e1'>Privacy Policy</a> and finalize registration.<p><br/>" +
              "We will send an optional verification email once registration is complete.",
            icon: "info",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Agree",
            cancelButtonText: "Cancel",
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.value) {
              try {
                await app.auth().createUserWithEmailAndPassword(email, password);

                await app.auth().currentUser.sendEmailVerification();

                history.push("/categories/wigroceries");
              } catch (error) {
                // alert(error);
                setFormError(
                  "An error has occured, please try again or check if already registered."
                );
              }
            }
          });
      } else {
        setFormError("Please fill in all required fields");
      }
    });
  };

  return (
    <>
      <Navbar />
      <main>
        <section className="absolute w-full h-full">
          <div className="absolute top-0 w-full h-full"></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full md:w-8/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                  {formError && (
                    <div
                      className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <strong className="font-bold">Error!</strong>
                      <span className="block sm:inline"> {formError}</span>
                    </div>
                  )}

                  <div className="rounded-t mb-0 px-6 py-3">
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-2xl font-bold">
                        Create an Account
                      </h6>
                      <div className="text-md mt-3 text-gray-600 mb-3">
                        Before you submit your order, please sign up below
                      </div>
                    </div>
                  </div>

                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form onSubmit={handleSignUp}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Enter a valid email"
                          value={values.email}
                          onChange={(e) => handleChange(e)}
                          style={{ transition: "all .15s ease" }}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs italic">{errors.email}</p>
                        )}
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Choose a password"
                          value={values.password}
                          onChange={(e) => handleChange(e)}
                          style={{ transition: "all .15s ease" }}
                        />
                        {errors.password && (
                          <p className="text-red-500 text-xs italic">{errors.password}</p>
                        )}
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                        >
                          Create Account
                        </button>
                      </div>
                    </form>
                    <div className="flex flex-col items-center mt-3">
                      <SocialLogins />
                      <p className="text-md mt-3 text-gray-600">
                        Already have an account?{"  "}
                        <Link to="/login" className="text-blue-500">
                          Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </main>
    </>
    // <div className="w-full py-3 px-3 md:px-10 text-center md:text-left">
    //   <h4 className="text-2xl text-center font-semibold">Ready to order?</h4>
    //   <p className="leading-relaxed mt-1 mb-4 text-gray-600">
    //     Before you submit your order, please sign up below
    //   </p>
    //   {formError && (
    //     <div
    //       className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
    //       role="alert"
    //     >
    //       <strong className="font-bold">Error!</strong>
    //       <span className="block sm:inline"> {formError}</span>
    //     </div>
    //   )}
    // </div>
  );
};

export default withRouter(SignUp);
