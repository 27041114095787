/* eslint-disable no-loop-func */
import React, { useState } from "react";
import FileSaver from "file-saver";
import Modal from "../components/Modal";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import firebase from "../config/firebase";

const headers = [
  { label: "Order Id", key: "id" },
  { label: "Items", key: "items" },
  { label: "Cost", key: "cost" },
  { label: "Email", key: "user_email" },
  { label: "Directions", key: "directions" },
  { label: "Pick Up", key: "pickuplocation" },
  { label: "Name", key: "user_name" },
  { label: "Home Number", key: "user_home_number" },
  { label: "Mobile Number", key: "user_mobile_number" },
  { label: "Address", key: "user_address" },
  { label: "Parish", key: "user_parish" },
  { label: "Status", key: "status" },
  { label: "Store", key: "store" },
  { label: "Date Added", key: "dateAdded" },
  { label: "Date Updated", key: "dateUpdated" },
];

const formatRecord = (order, merchantData) => {
  const formattedOrder = {
    items: "",
    cost: "",
    directions: "",
    pickuplocation: "",
    user_name: "",
    user_email: "",
    user_home_number: "",
    user_mobile_number: "",
    user_address: "",
    user_parish: "",
    store: "",
    status: "",
    dateAdded: "",
    dateUpdated: "",
  };

  // only merge properties in orders into formattedOrders
  Object.keys(order)
    .filter((key) => key in formattedOrder)
    .forEach((key) => (formattedOrder[key] = order[key]));

  if (order.customer) {
    Object.keys(order.customer)
      .filter((key) => key in formattedOrder)
      .forEach((key) => (formattedOrder[key] = order.customer[key]));
  }

  formattedOrder.id = order.id;
  let mId = "";
  order.cartItems.forEach((item) => {
    merchantData.forEach((merchant) => {
      if (merchant.id === item.merchantId) {
        mId = merchant.companyName;
        //if store is already a substring dont repeat it, this allows multiple stores.
        formattedOrder.store +=
          formattedOrder.store.indexOf(merchant.companyName) === -1
            ? `${merchant.companyName}  \n`
            : "";
      }
    });
    formattedOrder.items += `${item.name}  ${item.quantity} (${mId}) \n`;
  });

  return formattedOrder;
};

let groupBy = function (array, key) {
  return array.reduce(function (result, item) {
    (result[item[key]] = result[item[key]] || []).push(item);
    // (result[] = result[]).push(item);
    return result;
  }, {});
};

//puts the overhead to get data in this component.
const getData = async () => {
  const db = firebase.firestore();

  let merchantData = await db.collection("merchants").orderBy("dateAdded", "desc").get();
  let merchantList = merchantData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  let customerData = await db.collection("customers").get();
  let customerList = customerData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  let orderData = await db.collection("orders").orderBy("dateAdded", "desc").get();
  let orderList = orderData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  let itemData = await db.collectionGroup("items").get();
  let itemList = itemData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  var merchantGroups = await groupBy(itemList, "merchantId");

  const formattedOrders = Object.entries(merchantGroups)
    .map((item) => {
      const orderGroups = groupBy(item[1], "orderId");

      let merchantInfo = merchantList.filter((merchant) => merchant.id === item[0])[0];

      let arrayInfo = [];
      Object.keys(orderGroups).forEach((item) => {
        let orderInfo = orderList.filter((order) => order.id === item)[0];
        if (orderInfo.status !== "pending" && orderInfo.status !== "cancelled") {
          let customerInfo = customerList.find(
            (customer) => customer.id === orderInfo.customerId
          );
          // Get the items ordered for this order and merchant
          let cart_elements = merchantInfo
            ? itemList.filter(
                (item) =>
                  item.orderId === orderInfo.id && item.merchantId === merchantInfo.id
              )
            : itemList.filter((item) => item.orderId === orderInfo.id);
          arrayInfo.push({
            // cart_items: { ...Object.values(customerGroups) }[0],
            ...orderInfo,
            cartItems: cart_elements,
            customer: customerInfo,
            customerName: customerInfo?.user_name,
            merchantName: merchantInfo ? merchantInfo.companyName : "None",
            merchantInfo,
          });
        }
      });

      return arrayInfo;
    })
    .flat(2)
    .sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)); //Flattening an array reduces it from multi-dimensional to uni-dimensional

  return formattedOrders;
};

const generateCSV = (data, merchants) => {
  const csvData = data.map((order) => formatRecord(order, merchants));

  return csvData;
};

const ExportXlsx = ({ merchants }) => {
  const [isLoading, setLoading] = useState(false);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName, headers) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (var C = range.s.r; C <= range.e.r; ++C) {
      var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
      if (!ws[address]) continue;

      ws[address].s = { fill: { fgColor: { rgb: "86BC25" } } };

      headers.forEach((header) => {
        if (header.key === ws[address].v) {
          ws[address].v = header.label;
        }
      });
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleClick = async () => {
    if (isLoading) {
      return;
    }

    setLoading(true);
    const fileName = format(new Date(), "yyyy-MM-dd-HHmmss") + "_orders";
    const data = await getData();
    const csvData = generateCSV(data, merchants);
    setLoading(false);
    exportToCSV(csvData, fileName, headers);
  };

  return (
    <>
      <button
        className="md:m-3 bg-orange-500 text-white active:bg-blue-700 text-sm px-3 md:px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
        onClick={handleClick}
        disabled={isLoading}
      >
        <i className="fas fa-download px-3"></i>
        <span className="hidden md:inline">Export</span>
      </button>
      <Modal small isOpen={isLoading} title="">
        <div className="text-center py-24 flex flex-col justify-center items-center">
          <span className="text-blue-500 opacity-75 top-1/2 my-0 mx-auto block">
            <i className="fas fa-spinner fa-spin fa-5x"></i>
          </span>
          <p className="pt-5 text-3xl text-blue-400 font-bold">Preparing Download....</p>
        </div>
      </Modal>
    </>
  );
};

export default ExportXlsx;
