import React, { useState, useEffect, useContext } from "react";

import { withRouter } from "react-router";
import { format } from "date-fns";
import useYup from "@usereact/use-yup";
import Select from "react-select";
import firebase from "firebase";
import * as yup from "yup";
import swal from "sweetalert2";

import app from "../config/firebase";
import { AuthContext } from "../context/Auth";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object().shape({
  companyEmail: yup.string().email().required("Email is required"),
  contactName: yup.string().required("Contact name is required"),
  contactNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Contact number is required"),
  // lastName: yup.string().required("Contact last name is required"),
  companyAddress: yup.string().required("Company address is required"),
  companyDescription: yup.string().required("Company description is required"),
  companyCountry: yup.string().required("Country is required"),
  category: yup.string().required("Category is required"),
  companyName: yup.string().required("Company name is required"),
  companyLogo: yup.object().shape({
    file: yup
      .object()
      .shape({
        name: yup.string().required("File is required"),
        type: yup
          .string()
          .test("fileType", "File must be JPG, JPEG or PNG", (value) =>
            ["image/jpg", "image/jpeg", "image/png"].includes(value)
          ),
      })
      .label("File"),
  }),
  deliveryFee: yup.number(),
  serviceFee: yup.number(),
});

const MerchantForm = (props) => {
  const auth = useContext(AuthContext);
  const isAdmin = props.isAdmin || false;

  // console.log(auth)
  const { currentUser } = auth;
  const initialState = {
    companyEmail: "",
    contactName: "",
    contactNumber: "",
    companyDescription: "",
    companyAddress: "",
    companyCountry: "",
    category: "",
    status: "",
    companyName: "",
    isActive: false,
    serviceFee: 0,
    deliveryFee: 0,
  };
  const [values, setValues] = useState(initialState);
  const [isSubmitting, setSubmitting] = useState(false);

  const [businessLogo, setBusinessLogo] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  // const [image, setImage] = useState(null);
  const [formError, setFormError] = useState("");
  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  };

  const fetchCountries = async () => {
    const db = firebase.firestore();

    const dataRef = await db.collection("countries").orderBy("country_name").get();

    const dataCollection = dataRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setCountryList(dataCollection);
  };

  const fetchCategories = async () => {
    const db = firebase.firestore();

    const categoriesRef = await db
      .collection("categories")
      .orderBy("category_name")
      .get();

    const categories = categoriesRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setCategoryList(categories);
  };

  useEffect(() => {
    setValues(props.merchantInfo ? props.merchantInfo : initialState);
    fetchCategories();
    fetchCountries();
  }, [props.merchantInfo]); // eslint-disable-line

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleLogoChange = (e) => {
    const { files } = e.target;
    const logoFile = files[0];
    // console.log(logoFile, logoFile.name)
    if (logoFile) {
      setValues({
        ...values,
        companyLogo: { file: { name: logoFile.name, type: logoFile.type } },
      });
      setBusinessLogo(logoFile);
    }
  };

  // const resetForm = () => {
  //   setValues(initialState);
  //   setBusinessLogo(null);

  // };

  const uploadPhotoAsync = (uri, fileName) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      // const response = await fetch(uri);
      // const file = await response.blob();

      let uploadRef = firebase.storage().ref(fileName);

      const snapshot = await uploadRef.put(uri);
      let downloadURL = await uploadRef.getDownloadURL();
      console.log("download url", downloadURL, snapshot);
      res(downloadURL);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(values);

    setSubmitting(true);
    console.log(values, businessLogo);
    // return;
    validate();

    const objId = guid();

    // return;

    //If there are no form errors
    if (Object.keys(errors).length < 1) {
      let uploadData = values;

      const db = app.firestore();

      let merchantRecords = await db
        .collection("merchants")
        .where("companyEmail", "==", uploadData.companyEmail)
        .get();
      let merchantInfo = merchantRecords.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // console.log(merchantInfo);
      //Check if merchant exists already
      if (merchantInfo.exists) {
        swal.fire(
          "Error",
          "Sorry but it looks like you have already registered",
          "error"
        );
        setSubmitting(false);
        return;
      }
      //Check if image logo was provided

      if (businessLogo && Object.keys(businessLogo)?.length < 1) {
        const remoteUri = await uploadPhotoAsync(businessLogo, `${objId}`);
        console.log(remoteUri);
        uploadData.image = remoteUri;
      }

      // const ref = db.collection("merchants");
      try {
        // merchantCheck();
        // uploadBusinessLogo();

        //update merchant info
        if (props.editing) {
          uploadData.dateUpdated = format(new Date(), "yyyy-MM-dd HH:mm:ss");

          const db = app.firestore();

          let merchantRecords = await db
            .collection("merchants")
            .where("companyEmail", "==", uploadData.companyEmail)
            .get();

          let merchantInfo = merchantRecords.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          // Check if status was changed or remained approved and if the previous status was not approved.
          // If status was changed to approved from any previous status besides approved, send invite again.
          const doSendInvite =
            uploadData.status === "approved" && merchantInfo[0].status !== "approved";

          await props.onEdit(uploadData, doSendInvite);
        } else {
          uploadData.dateAdded = format(new Date(), "yyyy-MM-dd HH:mm:ss");
          // uploadData.status = "pending";
          // await ref.add(uploadData);
          await props.onAdd(uploadData);
        }
      } catch (error) {
        console.error(error);
        setFormError(error.message);
      } finally {
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
      swal.fire(
        "Oops",
        "Sorry but you may not have submitted all of the required fields. Please try again",
        "error"
      );
    }
  };

  const getValue = (name) => values[name];

  // const countryList = ["Barbados", "Trinidad", "St Lucia"];
  const countryOptions = countryList.map((item) => ({
    value: item.country_name,
    label: item.country_name,
  }));

  const categoryOptions = categoryList.map(({ category_name, id }) => ({
    value: id,
    label: category_name,
  }));

  const statusOptions = ["pending", "approved", "cancelled"].map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <div>
      {formError && (
        <div
          className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-32"
          role="alert"
        >
          <span className="block sm:inline"> {formError}</span>
        </div>
      )}
      {/* {verificationWarning && (
                                    <div
                                        className="w-100 m-3 bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mx-32"
                                        role="alert"
                                    >
                                        <span className="block sm:inline"> {verificationWarning}</span>
                                    </div>
                                )} */}
      <form onSubmit={handleSubmit}>
        <div className="flex">
          <div className={props.editing ? "w-1/2 mt-6" : "w-full mt-6"}>
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="company-name"
            >
              Business Name
            </label>
            <input
              value={getValue("companyName")}
              id="company-name"
              name="companyName"
              onChange={handleChange}
              className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
              placeholder="Name of company"
            />
            {errors.companyName && (
              <p className="text-red-500 text-xs">{errors.companyName}</p>
            )}
          </div>
          {props.editing && (
            <div className="w-1/2 mt-6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="company-name"
              >
                Status
              </label>
              <Select
                isSearchable={false}
                name="status"
                placeholder="Select an option"
                className="w-full px-3"
                options={statusOptions}
                value={{ label: values.status, value: values.status }}
                onChange={(result) =>
                  setValues({
                    ...values,
                    status: result.value,
                  })
                }
              />
              {errors.status && <p className="text-red-500 text-xs">{errors.status}</p>}
            </div>
          )}
        </div>

        <div className="relative w-full mb-3 mt-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="company-category"
          >
            What category is your business?
          </label>
          <Select
            isSearchable={false}
            name="category"
            placeholder="Select an option"
            options={categoryOptions}
            value={values.category}
            onChange={(value) =>
              setValues({
                ...values,
                category: value,
              })
            }
          />
          {errors.cateogry && <p className="text-red-500 text-xs">{errors.cateogry}</p>}
        </div>

        <div className="relative w-full mb-3 mt-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="company-description"
          >
            Company Description
          </label>
          <textarea
            rows={3}
            value={getValue("companyDescription")}
            name="companyDescription"
            onChange={handleChange}
            className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
            placeholder="Please describe the nature of your business"
          />
          {errors.companyDescription && (
            <p className="text-red-500 text-xs">{errors.companyDescription}</p>
          )}
        </div>

        <div className="relative w-full mb-3 mt-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="email"
          >
            Business Email
          </label>
          <input
            name="companyEmail"
            placeholder="Email for contacting company"
            value={getValue("companyEmail")}
            onChange={handleChange}
            className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
          />
          {errors.companyEmail && (
            <p className="text-red-500 text-xs">{errors.companyEmail}</p>
          )}
        </div>

        <div className="relative w-full mb-3 mt-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="email"
          >
            Primary Contact Number
          </label>
          <input
            id="number"
            name="contactNumber"
            placeholder="Contact number for contacting company"
            value={getValue("contactNumber")}
            onChange={handleChange}
            className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
          />
          {errors.contactNumber && (
            <p className="text-red-500 text-xs">{errors.contactNumber}</p>
          )}
        </div>
        <div className="relative w-full mb-3 mt-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="email"
          >
            Name of Contact Person
          </label>
          <input
            name="contactName"
            placeholder="Name of Contact Person"
            value={getValue("contactName")}
            onChange={handleChange}
            className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
          />
          {errors.contactName && (
            <p className="text-red-500 text-xs">{errors.contactName}</p>
          )}
        </div>

        <div className="relative w-full mb-3 mt-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="company-address"
          >
            Company Address
          </label>
          <textarea
            rows={5}
            value={getValue("companyAddress")}
            name="companyAddress"
            onChange={handleChange}
            className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
            placeholder="Address of the company"
          />
          {errors.companyAddress && (
            <p className="text-red-500 text-xs">{errors.companyAddress}</p>
          )}
        </div>

        <div className="relative w-full mb-3 mt-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="company-country"
          >
            Which country are you from?
          </label>
          <Select
            isSearchable={false}
            name="companyCountry"
            placeholder="Select an option"
            options={countryOptions}
            value={values.companyCountry}
            onChange={(value) =>
              setValues({
                ...values,
                companyCountry: value,
              })
            }
          />
          {errors.companyCountry && (
            <p className="text-red-500 text-xs">{errors.companyCountry}</p>
          )}
        </div>

        {/*  only allow changing delivery fee if admin*/}
        {isAdmin && (
          <div className="flex">
            <div className="relative mb-3 mt-4 flex-1 mr-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="delivery-fee"
              >
                Delivery Fee
              </label>
              <input
                id="delivery-fee"
                name="deliveryFee"
                placeholder="Delivery Fee ($BBD)"
                value={getValue("deliveryFee")}
                onChange={handleChange}
                className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
              />
              {errors.contactName && (
                <p className="text-red-500 text-xs">{errors.deliveryFee}</p>
              )}
            </div>
            <div className="relative mb-3 mt-4 flex-1">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="service-fee"
              >
                Service Fee
              </label>
              <input
                id="service-fee"
                name="serviceFee"
                placeholder="Service Fee (%)"
                value={getValue("serviceFee")}
                onChange={handleChange}
                className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full"
              />
              {errors.contactName && (
                <p className="text-red-500 text-xs">{errors.serviceFee}</p>
              )}
            </div>
          </div>
        )}

        <div className="w-full py-10 text-center flex flex-col content-center items-center justify-center bg-grey-lighter">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="company-address"
          >
            Company Logo
          </label>

          {!errors.companyLogo && (
            <>
              <img
                className="mx-auto"
                src={businessLogo ? URL.createObjectURL(businessLogo) : values.image}
                // src={values.image}
                style={{ width: "100px" }}
                alt="Preview of business logo"
              />
            </>
          )}

          {!values.image && values.companyLogo && (
            <p className="text-xs">{values.companyLogo.file.name}</p>
          )}

          {errors.companyLogo &&
            values.companyLogo &&
            errors.companyLogo.file &&
            errors.companyLogo.file.type && (
              <p className="text-red-500 text-xs">{errors.companyLogo.file.type}</p>
            )}
          <label className="w-64 flex flex-col items-center my-2 px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
            <svg
              className="w-8 h-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span className="mt-2 text-base leading-normal">Upload your logo</span>
            <input
              name="companyLogo"
              type="file"
              className="hidden"
              onChange={handleLogoChange}
            />
          </label>
          {errors.companyLogo &&
            errors.companyLogo.file &&
            errors.companyLogo.file.name && (
              <p className="text-red-500 text-xs">{errors.companyLogo.file.name}</p>
            )}
        </div>

        <div className="text-right mt-6">
          {currentUser?.admin && !props.editing && (
            <button
              className={`bg-gray-600 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
              type="submit"
              style={{ transition: "all .15s ease" }}
              disabled={isSubmitting}
            >
              Invite Merchant
            </button>
          )}

          {currentUser?.admin && props.editing && (
            <button
              className={`bg-gray-600 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
              type="submit"
              style={{ transition: "all .15s ease" }}
              disabled={isSubmitting}
            >
              Update
            </button>
          )}

          {!currentUser?.admin && !props.editing && (
            <button
              className={`bg-gray-600 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1`}
              type="submit"
              style={{ transition: "all .15s ease" }}
              disabled={isSubmitting}
            >
              Apply Now
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default withRouter(MerchantForm);
