import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import swal from "sweetalert";

import ProductForm from "./ProductForm";
import Table from "./DataTable";
import Modal from "./Modal";
import UploadProducts from "./UploadProducts";

import firebase from "../config/firebase";

const MerchantList = (props) => {
  const { merchantInfo } = props;
  const [showUploadModal, toggleUploadModal] = useState(false);
  const [showProductModal, toggleProductModal] = useState(false);
  const [products, setProducts] = useState(null);
  const [item, setItem] = useState(null);

  let guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const db = firebase.firestore();

    const { id } = merchantInfo;

    if (id) {
      const inventoryData = await db
        .collection("merchants")
        .doc(id)
        .collection("inventory")
        .get();

      let inventory = inventoryData.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProducts(inventory);
    }
  };

  const handleToggleModal = () => {
    // refreshList()
    setItem(null);
    // selectMerchant(null);
    toggleProductModal(!showProductModal);
    fetchData();
  };

  const uploadPhotoAsync = (uri, fileName) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (res, rej) => {
      // const response = await fetch(uri);
      // const file = await response.blob();

      let uploadRef = firebase.storage().ref(fileName);

      await uploadRef.put(uri);
      let downloadURL = await uploadRef.getDownloadURL();
      res(downloadURL);
    });
  };

  //Add new item to shopping list
  const handleAddItem = async (itemObj, img) => {
    const db = firebase.firestore();
    const objId = guid();

    if (img && Object.keys(img).length < 1) {
      const remoteUri = await uploadPhotoAsync(img, `${objId}`);
      itemObj.image = remoteUri;
    }

    try {
      await db
        .collection("merchants")
        .doc(merchantInfo.id)
        .collection("inventory")
        .add(itemObj);

      handleToggleModal();
    } catch (error) {
      console.error(error);
      // setFormError(error.message);
    }
  };

  const handleUpdateItem = async (itemObj, img) => {
    const db = firebase.firestore();
    // const newItemID = guid();

    if (itemObj) {
      const objId = guid();

      if (img && Object.keys(img).length < 1) {
        const remoteUri = await uploadPhotoAsync(img, `${objId}`);
        itemObj.image = remoteUri;
      }

      try {
        // await db.collection("customers").doc(currentUser.uid).collection('cart').add({ name: newItem, quantity: 1 });
        await db
          .collection("merchants")
          .doc(merchantInfo.id)
          .collection("inventory")
          .doc(itemObj.id)
          .update(itemObj);

        handleToggleModal();
      } catch (error) {
        // alert(error);
        console.error(error);
      }

      // setItems([...items, { id: newItemID, name: newItem, quantity: 1 }])
    }

    // setNewItem('')
  };

  //Delete item from shopping list
  const handleRemoveItem = (itemId) => {
    const db = firebase.firestore();

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        db.collection("merchants")
          .doc(merchantInfo.id)
          .collection("inventory")
          .doc(itemId)
          .delete();

        handleToggleModal();
      }
    });
  };

  const columns = [
    {
      Header: "Date",
      accessor: "dateAdded",
      disableFilters: true,
      Cell: (row, data) => format(new Date(row.row.original.dateAdded), "PPpp"),
    },
    {
      Header: "Title",
      accessor: "product_title",
    },
    {
      Header: "Price",
      id: "product_price",
      accessor: (d) =>
        d.product_price ? (
          <div className="text-left">{"$ " + parseFloat(d.product_price).toFixed(2)}</div>
        ) : (
          "n/a"
        ),
      disableFilters: true,
    },
    {
      Header: "Description",
      accessor: "product_description",
      disableFilters: true,
    },
    {
      Header: "Photo",
      accessor: "logo",
      disableFilters: true,
      Cell: (row, data) => (
        <div>
          {row.row.original.image ? (
            <img src={row.row.original.image} style={{ height: "100px" }} alt="Product" />
          ) : (
            "None"
          )}
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "action",
      disableFilters: true,
      Cell: ({ cell: { row } }) => (
        <div className="flex justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold uppercase text-xs py-2 px-2 rounded shadow"
            onClick={() => {
              setItem(row.original);

              toggleProductModal(!showProductModal);
            }}
          >
            View
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="container px-4 mb-10 mx-auto min-h-full h-auto  bg-white content-center items-center justify-center">
      <div className="flex justify-between items-center content-center pt-10">
        <h1 className="text-3xl uppercase font-bold text-blue-300">Manage your Store</h1>
        <div>
          <button
            className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            onClick={() => {
              toggleUploadModal(!showUploadModal);
            }}
          >
            Upload PriceList
          </button>
          <button
            className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            onClick={() => {
              toggleProductModal(!showProductModal);
            }}
          >
            Add Product
          </button>
        </div>
      </div>

      {products && (
        <Table
          // title="Order Dashboard"
          columns={columns}
          minRows={0}
          initialState={{
            sortBy: [{ id: "dateAdded", desc: true }],
          }}
          data={products}
        />
      )}
      {/* {item && ( */}
      <Modal
        small
        title={item ? "Edit Item" : "Add Item"}
        isOpen={showProductModal}
        toggleModal={() => toggleProductModal(false)}
        onConfirm={() => toggleProductModal(false)}
      >
        <ProductForm
          editing={item && Object.entries(item).length > 0 ? true : false}
          productInfo={item}
          onAdd={handleAddItem}
          onUpdate={handleUpdateItem}
          onRemove={handleRemoveItem}
          onCancel={handleToggleModal}
        />
      </Modal>

      <Modal
        medium
        title="Upload Pricelist"
        isOpen={showUploadModal}
        toggleModal={() => toggleUploadModal(false)}
        onConfirm={() => toggleUploadModal(false)}
      >
        {showUploadModal && (
          <UploadProducts
            merchantId={merchantInfo.id}
            onClose={() => toggleUploadModal(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default MerchantList;
