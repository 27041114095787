import "./styles.css"; // <- change './index.css' to './styles.css'
import "@fortawesome/fontawesome-free/css/all.min.css";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import L from "leaflet";
import "leaflet/dist/leaflet.css";

//Hack fix to display Marker icon
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;
// Sentry.init({
//   dsn: "https://242790b50f0841c6a2da9da011431301@o385383.ingest.sentry.io/5218018",
// });

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://242790b50f0841c6a2da9da011431301@o385383.ingest.sentry.io/5218018",
    environment: process.env.NODE_ENV,
  });
} else {
  console.log("sentry disabled");
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
