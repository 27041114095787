import React, { useEffect, useContext } from "react";
// import useYup from "@usereact/use-yup";

import { Link } from "react-router-dom";
import { GroceryItem } from "../components/GroceryItem";
// import DirectionsForm from "../components/DirectionsForm";
// import SocialLogins from "../components/SocialLogins.js";
// import Modal from "../components/Modal";
// import ProfileForm from "../components/ProfileForm";

// import * as yup from "yup";

// import firebase from "../config/firebase";
import { AuthContext } from "../context/Auth";
import { StoreContext } from "../context/store";
// import swal from "sweetalert2";
// import { format } from "date-fns";
// import axios from "axios";
// import app from "../config/firebase.js";

// const validationSchema = yup.object().shape({
//   email: yup.string().email().required(),
//   password: yup.string().min(6).required(),
// });

const Cart = (props) => {
  const auth = useContext(AuthContext);
  const { currentUser } = auth;

  const store = useContext(StoreContext);
  const { customerOrder, setCustomerOrder } = store;

  const [items, setItems] = React.useState(null);
  // const [profile, setProfile] = React.useState(null);
  // const [step, setStep] = React.useState(0);
  // const [isModalOpen, toggleModal] = React.useState(false);
  // const [modalType, setModalType] = React.useState("");

  // const [values, setValues] = useState({
  //   order_directions: "",
  //   order_dropofflocation: "",
  //   email: "",
  //   password: "",
  //   user_name: "",
  //   user_email: "",
  //   user_address: "",
  //   user_home_number: "",
  //   user_mobile_number: "",
  //   user_parish: "",
  // });

  // const { errors, validate } = useYup(values, validationSchema, {
  //   validateOnChange: false,
  // });

  // Handles input change event in the DirectionForm component
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   validate();
  //   setValues({
  //     ...values,
  //     [name]: value,
  //   });
  // };

  // Handler for the autofill of saved direction data in the form.
  // const handleDirectionsUpdate = (updatedInfo) => {
  //   setValues({
  //     ...values,
  //     order_directions: updatedInfo.order_directions,
  //     order_dropofflocation: updatedInfo.order_dropofflocation,
  //   });
  // };

  const fetchData = () => {
    setItems(customerOrder);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    setCustomerOrder(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleIncreaseQty = async (newItem) => {
    // return;

    let cartItems = items.cartItems.map((item) =>
      item.id === newItem.id ? { ...item, quantity: newItem.quantity } : item
    );

    let orderUpdate = {
      ...items,
      cartItems: cartItems,
    };
    setItems((items) => orderUpdate);
  };

  const handleDecreaseQty = async (newItem) => {
    let cartItems = items.cartItems.map((item) =>
      item.id === newItem.id ? { ...item, quantity: newItem.quantity } : item
    );

    let orderUpdate = {
      ...items,
      cartItems: cartItems,
    };

    setItems((items) => orderUpdate);
  };

  //Delete item from shopping list
  const handleRemoveItem = (itemId) => {
    let cartItems = items.cartItems;

    let newCartItems = cartItems.filter((item) => item.id !== itemId);

    setItems((items) => ({
      ...items,
      cartItems: newCartItems,
    }));
  };

  // const handleSaveProfile = async (data) => {
  //   const db = firebase.firestore();
  //   const ref = db.collection("customers").doc(currentUser.uid);
  //   try {
  //     delete data.user_pickuplocation;
  //     await ref.set({ ...data, user_email: currentUser.email });
  //     swal.fire({
  //       title: "Profile Saved",
  //       text: "Your profile has been saved and you may now continue.",
  //       icon: "success",
  //     });
  //     fetchData();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="w-full py-12 bg-white min-h-full flex">
      <div className="container mx-auto">
        {/* Editable Order Items List **************************/}

        <div className="mx-5">
          <div className=" border border-gray-400 rounded-lg">
            {/* <div className="flex items-center mx-3 border-solid border-b-2 border-gray-300 py-1">
              <p className="flex flex-wrap capitalize py-3 w-3/4">Product</p>
              <div className="flex-none justify-center w-1/4 content-center items-center">
                Quantity
                </div>
            </div> */}
            <h2 className="text-md font- p-3">Shopping Cart</h2>
            <ul className="w-full flex-grow border-t border-gray-400">
              {items && items.cartItems && items.cartItems.length > 0 ? (
                items.cartItems.map((item, index) => (
                  <GroceryItem
                    key={index}
                    list={items}
                    item={item}
                    handleIncrease={handleIncreaseQty}
                    handleDecrease={handleDecreaseQty}
                    onRemoveItem={handleRemoveItem}
                  />
                ))
              ) : (
                <div className="h-auto p-10 text-center">
                  <span>Your cart is currently empty</span>
                </div>
              )}
            </ul>
          </div>

          <div className="flex justify-between mt-6">
            <Link
              to="/shopping/lists"
              className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            >
              Continue Shopping
            </Link>
            {items && items.cartItems && items.cartItems.length > 0 && (
              <Link
                to="/shopping/options"
                className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              >
                Add Shipping Options
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
