import React, { useEffect, useState, useContext } from "react";

import firebase from "../config/firebase";

import ComingSoon from "../components/ComingSoon.js";
import LoadingSpinner from "../components/LoadingSpinner.js";
// import CategoryList from "../components/CategoryList.js";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

import { StoreContext } from "../context/store";

const SearchVendors = ({ history, match }) => {
  const [category, setCategory] = useState("");
  // const [displayName, setDisplayName] = useState("");
  // const [categoryList, setCategoryList] = useState([]);
  const [merchantList, setMerchantList] = React.useState(null);

  const store = useContext(StoreContext);
  const { countryFilter, searchFilter } = store;

  const fetchData = async () => {
    const db = firebase.firestore();

    const merchantData = await db
      .collection("merchants")
      .where("status", "==", "approved")
      .get();

    let merchantList = merchantData.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setMerchantList(merchantList);
  };

  // const fetchCategories = async () => {
  //   const db = firebase.firestore();

  //   const categoriesRef = await db
  //     .collection("categories")
  //     .orderBy("category_name")
  //     .get();

  //   const categories = categoriesRef.docs.map((doc) => ({
  //     ...doc.data(),
  //     id: doc.id,
  //   }));

  //   const filteredCategories = categories.filter(
  //     ({ category_name }) => category_name !== "other"
  //   );
  //   setCategoryList(filteredCategories);
  // };

  // const categoryMerchantFilter = (merchantCategory, category) => {
  //   if (!merchantCategory) {
  //     return false;
  //   }

  //   const filteredCategory = categoryList.filter(
  //     ({ category_name }) => category_name === category
  //   );
  //   const categoryID = filteredCategory[0]?.id;

  //   if (merchantCategory?.value === categoryID) {
  //     return true;
  //   }
  //   return false;
  // };

  // const capitalize = (value) => {
  //   const lowercase = value.toLowerCase();
  //   const capitalize = lowercase.charAt(0).toUpperCase() + lowercase.slice(1);

  //   return capitalize;
  // };

  useEffect(() => {
    // fetchCategories();
    fetchData();
    const categoryName = match.params.category;
    if (categoryName) {
      setCategory(categoryName);
    } else {
      setCategory("grocery");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   setDisplayName("Wi" + capitalize(category));
  // }, [category]);

  // if (merchantList) {
  const filteredVendors = merchantList
    ?.sort(function (a, b) {
      if (a.companyName < b.companyName) {
        return -1;
      }
      if (a.companyName > b.companyName) {
        return 1;
      }
      return 0;
    })
    .filter((item) => item.companyName.toLowerCase().includes(searchFilter))
    .filter((item) =>
      countryFilter !== ""
        ? item.companyCountry?.label?.toLowerCase() === countryFilter.toLowerCase()
        : item
    );

  // const handleSelectCategory = (category_name) => {
  //   setCategory(category_name);
  // };

  return (
    <div>
      <div className="h-screen w-full">
        <Navbar />
        <main>
          <div
            className="relative pb-32 flex flex-col content-center items-center justify-center"
            style={{
              minHeight: "75vh",
            }}
          >
            <div className="flex flex-col text-center w-full bg-primary-yellow">
              <h1 className="text-md p-3 text-left uppercase font-medium title-font text-gray-900">
                {searchFilter !== ""
                  ? `Searching for: "${searchFilter}"`
                  : "Showing all Vendors"}
              </h1>
            </div>
            <div className="container relative mx-3">
              <section className="text-gray-700 body-font">
                <div className="container px-5 py-6 mx-auto">
                  <div className="flex flex-wrap justify-center items-center -m-4">
                    {filteredVendors ? (
                      filteredVendors.length > 0 ? (
                        filteredVendors.map((item, index) => (
                          <div
                            key={index}
                            className="lg:w-1/4 md:w-1/2 p-4 w-full cursor-pointer"
                            onClick={() => {
                              history.push("/shopping/lists/" + category + "/" + item.id);
                            }}
                          >
                            <div className="block relative h-48 rounded overflow-hidden bg-gray-400 bg-opacity-25 flex justify-center items-center">
                              <img
                                src={item.image}
                                alt={item.companyName}
                                className="object-cover object-center block"
                                // style={{ height: 200, width: 200 }}
                              />
                            </div>
                            <div className="mt-4 text-center">
                              <h3 className="text-gray-600 text-sm tracking-widest title-font mb-1">
                                {item.companyName}
                              </h3>

                              <button className="text-sm border-2 rounded-lg border-yellow-400 p-2 m-3 w-2/3">
                                Start Shopping
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <ComingSoon
                          title="Coming Soon..."
                          subtitle="Sorry, we do not have any vendors for this category yet"
                        />
                      )
                    ) : (
                      <LoadingSpinner />
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default SearchVendors;
