import React, { useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import useYup from "@usereact/use-yup";
import * as yup from "yup";
import "flatpickr/dist/themes/material_blue.css";
import swal from "sweetalert2";
import Flatpickr from "react-flatpickr";

import Footer from "../components/Footer.js";
import NavBar from "../components/Navbar.js";

const validationSchema = yup.object().shape({
  delivery_contact_name: yup.string().required("Field is required"),
  delivery_contact_email: yup.string().email().required("Field is required"),
  delivery_contact_telephone: yup.string().min(10).required("Field is required"),
  delivery_pickup_time: yup.string().required("Field is required"),
  delivery_pickup_date: yup.string().required("Field is required"),
  delivery_pickup_location: yup.string().required("Field is required"),
  delivery_dropoff_time: yup.string().required("Field is required"),
  delivery_dropoff_date: yup.string().required("Field is required"),
  delivery_dropoff_location: yup.string().required("Field is required"),
  delivery_recipient_name: yup.string().required("Field is required"),
  delivery_recipient_number: yup.string().min(10).required("Field is required"),
});

const DeliveryPage = () => {
  const initialState = {
    delivery_contact_name: "",
    delivery_contact_email: "",
    delivery_contact_telephone: "",
    delivery_order: "",
    delivery_pickup_time: "",
    delivery_pickup_date: "",
    delivery_pickup_location: "",
    delivery_dropoff_time: "",
    delivery_dropoff_date: "",
    delivery_dropoff_location: "",
    delivery_recipient_name: "",
    delivery_recipient_number: "",
    delivery_instructions: "",
  };

  const [values, setValues] = useState(initialState);
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setValues({
      ...values,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormError("");
    setValues(initialState);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      delivery_contact_name,
      delivery_contact_email,
      delivery_contact_telephone,
      delivery_order,
      delivery_pickup_date,
      delivery_pickup_location,
      delivery_pickup_time,
      delivery_dropoff_location,
      delivery_dropoff_date,
      delivery_dropoff_time,
      delivery_recipient_name,
      delivery_recipient_number,
      delivery_instructions,
    } = values;
    validate();
    console.log(values);
    // console.log(values.delivery_pickup_date, values.delivery_pickup_time)
    // console.log(values.delivery_dropoff_date, values.delivery_dropoff_time)
    // return;

    const contact_subject = "New delivery order from " + delivery_contact_name;
    const contact_message =
      "Order details<br/>" +
      "Contact Name: " +
      delivery_contact_name +
      "Contact Email: " +
      delivery_contact_email +
      "Contact Number: " +
      delivery_contact_telephone +
      "Delivery Items: " +
      delivery_order +
      "Pickup Location: " +
      delivery_pickup_location +
      "Pickup Date & Time: " +
      delivery_pickup_date +
      " " +
      delivery_pickup_time +
      "Dropoff Location: " +
      delivery_dropoff_location +
      "Dropoff Date & Time: " +
      delivery_dropoff_date +
      " " +
      delivery_dropoff_time +
      "Recipient Name: " +
      delivery_recipient_name +
      "Recipient Number: " +
      delivery_recipient_number +
      "Instructions: " +
      delivery_instructions;

    if (Object.keys(errors).length < 1) {
      swal.showLoading();
      try {
        //Send email via firebase function
        await axios.post(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/sendmail", {
          email: delivery_contact_email,
          title: contact_subject,
          message: contact_message,
        });
        swal.hideLoading();
        swal.fire(
          "Good job!",
          "Thanks for your order! We will get back to you as soon as possible!",
          "success"
        );
        setFormSuccess(
          "Thanks for your order! We will get back to you as soon as possible."
        );
        resetForm();
      } catch (error) {
        // alert(error);
        // console.error(error, email, password);
        swal.fire("Error", "An error has occurred, please try again", "error");
        setFormError("An error has occurred, please try again");
      }
    } else {
      swal.fire("Error", "Please fill in all required fields", "error");
      setFormError("Please fill in all required fields");
    }
  };

  const displayFieldErrors = (field_name) => {
    return (
      values[field_name]?.length > 0 &&
      errors[field_name] && <p className="text-red-500 text-xs">{errors[field_name]}</p>
    );
  };

  return (
    <div>
      <NavBar />
      <main>
        <div
          className="mt-3 mx-auto flex text-center justify-center items-center"
          style={{
            minHeight: 200,
          }}
        >
          {/* <div className="px-3">
            <h1 className="text-gray-800 font-normal text-5xl md:text-6xl">
              Anything from Anywhere
            </h1>
            <div className="text-gray-800 py-3 text-xl text-center">
              With WiFetch you can now order anything from anywhere or schedule pickup for
              an order
            </div>
          </div> */}
          <div className="rounded-t mb-0 px-6 py-3">
            <div className="text-center mb-3">
              <h6 className="text-gray-600 text-2xl font-bold">Anything from Anywhere</h6>
              <div className="text-md mt-3 text-gray-600 mb-3">
                With WiFetch you can now order anything from anywhere or schedule pickup
                for an order
              </div>
              <div className="text-md mt-3 text-gray-600 mb-3">
                Already Ordered and need something picked up?
              </div>
            </div>
          </div>
        </div>
        <div
          className="bg-white flex items-center flex-1 w-full pb-32"
          style={{ minHeight: "500px" }}
        >
          <div className="w-full md:w-9/12 mx-auto">
            <div className="w-full p-3 md:px-10 text-center md:text-left">
              {formSuccess && (
                <div
                  className="w-100 m-3 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Success!</strong>
                  <span className="block sm:inline"> {formSuccess}</span>
                </div>
              )}

              {formError && (
                <div
                  className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Error!</strong>
                  <span className="block sm:inline"> {formError}</span>
                </div>
              )}
              <div className="text-left">
                <form
                  className="m-1 p-5 md:p-10 bg-white rounded shadow-xl"
                  onSubmit={handleSubmit}
                >
                  <div className="my-3 text-gray-800 text-xl text-center">
                    Contact Information
                  </div>
                  <div className="flex flex-col md:flex-row">
                    <div className="mt-2 md:pr-2 md:w-1/2">
                      <label className="block text-sm text-gray-600">Your Name</label>
                      <input
                        value={values.delivery_contact_name}
                        onChange={handleChange}
                        className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                        id="delivery_contact_name"
                        name="delivery_contact_name"
                        type="text"
                        required=""
                        placeholder="Name of the sender?"
                      />
                      {displayFieldErrors("delivery_contact_name")}
                    </div>
                    <div className="mt-2 md:w-1/2">
                      <label className="block text-sm text-gray-600">Your Email</label>
                      <input
                        value={values.delivery_contact_email}
                        onChange={handleChange}
                        className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                        id="delivery_contact_email"
                        name="delivery_contact_email"
                        type="text"
                        required=""
                        placeholder="What is your email?"
                      />
                      {/* {values.delivery_contact_email?.length > 0 && errors.delivery_contact_email && (
                        <p className="text-red-500 text-xs">{errors.delivery_contact_email}</p>
                      )} */}
                      {displayFieldErrors("delivery_contact_email")}
                    </div>
                  </div>

                  <div className="mt-2">
                    <label className="block text-sm text-gray-600">Contact Number</label>
                    <input
                      value={values.delivery_contact_telephone}
                      onChange={handleChange}
                      className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                      id="delivery_contact_telephone"
                      name="delivery_contact_telephone"
                      type="text"
                      required=""
                      placeholder="What is your contact number?"
                    />
                    {displayFieldErrors("delivery_contact_telephone")}
                  </div>
                  <div className="my-3 text-gray-800 text-xl text-center">
                    Delivery Information
                  </div>
                  <div className="mt-2">
                    <label className=" block text-sm text-gray-600">
                      What do you need delivered?
                    </label>
                    <textarea
                      value={values.delivery_order}
                      onChange={handleChange}
                      className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                      rows="4"
                      id="delivery_order"
                      name="delivery_order"
                      type="text"
                      required=""
                      placeholder="Please indicate each item on a new line including quantity and brand"
                    ></textarea>
                    {displayFieldErrors("delivery_order")}
                  </div>
                  <div className="mt-2 md:inline-block md:pr-2 md:w-1/2">
                    <label className="block text-sm text-gray-600">Pickup location</label>
                    <input
                      value={values.delivery_pickup_location}
                      onChange={handleChange}
                      className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                      id="delivery_pickup_location"
                      name="delivery_pickup_location"
                      type="text"
                      required=""
                      placeholder="Where to collect?"
                    />
                    {displayFieldErrors("delivery_pickup_location")}
                  </div>
                  <div className="mt-2 md:inline-block md:pr-2 md:w-1/2">
                    <label className="block text-sm text-gray-600">
                      Dropoff Location
                    </label>
                    <input
                      value={values.delivery_dropoff_location}
                      onChange={handleChange}
                      className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                      id="delivery_dropoff_location"
                      name="delivery_dropoff_location"
                      type="text"
                      required=""
                      placeholder="Where to dropoff?"
                    />
                    {displayFieldErrors("delivery_dropoff_location")}
                  </div>
                  {/* <div className="mt-2">
                    <label className=" block text-sm text-gray-600" >What do you need delivered?</label>
                    <textarea onChange={handleChange} className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline" rows="4" id="delivery_information" name="delivery_information" type="text" required="" placeholder="What do you need delivered? Include details if possible"></textarea>
                  </div> */}
                  <div className="mt-2 md:inline-block md:pr-2 md:w-1/2">
                    <label className="text-sm block text-gray-600">Pickup Date?</label>
                    <div className="relative">
                      <Flatpickr
                        name="delivery_pickup_date"
                        placeholder="YYYY-MM-DD"
                        className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={values.delivery_pickup_date}
                        options={{ enableTime: false }}
                        // onClose={data => console.log(data)}
                        // onValueUpdate={data => console.log(data)}
                        onValueUpdate={(date) => {
                          validate();
                          console.log(date);
                          setValues((prevStyle) => ({
                            ...prevStyle,
                            delivery_pickup_date:
                              date.length > 0 ? format(date[0], "yyyy-MM-dd") : "",
                          }));
                        }}
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {displayFieldErrors("delivery_pickup_date")}
                  </div>
                  <div className="mt-2 md:inline-block md:w-1/2">
                    <label className="block text-sm text-gray-600">Pickup Time?</label>
                    <div className="relative">
                      <Flatpickr
                        name="delivery_pickup_time"
                        placeholder="hh:mm"
                        className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={values.delivery_pickup_time}
                        options={{ enableTime: true, noCalendar: true, dateFomat: "H:i" }}
                        // onClose={data => console.log(data)}
                        // onValueUpdate={data => console.log(data)}
                        onValueUpdate={(date) => {
                          validate();
                          setValues((prevStyle) => ({
                            ...prevStyle,
                            delivery_pickup_time:
                              date.length > 0 ? format(date[0], "HH:mm") : "",
                          }));
                        }}
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {displayFieldErrors("delivery_pickup_time")}
                  </div>
                  <div className="mt-2 md:inline-block md:pr-2 md:w-1/2">
                    <label className="text-sm block text-gray-600">Dropoff Date?</label>
                    <div className="relative">
                      <Flatpickr
                        name="delivery_dropoff_date"
                        placeholder="YYYY-MM-DD"
                        className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={values.delivery_dropoff_date}
                        options={{ enableTime: false }}
                        // onClose={data => console.log(data)}
                        // onValueUpdate={data => console.log(data)}
                        onValueUpdate={(date) => {
                          validate();
                          setValues((prevStyle) => ({
                            ...prevStyle,
                            delivery_dropoff_date:
                              date.length > 0 ? format(date[0], "yyyy-MM-dd") : "",
                          }));
                        }}
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {displayFieldErrors("delivery_dropoff_date")}
                  </div>
                  <div className="mt-2 md:inline-block md:w-1/2">
                    <label className="block text-sm text-gray-600">Dropoff Time?</label>
                    <div className="relative">
                      <Flatpickr
                        name="delivery_dropoff_time"
                        placeholder="hh:mm"
                        className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={values.delivery_dropoff_time}
                        options={{ enableTime: true, noCalendar: true, dateFomat: "H:i" }}
                        // onClose={data => console.log(data)}
                        // onValueUpdate={data => console.log(data)}
                        onValueUpdate={(date) => {
                          validate();
                          setValues((prevStyle) => ({
                            ...prevStyle,
                            delivery_dropoff_time:
                              date.length > 0 ? format(date[0], "HH:mm") : "",
                          }));
                        }}
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {displayFieldErrors("delivery_dropoff_time")}
                  </div>
                  <div className="flex flex-col md:flex-row">
                    <div className="mt-2 md:pr-2 md:w-1/2">
                      <label className="block text-sm text-gray-600">
                        Recipient Name
                      </label>
                      <input
                        value={values.delivery_recipient_name}
                        onChange={handleChange}
                        className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                        id="delivery_recipient_name"
                        name="delivery_recipient_name"
                        type="text"
                        required=""
                        placeholder="Recipient Name"
                      />
                      {displayFieldErrors("delivery_recipient_name")}
                    </div>
                    <div className="mt-2 md:w-1/2">
                      <label className="block text-sm text-gray-600">
                        Recipient Number
                      </label>
                      <input
                        value={values.delivery_recipient_number}
                        onChange={handleChange}
                        className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                        id="delivery_recipien_number"
                        name="delivery_recipient_number"
                        type="text"
                        required=""
                        placeholder="Recipient Number"
                      />
                      {displayFieldErrors("delivery_recipient_number")}
                    </div>
                  </div>

                  <div className="mt-2">
                    <label className=" block text-sm text-gray-600">
                      Directions (optional)
                    </label>
                    <textarea
                      value={values.delivery_instructions}
                      onChange={handleChange}
                      className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                      rows="4"
                      id="delivery_instructions"
                      name="delivery_instructions"
                      type="text"
                      required=""
                      placeholder="Please provide any additional directions"
                    ></textarea>
                    {displayFieldErrors("delivery_instructions")}
                  </div>
                  <div className="mt-4 text-center">
                    <p className="text-sm py-3">
                      Pickups are $20.00 BBD + 5% service fee for first pickup and $15.00
                      BBD plus service charge thereafter. If you experience any problems,
                      email us at{" "}
                      <a
                        href="mailto:orderanything@wifetch.com"
                        className="text-blue-500 underlineß"
                      >
                        orderanything@wifetch.com
                      </a>
                    </p>

                    <p className="text-sm py-3">
                      Please note that grocery store runs are $45.00 BBD.
                    </p>
                    <button
                      className="px-4 py-2 text-white font-light tracking-wider bg-blue-500 hover:bg-blue-700 hover:shadow-lg rounded"
                      type="submit"
                    >
                      Submit My Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DeliveryPage;
