/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import axios from "axios";
import swal from "sweetalert";
import { format, parse } from "date-fns";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import Modal from "../components/Modal";
import Table from "../components/DataTable";
import StripePayment from "../components/StripePayment";

import firebase from "../config/firebase";
import { AuthContext } from "../context/Auth";

//import * as yup from 'yup';
//import useYup from '@usereact/use-yup'

/*
const validationSchema = yup.object().shape({
  order_cost: yup.number().min(0).required(),
  order_status: yup.string().required()
});
*/
// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <div className="inline-block relative">
      <select
        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
        value={filterValue}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
}

const Orders = () => {
  const auth = useContext(AuthContext);
  const { currentUser } = auth;

  //const [cartItems, setCartItems] = React.useState([]);
  const [order, selectOrder] = React.useState(null);
  const [orderList, setOrderList] = React.useState(null);
  //const [data, setData] = useState([])
  const [isModalOpen, toggleModal] = React.useState(false);
  const [showReturnPolicy, toggleReturnPolicy] = React.useState(false);
  const [modalContent, setModalContent] = React.useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async () => {
    setOrderList([]);
    const db = firebase.firestore();
    //const ref = db.collection("customers")

    let orderData = await db
      .collection("orders")
      .where("customerId", "==", currentUser.uid)
      .get();
    orderData = orderData.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    await orderData.map(async (currentOrder) => {
      const data = await db
        .collection("orders")
        .doc(currentOrder.id)
        .collection("items")
        .get();
      currentOrder.cartItems = data.docs.map((doc) => ({
        ...doc.data(),
        customerId: currentOrder.customerId,
        id: doc.id,
      }));
    });

    setOrderList([...orderData]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggleModal = () => {
    // fetchData()
    toggleModal(!isModalOpen);
  };

  const handleCancelOrder = async () => {
    // toggleCancelModal(!isCancelModalOpen)

    const db = firebase.firestore();
    //Update order information

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (value) => {
      if (value) {
        try {
          await db
            .collection("orders")
            .doc(order.id)
            .update({
              dateUpdated: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
              status: "cancelled",
            });

          toggleModal(!isModalOpen);

          //Refresh data
          fetchData();
        } catch (error) {
          // alert(error);
          console.error(error);
          // setFormError('Sorry but we do not recognize that email/password combination. Please try again')
        }
      }
    });

    // toggleCancelModal(!isCancelModalOpen);
  };

  const handlePayOrder = async () => {
    const db = firebase.firestore();
    //Update order information

    try {
      //Update order as paid
      await db
        .collection("orders")
        .doc(order.id)
        .update({
          dateUpdated: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          status: "paid",
        });

      toggleModal(!isModalOpen);

      swal("Success!", "Thanks, your payment has been received!", "success").then(
        async (value) => {
          //Send email via firebase function
          await axios.post(
            process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/sendnotification",
            {
              email: currentUser.email,
              status: "paid",
              cost: order.cost,
              orderno: order.id,
              title: "WiFetch: Payment Received",
            }
          );

          //Refresh data
          fetchData();
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      Header: "Date",
      accessor: "dateAdded",
      disableFilters: true,
      Cell: (row, data) =>
        format(
          parse(row.row.original?.dateAdded, "yyyy-MM-dd HH:mm:ss", new Date()),
          "PPpp"
        ),
      // width: 500,
      filterMethod: (filter, row) =>
        row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
    },
    {
      Header: "Order #",
      accessor: "id",
    },
    {
      Header: "Type",
      accessor: "type",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Total Cost",
      accessor: "cost",
      Cell: (row, data) => {
        return row.row.original.cost ? row.row.original.cost : "n/a";
      },
      filterMethod: (filter, row) =>
        row[filter.id].startsWith(filter.value) && row[filter.id].endsWith(filter.value),
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell: { row } }) => (
        <div className="flex justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-semibold uppercase text-xs py-2 px-2 rounded shadow"
            onClick={() => {
              selectOrder(row.original);
              // setFormValues({ order_cost: row.original.cost, order_status: row.original.status })
              setModalContent({
                modalTitle: "Order Details",
                customerId: row.original.customerId,
                confirmText: "Ok",
              });
              toggleModal(!isModalOpen);
            }}
          >
            View
          </button>
        </div>
      ),
    },
  ];
  const initialState = {
    sortBy: [{ id: "dateAdded", desc: true }],
  };
  return (
    <div className="container px-4 mx-auto min-h-full h-auto  bg-white content-center items-center justify-center">
      {/* <div className="w-full px-1 pt-4 my-10 bg-white"> */}

      {orderList ? (
        <>
          <div className="font-sans flex justify-between text-md items-center border-b border-gray-200 mb-6">
            <Link className="text-gray-600  py-4" to="/shopping/lists">
              <i className="fas fa-angle-double-left"></i> Back to Shopping
            </Link>
            <button
              className="text-blue-500 py-4"
              onClick={() => toggleReturnPolicy(true)}
            >
              View Our Return Policy
            </button>
          </div>

          <Table
            // filterable
            // defaultFilterMethod={(filter, row) =>
            //     String(row[filter.id]) === filter.value}
            // className="bg-white"
            // sortBy=[{id: 'age', desc: true }]
            title="Your Orders"
            columns={columns}
            minRows={0}
            data={orderList}
            initialState={initialState}
          />
        </>
      ) : (
        <div className="flex flex-col content-center justify-center items-center h-full">
          <h1 className="text-5xl uppercase font-bold text-blue-300 pt-24">
            Loading ...
          </h1>
          <Link
            className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            type="button"
            to="/shopping/lists"
          >
            <i className="fas fa-angle-double-left"></i> Back to Shopping
          </Link>
        </div>
      )}
      {/* </div> */}

      <Modal
        small
        isOpen={isModalOpen}
        toggleModal={handleToggleModal}
        content={modalContent}
        onConfirm={handleToggleModal}
      >
        {order &&
          (order.status === "confirmed" ||
            order.status === "approved" ||
            order.status === "paid") && (
            <div className="flex flex-col justify-between items-center text-center px-4 pb-3">
              <p className="text-2xl font-bold py-3">Thanks for your order!</p>
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                List of Items
              </label>
              {order.cartItems.length ? (
                <ul
                  className="w-full p-3 mb-3 bg-gray-200 text-center overflow-y-scroll"
                  style={{ maxHeight: "200px" }}
                >
                  {order.cartItems.map((elem, key) => (
                    <li key={key} className="text-black">
                      {elem.name} x {elem.quantity}
                    </li>
                  ))}
                  {/* {order && order.map((item, index) => { return (item.cartItems.filter(elem => elem.customerId == modalContent.customerId).map((elem, key) => <p className="text-black">{elem.name}</p>)) })} */}
                </ul>
              ) : (
                <p className="text-lg py-3">No items were added to this order</p>
              )}
              <p className="text-md py-6">
                Kindly note that if you submitted your order before 10:00pm, it will be
                dispatched the following day. If submitted after 10:00pm, your order will
                be delivered the day after. Thank you!
              </p>

              {/* <p className="text-md py-6">If you wish to cancel your order you can click the button below</p> */}
              <button
                type="button"
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mt-5 rounded"
                onClick={handleCancelOrder}
              >
                Cancel Order
              </button>
            </div>
          )}

        {order && order.status === "pending" && (
          <div className="flex flex-col justify-between items-center text-center px-4 pb-3">
            <p className="text-2xl font-bold pt-3">Pending Order</p>
            <p className="text-md pb-3">
              Order # <br />
              <span className="text-xs">{order.id}</span>
            </p>
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              List of Items
            </label>
            {order.cartItems.length ? (
              <ul
                className="w-full p-3 mb-3 bg-gray-200 text-center overflow-y-scroll"
                style={{ maxHeight: "200px" }}
              >
                {order.cartItems.map((elem, key) => (
                  <li key={key} className="text-black">
                    {elem.name} x {elem.quantity}
                  </li>
                ))}
                {/* {order && order.map((item, index) => { return (item.cartItems.filter(elem => elem.customerId == modalContent.customerId).map((elem, key) => <p className="text-black">{elem.name}</p>)) })} */}
              </ul>
            ) : (
              <p className="text-lg py-3">No items were added to this order</p>
            )}
            <p className="text-md py-6">
              Please update and confirm your order for us to get started
            </p>

            {/* <p className="text-md py-6">If you wish to cancel your order you can click the button below</p> */}
            <Link
              to="/shopping/lists/grocery"
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 rounded"
            >
              Update Order
            </Link>
          </div>
        )}

        {order && order.status === "packaged" && (
          <div className="flex flex-col justify-between items-center text-center px-4 pb-3">
            <p className="text-2xl text-green-500 font-bold">
              Your order is almost ready!
            </p>
            <img
              style={{ height: "100px" }}
              src={require("../assets/img/pending_payment.png")}
              alt="Please pay using your credit card!"
            />
            <p className="text-md py-6">
              Please pay for your items. You can pay for your items using the form below
            </p>
            <StripePayment
              orderInfo={order}
              onSuccess={handlePayOrder}
              onError={() => {
                swal(
                  "An error occurred",
                  "We were unable to process your card payment. Please try again",
                  "error"
                );
              }}
            />
          </div>
        )}

        {order && order.status === "cancelled" && (
          <div className="flex flex-col justify-between items-center text-center px-4 pb-3">
            <p className="text-2xl text-red-500 font-bold py-3">
              This order was cancelled
            </p>
            {/* <p className="text-md py-6">Your order has been received by the WiFetch Team</p>

          <img style={{ height: '200px' }} src={require('../assets/img/pending_order.png')} alt="We are looking for your items!" /> */}
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              List of Items
            </label>
            {order.cartItems.length ? (
              <ul
                className="w-full p-3 mb-3 bg-gray-200 text-center overflow-y-scroll"
                style={{ maxHeight: "200px" }}
              >
                {order.cartItems.map((elem, key) => (
                  <li className="text-black">
                    {elem.name} x {elem.quantity}
                  </li>
                ))}
                {/* {order && order.map((item, index) => { return (item.cartItems.filter(elem => elem.customerId == modalContent.customerId).map((elem, key) => <p className="text-black">{elem.name}</p>)) })} */}
              </ul>
            ) : (
              <p className="text-lg py-3">No items were added to this order</p>
            )}

            <button
              type="button"
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 mt-5 rounded"
              onClick={handleToggleModal}
            >
              Close
            </button>
          </div>
        )}
      </Modal>

      <Modal
        small
        isOpen={showReturnPolicy}
        toggleModal={() => toggleReturnPolicy(false)}
        title="Wi Fetch Return Policy"
      >
        <div className="flex flex-col justify-center items-center h-64">
          Once goods have been dispatched we have a no return policy due to Covid-19 and
          our health and safety requirements. Once goods have been shopped and paid for by
          Wi Fetch we have a no cancellation policy. Should an item received be materially
          different from that requested, Wi Fetch will refund the cost of the item.
          <button
            onClick={() => toggleReturnPolicy(false)}
            className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
          >
            Ok
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Orders;
