import React, { useEffect } from "react";
import axios from "axios";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import swal from "sweetalert2";

const MerchantApproveInvite = ({ history, match }) => {
  // const { currentUser } = useContext(AuthContext);

  const fetchData = async () => {
    //const ref = db.collection("customers")
    const userToken = match.params.token;

    try {
      axios
        .post(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/setMerchantClaims", {
          token: userToken,
        })
        .then(() => {
          swal
            .fire(
              "Invitation Accepted",
              "Thank you for accepting our invite to be a Wifetch merchant! You may continue to login now.",
              "success"
            )
            .then(() => {
              history.push("/");
            });
        })
        .catch(() => {
          swal.fire(
            "Sorry",
            "Something went wrong with the invitation, please contact support!",
            "error"
          );
        });
    } catch (error) {
      console.error(error);
      swal.fire(
        "Sorry",
        "Something went wrong with the invitation, please contact support!",
        "error"
      );
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navbar transparent />
      <main className="bg-yellow-400">
        <section className="absolute w-full h-full">
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full md:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                  <div className="rounded-t mb-0 px-6 py-3">
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-xl font-bold">
                        Merchant Invite Approval
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default MerchantApproveInvite;
