import "./App.css";

import React from "react";
// import ReactGA from "react-ga";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import FullStory from "react-fullstory";

import withTracker from "./hoc/withTracker";

import PageWrapper from "./components/PageWrapper";
import { AuthProvider } from "./context/Auth";
import { StoreProvider } from "./context/store";
import PrivateRoute from "./PrivateRoute";
import About from "./views/About";
import Cart from "./views/Cart";
import DeliveryPage from "./views/Delivery";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfUse from "./views/TermsOfUse";
import Contact from "./views/Contact";
import Dashboard from "./views/Dashboard";
import Drivers from "./views/Drivers";
import Merchants from "./views/Merchants";
import MerchantApproveInvite from "./views/MerchantApproveInvite";
import MerchantRegistration from "./views/MerchantRegistration";
import MerchantDashboard from "./views/MerchantDashboard";
import ForgotPassword from "./views/ForgotPassword";
import Landing from "./views/Landing";
import Login from "./views/Login";
import Membership from "./views/Membership";
import Orders from "./views/Orders";
import Profile from "./views/Profile";
import Shopping from "./views/Shopping";
import VendorList from "./views/VendorList";
import SearchPage from "./views/SearchVendors";
import OrderSubmission from "./views/OrderSubmission";
import Checkout from "./views/Checkout";
import SignUp from "./views/SignUp";

import Page404 from "./components/Page404";

const ORG_ID = "TSE89";

const App = () => {
  return (
    <AuthProvider>
      <StoreProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={withTracker(Landing)} />
            <Route exact path="/home" component={withTracker(Landing)} />
            <Route path="/about" component={withTracker(About)} />
            <Route path="/privacypolicy" component={withTracker(PrivacyPolicy)} />
            <Route path="/termsofuse" component={withTracker(TermsOfUse)} />
            <Route path="/contactus" component={withTracker(Contact)} />
            <Route path="/deliveries" component={withTracker(DeliveryPage)} />
            <Route exact path="/membership" component={withTracker(Membership)} />
            <Route exact path="/search" component={withTracker(SearchPage)} />

            <Route exact path="/vendors/:category?" component={withTracker(VendorList)} />

            <Route exact path="/login" component={withTracker(Login)} />
            <Route exact path="/forgotpassword" component={withTracker(ForgotPassword)} />
            <Route exact path="/signup" component={withTracker(SignUp)} />
            <Route exact path="/driver_apply" component={withTracker(Drivers)} />
            <Route exact path="/merchant_apply" component={withTracker(Merchants)} />
            <Route
              exact
              path="/merchants/register/:token"
              component={MerchantRegistration}
            />
            <Route
              exact
              path="/merchants/invitation/:token"
              component={MerchantApproveInvite}
            />
            <PrivateRoute
              path="/users"
              component={() => {
                return (
                  <PageWrapper>
                    <Switch>
                      <Route
                        exact
                        path="/users/shopping/:category/:token"
                        component={withTracker(Shopping)}
                      />
                      <Route
                        exact
                        path="/users/profile"
                        component={withTracker(Profile)}
                      />
                      <Route exact path="/users/orders" component={withTracker(Orders)} />
                      <Route
                        exact
                        path="/users/dashboard"
                        component={withTracker(Dashboard)}
                      />
                      <Redirect from="/users/*" to="/vendors" />
                    </Switch>
                  </PageWrapper>
                );
              }}
            />

            <Route
              path="/shopping"
              component={() => {
                return (
                  <PageWrapper>
                    <Switch>
                      <Route
                        exact
                        path="/shopping/lists/:category?/:token?"
                        component={withTracker(Shopping)}
                      />
                      <Route exact path="/shopping/cart" component={withTracker(Cart)} />
                      <Route
                        exact
                        path="/shopping/options"
                        component={withTracker(OrderSubmission)}
                      />
                      <Route
                        exact
                        path="/shopping/checkout/:id?"
                        component={withTracker(Checkout)}
                      />
                    </Switch>
                  </PageWrapper>
                );
              }}
            />

            <PrivateRoute
              path="/users"
              component={() => {
                return (
                  <PageWrapper>
                    <Switch>
                      <Route
                        exact
                        path="/users/profile"
                        component={withTracker(Profile)}
                      />
                      <Route exact path="/users/orders" component={withTracker(Orders)} />
                      <Route
                        exact
                        path="/users/dashboard"
                        component={withTracker(Dashboard)}
                      />
                      <Redirect from="/users/*" to="/users/profile" />
                    </Switch>
                  </PageWrapper>
                );
              }}
            />

            <PrivateRoute
              path="/merchants"
              component={() => {
                return (
                  <PageWrapper>
                    <Switch>
                      <Route
                        exact
                        path="/merchants/dashboard"
                        component={withTracker(MerchantDashboard)}
                      />
                      <Redirect from="/merchants/*" to="/merchants/dashboard" />
                    </Switch>
                  </PageWrapper>
                );
              }}
            />

            <Route component={Page404} />
          </Switch>
        </Router>
        {process.env.NODE_ENV === "production" && <FullStory org={ORG_ID} />}
      </StoreProvider>
    </AuthProvider>
  );
};

export default App;
