import useYup from "@usereact/use-yup";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import app from "../config/firebase.js";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  // password: yup.string().min(6).required()
});

const ForgotPassword = ({ history }) => {
  const [values, setValues] = useState({
    email: "",
    // password: '',
  });
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");

  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      await app
        .auth()
        .sendPasswordResetEmail(values.email)
        .then(function () {
          setValues({
            email: "",
          });
        })
        .catch(function (error) {
          // console.error("An error occurred trying to send the reset password");
          // setFormError(
          //   "Sorry an error occurred trying to send the reset password link. Please try again"
          // );
        });
      setFormSuccess(
        "Thanks for your request. Please check your email inbox for instructions to reset your password"
      );
    } catch (error) {
      console.error("An error occurred");
      setFormError(
        "Sorry an error occurred trying to send the reset password link. Please try again"
      );
    }
  };

  return (
    <>
      <Navbar transparent />
      <main>
        <section className="absolute w-full h-full">
          <div
            className="absolute top-0 w-full h-full bg-yellow-400"
            style={
              {
                // backgroundImage:
                //     // "url(" + require("assets/img/register_bg_2.png") + ")",
                // backgroundSize: "100%",
                // backgroundRepeat: "no-repeat"
              }
            }
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full md:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                  {formSuccess && (
                    <div
                      className="w-100 m-3 bg-red-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <strong className="font-bold">Success!</strong>
                      <span className="block sm:inline"> {formSuccess}</span>
                    </div>
                  )}

                  {formError && (
                    <div
                      className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <strong className="font-bold">Error!</strong>
                      <span className="block sm:inline"> {formError}</span>
                    </div>
                  )}

                  <div className="rounded-t mb-0 px-6 py-3">
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-xl font-bold">Reset Password</h6>
                    </div>
                  </div>

                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    {/* <div className="text-gray-500 text-center mb-3 font-bold">
                                            <small>Or sign in with credentials</small>
                                        </div> */}
                    <form onSubmit={handleResetPassword}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                          placeholder="Enter your email address"
                          value={values.email}
                          onChange={(e) => handleChange(e)}
                          style={{ transition: "all .15s ease" }}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs italic">{errors.email}</p>
                        )}
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                        >
                          Reset Password
                        </button>
                        <Link to="/login" className="text-blue-500 text-md mt-6">
                          Back to Login
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default ForgotPassword;
