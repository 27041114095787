import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../components/LoadingSpinner";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/Auth";
import MmoneyPayment from "../components/MmoneyPayment";

import swal from "sweetalert2";

const Checkout = (props) => {
  const auth = useContext(AuthContext);
  const { currentUser } = auth;
  const [values] = useState({
    pt_gateway_account: "wifetchinc1",
    pt_transaction_amount: "",
    order_cost: 0,
    order_no: "",
  });
  const [orderCost, setOrderCost] = React.useState(0);
  const [orderNumber, setOrderNumber] = React.useState(false);
  const [iframeLoading, setiframeLoading] = React.useState(false);
  // const [showPayNow, setShowPayNow] = React.useState(true);
  const [showRBCPayment, setShowRBCPayment] = React.useState(false);

  // const [items, setItems] = React.useState(null);

  const fetchData = async () => {
    const orderNo = props.match?.params?.id;
    //Send email via firebase function

    try {
      const res = await axios.get(
        process.env.REACT_APP_FIREBASE_FUNCTION_URL + `/orders/${orderNo}/cost`
      );

      if (res.data) {
        setOrderCost(res.data.cost);
        setOrderNumber(orderNo);
      }
    } catch (error) {
      console.error(error);
      // setShowPayNow(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handlePayWithCard = async (event) => {
    setiframeLoading(true);
    document.getElementById("rbcForm").submit();
    document.getElementById("rbcIframe").height = "1200px";
    setShowRBCPayment(true);
  };

  const handleMmoneySuccess = async (data) => {
    // swal.fire("Success!", "Thanks, your payment has been received!", "success");
    const { ulid } = data;

    try {
      await axios.post(process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/payment/success", {
        order_id: orderNumber,
        amount: orderCost,
        invoice_id: ulid,
      });

      swal.fire({
        title: "Success",
        text: "Thanks, your payment has been received. Please close the payment window.",
        icon: "success",
      });
    } catch (e) {
      console.error(e.message);
      swal.fire({
        title: "An error occurred",
        text: `Your status of your payment failed to update, however your payment was successful. Invoice id: ${ulid}`,
        icon: "error",
      });
    }
  };

  const iframeLoaded = () => {
    setiframeLoading(false);
  };

  console.log("show rbc payment", showRBCPayment);
  return (
    <div className="w-full py-6 bg-white min-h-full flex">
      <div className="container mx-auto">
        {/* Editable Order Items List **************************/}
        <div className="flex items-center justify-start">
          <Link to="/shopping/cart" className="text-blue-500 underline text-sm px-3">
            Cart
          </Link>
          <p className="text-xs">
            <i className="fas fa-chevron-right"></i>
          </p>
          {/* <Link to="/shopping/options" className="text-blue-500 underline text-sm px-3">
            Shipping
          </Link>
          <p className="text-xs">
            <i className="fas fa-chevron-right"></i>
          </p> */}
          <p className="text-sm px-3">Checkout</p>
        </div>
        <div className="flex">
          <div
            className="w-3/4 flex flex-col p-5 bg-white rounded shadow-xl mx-auto"
            style={{ minHeight: 500 }}
          >
            {!showRBCPayment && (
              <div className="flex-1">
                <h2 className="text-gray-800 text-xl  p-3 border-b border-gray-300">
                  Payment Options
                </h2>
                <section class="text-gray-700 body-font">
                  <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col items-center text-center w-full mb-20">
                      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 pb-4 border-b border-gray-400">
                        Order {`#${orderNumber || "Loading..."}`}
                      </h1>
                      <h1 class="text-md title-font mb-4 text-gray-900">
                        Payment Required
                      </h1>
                      <div class="md:w-2/3 w-full flex justify-between border-t border-b mb-6 border-gray-300 py-2">
                        <span class="text-gray-500">Amount</span>
                        <span class="ml-auto text-gray-900">
                          $ {orderCost?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-wrap text-center w-full">
                      <div className="flex flex-col md:w-1/2 w-full border-2 border-gray-200 px-4 py-6 rounded-l">
                        <div className="flex-1 mt-4 text-center">
                          <p class="leading-relaxed">Pay with</p>
                          <h2 class="title-font font-medium text-2xl text-gray-900">
                            Credit Card
                          </h2>
                          <li className="inline-block m-3">
                            <img
                              src={require("../assets/img/mastercard.png")}
                              style={{ height: 50 }}
                              alt={""}
                            />
                          </li>
                          <li className="inline-block m-3">
                            <img
                              src={require("../assets/img/visa.png")}
                              style={{ height: 50 }}
                              alt={""}
                            />
                          </li>
                        </div>
                        <div
                          onClick={handlePayWithCard}
                          className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        >
                          Pay Now
                        </div>
                      </div>

                      <div className="flex flex-col md:w-1/2 w-full border-2 border-gray-200 px-4 py-6 rounded-l">
                        <div className="flex-1 mt-4 text-center">
                          <p class="leading-relaxed">Pay with</p>
                          <h2 class="title-font font-medium text-2xl text-gray-900">
                            mMoney
                          </h2>
                          <li className="inline-block m-3">
                            <img
                              src={require("../assets/img/mmoney.png")}
                              style={{ height: 50 }}
                              alt={""}
                            />
                          </li>
                        </div>
                        {window.mMoney && orderNumber && (
                          <div className="mr-2">
                            <MmoneyPayment
                              orderInfo={{
                                cost: orderCost,
                                id: orderNumber,
                              }}
                              onSuccess={handleMmoneySuccess}
                              onError={() => {
                                swal.fire({
                                  title: "An error occurred",
                                  text:
                                    "We were unable to process your card payment. Please try again",
                                  icon: "error",
                                });
                              }}
                            />
                          </div>
                        )}
                        {/* <button
                                    onClick={() => console.log("make payment")}
                                    disabled
                                    className="bg-blue-500 text-white hover:bg-blue-700 text-sm  px-6 py-3 rounded hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                  >
                                    Pay Now
                                  </button> */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            <div className={showRBCPayment ? "h-auto" : "h-0"}>
              <div>
                <>
                  {iframeLoading && <LoadingSpinner />}
                  <iframe
                    title="payment"
                    name="rbcIframe"
                    id="rbcIframe"
                    frameBorder="0"
                    className={
                      iframeLoading ? "invisible" : "visible w-full py-12 text-center"
                    }
                    allowFullScreen
                    onLoad={iframeLoaded}
                  />
                  <form
                    method="POST"
                    id="rbcForm"
                    action="https://pay1.plugnpay.com/pay/"
                    target="rbcIframe"
                  >
                    <input
                      type="hidden"
                      name="pt_gateway_account"
                      value={values.pt_gateway_account}
                    />
                    <input type="hidden" name="pt_transaction_amount" value={orderCost} />
                    <input
                      type="hidden"
                      name="pb_cards_allowed"
                      value="Visa,Mastercard"
                    />
                    <input
                      type="hidden"
                      name="pd_transaction_payment_type"
                      value="credit"
                    />
                    <input type="hidden" name="pt_currency" value="bbd" />
                    <input type="hidden" name="pt_billing_country" value="BB" />
                    <input
                      type="hidden"
                      name="pb_response_url"
                      value={`${process.env.REACT_APP_FIREBASE_FUNCTION_URL}/hooks/orders`}
                    />
                    <input
                      type="hidden"
                      name="pt_custom_name_1"
                      value="wifetch_order_id"
                    />
                    <input type="hidden" name="pt_billing_company" value="Wifetch" />
                    <input type="hidden" name="pt_custom_value_1" value={orderNumber} />
                    <input type="hidden" name="pb_transition_type" value="hidden" />
                  </form>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
