import React from "react";
import { Link } from "react-router-dom";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";

//<a href="https://www.freepik.com/free-photos-vectors/background">Background photo created by benzoix - www.freepik.com</a>

export default function About() {
  return (
    <>
      <Navbar transparent />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: "url('../assets/img/landing.png')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute bg-yellow-400"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-9/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-gray-800 uppercase font-semibold text-5xl">
                    Priority Delivery Service
                  </h1>
                  <p className="mt-4 text-2xl uppercase text-gray-600">
                    At your Fingertips
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <img
                    className="w-full rounded"
                    src={require("../assets/img/wifetch_grocery.jpg")}
                    alt="WiPharm"
                  />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Grocery</div>
                    <p className="text-gray-700 text-base">
                      Four free grocery deliveries with your subscription
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <img
                    className="w-full rounded"
                    src={require("../assets/img/wifetch_pharmacy.jpg")}
                    alt="WiPharm"
                  />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Pharmacy</div>
                    <p className="text-gray-700 text-base">
                      Two pharmaceutical deliveries with your subscription
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <img
                    className="w-full rounded"
                    src={require("../assets/img/wifetch_priority.jpg")}
                    alt="WiPharm"
                  />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Priority</div>
                    <p className="text-gray-700 text-base">
                      Be first in line before our pay per delivery customers
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("../assets/img/pending_delivery.png")}
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">Why Sign Up for Membership</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    WiFetch provides essential and non-essential delivery and personal
                    services island wide. It is a one-stop-shop service to cater to all
                    consumer and businesses purchases needs with the ethos of - whatever
                    you need; ‘WiFetch, WiDeliver'.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-truck"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Contactless Delivery</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-hard-hat"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Safeguarding you</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-icicles"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Refrigerated Delivery</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Let Us Keep you Safe. Let Us Fetch It.
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                  Membership to WiFetch costs just $100 a month and includes FOUR grocery
                  deliveries and TWO pharmaceutical deliveries. As a member you will
                  receive priority on all orders you make via our website. We make sure
                  you receive your goods ahead of the rest. That's our promise.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-gray-900">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
                  <div className="flex-auto p-5 lg:p-10 text-center items-center content-center justify-center">
                    <h4 className="text-2xl font-semibold mb-5">
                      Ready to become a Member?
                    </h4>

                    <Link
                      className="bg-blue-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      to="/contactus"
                      style={{ transition: "all .15s ease" }}
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
