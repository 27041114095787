import React from "react";

const ComingSoon = ({ title, subtitle }) => {
  return (
    <div className="text-center py-32 flex flex-col justify-center items-center">
      <h1 className="text-2xl uppercase font-bold text-gray-500">{title}</h1>
      <h2 className="text-xl py-3 text-gray-400">{subtitle}</h2>
    </div>
  );
};

export default ComingSoon;
