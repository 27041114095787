import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { AuthContext } from "./context/Auth";

const PrivateRoute = (props) => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { redirectedFrom: props.location.pathname },
      }}
    />
  );
};

export default PrivateRoute;
