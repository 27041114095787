import React, { useEffect, useState } from "react";

import app from "../config/firebase.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    // app.auth().onAuthStateChanged(setCurrentUser);
    app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const db = app.firestore();
        const ref = db.collection("customers").doc(user.uid);

        await ref
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              var isAdmin = false;
              var isMerchant = false;
              //Check if user is admin
              await app
                .auth()
                .currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                  if (idTokenResult.claims.admin) {
                    // Show admin UI.
                    isAdmin = true;
                  }
                  if (idTokenResult.claims.merchant) {
                    // Show merchant dashboard UI.
                    isMerchant = true;
                  }
                });

              setCurrentUser({
                uid: user.uid,
                email: user.email,
                name: user.displayName,
                admin: isAdmin,
                merchant: isMerchant,
                isEmailVerified: user.emailVerified,
                token: user.refreshToken,
              });
            } else {
              // doc.data() will be undefined in this case

              isAdmin = false;
              isMerchant = false;
              //Check if user is admin
              await app
                .auth()
                .currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                  if (idTokenResult.claims.admin) {
                    // Show admin UI.
                    isAdmin = true;
                  }

                  if (idTokenResult.claims.merchant) {
                    // Show merchant dashboard UI.
                    isMerchant = true;
                  }
                });

              setCurrentUser({
                uid: user.uid,
                email: user.email,
                name: user.displayName,
                admin: isAdmin,
                merchant: isMerchant,
                isEmailVerified: user.emailVerified,
                token: user.refreshToken,
              });
            }
          })
          .catch(function (error) {
            //console.log("Error getting document:", error);
          });
      } else {
        setCurrentUser(user);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {typeof currentUser === "undefined" ? null : children}
    </AuthContext.Provider>
  );
};
