import useYup from "@usereact/use-yup";
import React, { useEffect, useState } from "react";

// import { Map, TileLayer, Marker, Popup } from "react-leaflet";
// import ReactLeafletSearch from "react-leaflet-search";
import * as yup from "yup";
import firebase from "../config/firebase";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  order_contact_name: yup.string().required("Field is required."),
  order_contact_number: yup.string().required("Field is required."),
  order_contact_email: yup.string().required("Field is required."),
  order_directions: yup.string().required("Field is required."),
  order_dropofflocation: yup.string().required("Field is required."),
  order_country: yup.string().required("Country is required"),
  // order_contact_parish: yup.string().required("Parish is required"),
});

const parishes = {
  Barbados: [
    "Christ Church",
    "St Philip",
    "St Michael",
    "St George",
    "St John",
    "St James",
    "St Thomas",
    "St Joseph",
    "St Peter",
    "St Andrew",
    "St Lucy",
  ],
  Trinidad: [
    "Caroni",
    "Mayaro",
    "Nariva",
    "Saint Andrew",
    "Saint David",
    "Saint George",
    "Saint Patrick",
    "Victoria",
  ],
  Guyana: ["Essequibo", "Demerara", "Berbice"],
  Jamaica: [
    "Clarendon",
    "Hanover",
    "Kingston",
    "Manchester",
    "Portland",
    "Saint Andrew",
    "Saint Ann",
    "Saint Catherine",
    "Saint Elizabeth",
    "Saint James",
    "Saint Mary",
    "Saint Thomas",
    "Trelawny",
    "Westmoreland",
  ],
};
const DirectionsForm = (props) => {
  const [countryList, setCountryList] = useState([]);

  // const mapCenter = [13.1334144, -59.6246528];
  // const [markerPosition, setMarkerPosition] = useState(mapCenter);
  const [values, setValues] = useState({
    order_contact_email: "",
    order_contact_name: "",
    order_contact_number: "",
    order_directions: "",
    order_dropofflocation: "",
    order_contact_parish: "",
    order_country: "",
  });

  const { errors } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  useEffect(() => {
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.orderInfo) {
      const {
        order_contact_name,
        order_contact_email,
        order_contact_number,
        order_contact_parish,
        order_directions,
        order_country,
        order_dropofflocation,
      } = props.orderInfo;
      const order = {
        order_contact_name,
        order_contact_email,
        order_contact_number,
        order_contact_parish,
        order_directions,
        order_country,
        order_dropofflocation,
      };

      setValues(order);
    }

    // getDirectionsFromStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderInfo]);

  useEffect(() => {
    setValues({
      ...values,
      order_contact_name: props.userInfo.user_name,
      order_contact_email: props.userInfo.user_email,
      order_contact_number: props.userInfo.user_mobile_number,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userInfo]);

  const fetchCountries = async () => {
    const db = firebase.firestore();

    const dataRef = await db.collection("countries").orderBy("country_name").get();

    const dataCollection = dataRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setCountryList(dataCollection);
  };

  // const getDirectionsFromStorage = () => {
  //   let orderItems = localStorage.getItem("orderItems");
  //   orderItems = JSON.parse(orderItems);
  //   setValues({
  //     ...values,
  //     order_directions: orderItems.directions,
  //     order_dropofflocation: orderItems.pickuplocation,
  //   });

  //   props.handleUpdate({
  //     order_directions: orderItems.directions,
  //     order_dropofflocation: orderItems.pickuplocation,
  //   });
  // };

  const handleChange = (e) => {
    let { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    // validate();
    props.handleChange(e);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   validate();

  //   props.onAdd({
  //     directions: values.order_directions,
  //     dropOfflocation: values.order_dropofflocation,
  //     // latitude: markerPosition[0],
  //     // longitude: markerPosition[1],
  //   });
  // };

  // const updatePosition = () => {
  //   const marker = markerRef.current;
  //   if (marker != null) {
  //     const newPosition = marker.leafletElement.getLatLng();
  //     setMarkerPosition([newPosition.lat, newPosition.lng]);
  //   }
  // };

  // const myPopup = (SearchInfo) => {
  //   return (
  //     <Popup>
  //       <div>
  //         <p>I am a custom popUp</p>
  //         <p>latitude and longitude from search component: lat:{SearchInfo.latLng[0]} lng:{SearchInfo.latLng[1]}</p>
  //         <p>Info from search component: {SearchInfo.info}</p>
  //         <p>{JSON.stringify(SearchInfo.raw)}</p>
  //       </div>
  //     </Popup>
  //   );
  // }

  // const { latitude, longitude, error } = usePosition();
  // const markerRef = useRef();
  // const mapZoom = 11;

  const displayFieldErrors = (field_name) => {
    return (
      values[field_name]?.length > 0 &&
      errors[field_name] && <p className="text-red-500 text-xs">{errors[field_name]}</p>
    );
  };

  // console.log(values);

  return (
    // <div className="shadow flex content-center items-center justify-center mt-16 px-3 py-4 bg-white border border-gray-300 rounded">
    <div>
      <div className="text-left">
        {/*  <p className="text-sm py-3">
          Please drag the map marker to indicate the dropoff location
        </p>

        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Add a dropoff location
        </label>
        

        <Map center={mapCenter} zoom={mapZoom}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <ReactLeafletSearch
            className="custom-style"
            position="topleft"
            mapStateModifier="flyTo"
            inputPlaceholder="The default text in the search bar"
            showMarker={true}
            showPopup={true}
            openSearchOnLoad={false}
            closeResultsOnClick={false}
            popUp={myPopup}
          />
          <Marker
            ref={markerRef}
            draggable={true}
            onDragend={updatePosition}
            position={markerPosition}
          ></Marker>
        </Map> */}

        <form>
          <div className="text-gray-800 text-xl text-left">Contact Information</div>
          <div className="flex flex-col md:flex-row">
            <div className="mt-2 md:inline-block md:pr-2 md:w-1/2">
              <label className="block text-sm text-gray-600">Your Name</label>
              <input
                value={values.order_contact_name}
                onChange={handleChange}
                className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                id="order_contact_name"
                name="order_contact_name"
                type="text"
                required=""
                placeholder="What is your name?"
              />
              {displayFieldErrors("order_contact_name")}
            </div>
            <div className="mt-2 md:inline-block md:pr-2 md:w-1/2">
              <label className="block text-sm text-gray-600">Your Email</label>
              <input
                value={values.order_contact_email}
                onChange={handleChange}
                className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
                id="order_contact_email"
                name="order_contact_email"
                type="text"
                required=""
                placeholder="What is your email?"
              />

              {displayFieldErrors("order_contact_email")}
            </div>
          </div>

          <div className="mt-2 md:inline-block md:pr-2 md:w-1/2">
            <label className="block text-sm text-gray-600">Your Mobile Number</label>
            <input
              value={values.order_contact_number}
              onChange={handleChange}
              className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
              id="order_contact_number"
              name="order_contact_number"
              type="text"
              required=""
              placeholder="What is your number?"
            />

            {displayFieldErrors("order_contact_number")}
          </div>

          <div className="mt-2 md:inline-block md:w-1/2">
            <label className="block text-sm text-gray-600">Country</label>
            <div className="relative">
              <select
                name="order_country"
                value={values.order_country}
                onChange={handleChange}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight"
              >
                <option value="">--Select Country--</option>
                {countryList.map(({ country_name }, index) => (
                  <option key={index} value={country_name}>
                    {country_name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {displayFieldErrors("order_country")}
          </div>

          <div className="mt-2 md:inline-block md:w-1/2">
            <label className="block text-sm text-gray-600">Parish</label>
            <div className="relative">
              <select
                name="order_contact_parish"
                // value={values.order_contact_parish}
                value={values.order_contact_parish}
                onChange={handleChange}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight"
              >
                <option value="">--Select One--</option>
                {parishes[values.order_country] &&
                  parishes[values.order_country].map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            {displayFieldErrors("order_contact_parish")}
          </div>

          <div className="my-3 text-gray-800 text-xl text-left">Delivery Information</div>

          <div className="mt-2 ">
            <label className="block text-sm text-gray-600">DropOff Location</label>
            <input
              value={values.order_dropofflocation}
              onChange={handleChange}
              className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
              id="order_dropofflocation"
              name="order_dropofflocation"
              type="text"
              required=""
              placeholder="Where to dropoff?"
            />

            {displayFieldErrors("order_dropofflocation")}
          </div>

          <div className="mt-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Additional directions
            </label>
            <textarea
              name="order_directions"
              value={values.order_directions}
              onChange={(e) => handleChange(e)}
              className="w-full px-5  py-2 text-gray-700 bg-gray-200 rounded focus:outline-none focus:shadow-outline"
              placeholder="Provide any additional helpful instructions"
              rows="3"
            ></textarea>
            {displayFieldErrors("order_directions")}
          </div>
        </form>

        {/* <form className="w-full" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3 my-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Add a drop-off location
              </label>
              <input
                name="order_dropofflocation"
                type="text"
                value={values.order_dropofflocation}
                onChange={(e) => handleChange(e)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Type the drop-off location"
                rows="3"
              />
              {errors.order_dropofflocation && (
                <p className="text-red-500 text-xs italic">
                  {errors.order_dropofflocation}
                </p>
              )}
            </div>
            <div className="w-full px-3 my-6 md:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Additional directions
              </label>
              <textarea
                name="order_directions"
                value={values.order_directions}
                onChange={(e) => handleChange(e)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Provide any additional helpful instructions"
                rows="3"
              ></textarea>
              {errors.order_directions && (
                <p className="text-red-500 text-xs italic">{errors.order_directions}</p>
              )}
            </div>
          </div>
        </form> */}
      </div>
    </div>
  );
};

export default DirectionsForm;
