import useYup from "@usereact/use-yup";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";

import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import app from "../config/firebase.js";
// import { AuthContext } from "../context/Auth.js";

const validationSchema = yup.object().shape({
  companyName: yup.string().required(),
  companyEmail: yup.string().email().required(),
  userPassword: yup.string().min(6).required("Field is required"),
});

const MerchantRegistration = ({ history, match }) => {
  const initialState = {
    companyName: "",
    companyEmail: "",
    userPassword: "",
  };

  const [values, setValues] = useState(initialState);

  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");

  const { errors, validate } = useYup(values, validationSchema, {
    validateOnChange: true,
  });

  const fetchData = async () => {
    const db = app.firestore();
    //const ref = db.collection("customers")
    const userToken = match.params.token;

    let merchantInfo = await db
      .collection("merchants")
      .doc(userToken)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return { ...doc.data(), id: doc.id };
        }
      });
    // let merchantInfo = merchantRecords.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    //Display error if merchantInfo is not found

    if (merchantInfo) {
      setValues({
        companyName: merchantInfo.companyName,
        companyEmail: merchantInfo.companyEmail,
        userPassword: "",
      });
    } else {
      setFormError("Sorry, an error has occurred. Please try again or contact us");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    validate();
    setValues({
      ...values,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormError("");
    setValues(initialState);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const { email, password } = values;
    validate().then(async ({ errors, isValid }) => {
      if (isValid) {
        try {
          //Send email via firebase function
          await axios.post(
            process.env.REACT_APP_FIREBASE_FUNCTION_URL + "/registermerchant",
            {
              user_email: values.companyEmail,
              user_password: values.userPassword,
            }
          );

          setFormSuccess(
            "Thanks for your message! We will get back to you within 1 or 2 business days."
          );
          resetForm();
        } catch (error) {
          setFormError("An error has occurred, please try again");
        }
      } else {
        setFormError("Please fill in all required fields");
      }
    });
  };

  return (
    <>
      <Navbar transparent />
      <main className="bg-yellow-400">
        <section className="absolute w-full h-full">
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full md:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                  <div className="rounded-t mb-0 px-6 py-3">
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-xl font-bold">
                        Merchant Registration
                      </h6>

                      {formSuccess ? (
                        <div>
                          <div
                            className="w-100 m-3 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                            role="alert"
                          >
                            <strong className="font-bold">Success!</strong>
                            <span className="block sm:inline"> {formSuccess}</span>
                          </div>
                          <div>
                            <Link
                              className="mt-3 bg-blue-500 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                              type="button"
                              to="/merchants/dashboard"
                            >
                              Manage your Store
                            </Link>
                          </div>
                        </div>
                      ) : formError ? (
                        <div
                          className="w-100 m-3 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                          role="alert"
                        >
                          <strong className="font-bold">Error!</strong>
                          <span className="block sm:inline"> {formError}</span>
                        </div>
                      ) : (
                        <p className="text-gray-600 text-md font-light pt-3">
                          Please complete your registration by choosing a password
                        </p>
                      )}
                    </div>
                  </div>

                  {values && !formSuccess && (
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                      <form onSubmit={handleSubmit}>
                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                            Company Name
                          </label>
                          <input
                            type="text"
                            name="companyName"
                            className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                            placeholder="Company Name"
                            value={values.companyName}
                            disabled
                            onChange={(e) => handleChange(e)}
                            style={{ transition: "all .15s ease" }}
                          />
                          {errors.companyName && (
                            <p className="text-red-500 text-xs italic">
                              {errors.companyName}
                            </p>
                          )}
                        </div>

                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                            Email
                          </label>
                          <input
                            type="email"
                            name="companyEmail"
                            className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-500 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                            placeholder="Email"
                            disabled
                            value={values.companyEmail}
                            onChange={(e) => handleChange(e)}
                            style={{ transition: "all .15s ease" }}
                          />
                          {errors.companyEmail && (
                            <p className="text-red-500 text-xs italic">
                              {errors.companyEmail}
                            </p>
                          )}
                        </div>

                        <div className="relative w-full mb-3">
                          <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                            Password
                          </label>
                          <input
                            type="password"
                            name="userPassword"
                            className="px-3 py-3 placeholder-gray-400 bg-gray-200 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                            placeholder="Password"
                            value={values.userPassword}
                            onChange={(e) => handleChange(e)}
                            style={{ transition: "all .15s ease" }}
                          />
                          {errors.userPassword && (
                            <p className="text-red-500 text-xs italic">
                              {errors.userPassword}
                            </p>
                          )}
                        </div>

                        <div className="text-center mt-6">
                          <button
                            className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-6 w-full"
                            type="submit"
                            style={{ transition: "all .15s ease" }}
                          >
                            Register
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default MerchantRegistration;
