import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import firebase from "../config/firebase";
import { AuthContext } from "../context/Auth";
import { StoreContext } from "../context/store";

const Navbar = (props) => {
  const [countryList, setCountryList] = useState([]);
  const [searchText, setSearchText] = useState("");

  const pageLocation = useLocation();
  const history = useHistory();

  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { currentUser } = auth;

  //This location hook allows using the react-router path from any component
  let location = useLocation();

  const {
    customerOrder,
    countryFilter,
    setCountryFilter,
    // searchFilter,
    setSearchFilter,
  } = store;

  useEffect(() => {
    console.log(customerOrder, countryFilter);
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    // validate();
    setSearchText(value.toLowerCase());
  };

  const fetchCountries = async () => {
    const db = firebase.firestore();

    const dataRef = await db.collection("countries").orderBy("country_name").get();

    const dataCollection = dataRef.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setCountryList(dataCollection);
  };

  const handleSearch = (searchText) => {
    // console.log(useLocation())
    console.log(pageLocation);
    setSearchFilter(searchText);
    if (pageLocation.pathname !== "/search") {
      history.push("/search");
    }
  };

  console.log(countryList);

  return (
    <nav
      className={
        (props.transparent ? "top-0 absolute bg-yellow-400 " : "bg-white  shadow-lg") +
        " p-2 mt-0 w-full relative"
      }
    >
      <div className="container mx-auto flex flex-wrap items-center">
        <div className="flex flex-col md:flex-row w-full md:w-2/3 items-center content-center justify-center md:justify-start">
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center">
              {/* <img
                src={require("../assets/icons/undplogo.png")}
                style={{ height: 50, padding: "0 1em" }}
                alt="nav bar logo"
              /> */}
              <Link
                to="/"
                className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
              >
                <span className="text-xl font-extrabold">
                  <span className="font-light">
                    W<span className="lowercase">i</span>
                  </span>
                  Fetch
                </span>
              </Link>
            </div>
            <div className="flex-1 flex items-center justify-end md:mr-16 ">
              {/* {!currentUser && (
                <Link
                  className={
                    "md:hidden text-sm font-semibold rounded shadow hover:shadow-md outline-none focus:outline-none py-2 px-4 rounded-r"
                  }
                  to={{
                    pathname: "/login",
                    state: { redirectedFrom: location.pathname },
                  }}
                  style={{ transition: "all .15s ease" }}
                >
                  Sign In
                </Link>
              )} */}
              {/* <div className="relative ml-6 my-2">

                <div className="absolute pin-r pin-t mt-3 mr-4 text-purple-lighter">


                </div>
              </div> */}
              <div className="relative">
                <input
                  type="search"
                  className="bg-purple-white border border-gray-400 rounded-full py-1 px-3"
                  placeholder="Search Store Name..."
                  value={searchText}
                  onChange={handleChange}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center  text-gray-700"
                  onClick={() => handleSearch(searchText)}
                  // onClick={() => setSearchFilter(searchText)}
                >
                  <img
                    className=""
                    style={{ height: 30 }}
                    src={require(`../assets/icons/search.svg`)}
                    alt="cart SVG"
                  />
                  {/* <svg className="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style={{ enableBackground: 'new 0 0 56.966 56.966' }} width="512px" height="512px">
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg> */}
                </div>
              </div>
              <Link
                className="md:hidden flex text-sm font-semibold outline-none focus:outline-none py-2 px-4"
                to="/shopping/cart"
              >
                <img
                  className="inline-block pr-2"
                  style={{ height: 20 }}
                  src={require(`../assets/icons/cart.svg`)}
                  alt="cart SVG"
                />
                {customerOrder ? customerOrder.cartItems?.length : 0}
              </Link>
              {/* <Link
                className={
                  "text-sm font-semibold rounded shadow hover:shadow-md outline-none focus:outline-none py-2 px-4 rounded-r"
                }
                to="/signup"
                style={{ transition: "all .15s ease" }}
              >
                Sign Up
              </Link> */}
            </div>
          </div>
        </div>

        <div className="flex w-full content-center justify-between md:w-1/3 md:justify-end">
          <ul
            className={
              (props.transparent ? "text-gray-800" : "text-gray-600") +
              " list-reset flex justify-around flex-1 md:flex-none items-center"
            }
          >
            <li className="">
              <select
                value={countryFilter}
                onChange={(e) => setCountryFilter(e.target.value)}
                className="bg-purple-white border border-gray-400 rounded-full py-2 px-3"
              >
                <option value="">--All--</option>
                {countryList.map(({ country_name }, index) => (
                  <option key={index}>{country_name}</option>
                ))}
              </select>
            </li>
            <li className="">
              <Link
                className={
                  "inline-block py-2 px-4 rounded-lg " +
                  (props.transparent ? "hover:bg-yellow-500" : "hover:bg-gray-400")
                }
                to="/deliveries"
              >
                Delivery
              </Link>
            </li>
            {!currentUser ? (
              <>
                <li className="flex">
                  <Link
                    className={
                      "rounded outline-none focus:outline-none  py-2 px-4 rounded-l"
                    }
                    to={{
                      pathname: "/login",
                      state: { redirectedFrom: location.pathname },
                    }}
                    style={{ transition: "all .15s ease" }}
                  >
                    Login
                  </Link>
                  {/* <Link
                    className={
                      "rounded outline-none focus:outline-none  py-2 px-4 rounded-l"
                    }
                    to="/signup"
                    style={{ transition: "all .15s ease" }}
                  >
                    Sign Up
                  </Link> */}
                  <Link
                    className="flex hidden md:inline text-sm font-semibold outline-none focus:outline-none py-2 px-4"
                    to="/shopping/cart"
                  >
                    <img
                      className="inline-block pr-2"
                      style={{ height: 20 }}
                      src={require(`../assets/icons/cart.svg`)}
                      alt="cart SVG"
                    />
                    {customerOrder ? customerOrder.cartItems?.length : 0}
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="mr-3">
                  <div className="dropdown inline-block relative">
                    {/* <span className='text-sm'>Welcome back, {currentUser.email}</span> */}
                    <button
                      className={
                        "py-2 px-4 rounded inline-flex items-center rounded-lg " +
                        (props.transparent
                          ? "text-gray-800 hover:bg-yellow-500"
                          : "text-gray-600 hover:bg-gray-400")
                      }
                    >
                      <span className="mr-1 ">
                        <p>Account</p>
                      </span>
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                      </svg>
                    </button>
                    <div className="dropdown-menu hidden absolute right-0 shadow-lg z-50">
                      <ul className="w-full mt-2 origin-top-right rounded-md md:w-48 bg-white rounded-md shadow text-gray-700">
                        <li className="">
                          <Link
                            className="hover:bg-gray-400  rounded-t py-2 px-4 block whitespace-no-wrap"
                            to="/users/profile"
                          >
                            Profile
                          </Link>
                        </li>
                        {/* <li className="">
                            <Link
                              className="hover:bg-gray-400  rounded-t py-2 px-4 block whitespace-no-wrap"
                              to="/users/orders"
                            >
                              Orders
                            </Link>
                          </li> */}
                        {currentUser.merchant && !currentUser.admin && (
                          <li className="">
                            <Link
                              className="hover:bg-gray-400  py-2 px-4 block whitespace-no-wrap"
                              to="/merchants/dashboard"
                            >
                              Manage Store
                            </Link>
                          </li>
                        )}
                        {currentUser.admin && (
                          <li className="">
                            <Link
                              className="hover:bg-gray-400  py-2 px-4 block whitespace-no-wrap"
                              to="/users/dashboard"
                            >
                              Dashboard
                            </Link>
                          </li>
                        )}
                        <li className="">
                          <button
                            className="w-full text-left rounded-b hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                            onClick={() => {
                              //Clear customer order
                              firebase.auth().signOut(); //Sign out user
                            }}
                          >
                            Sign Out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <Link
                  className="flex hidden md:inline text-sm font-semibold outline-none focus:outline-none py-2 px-4"
                  to="/shopping/cart"
                >
                  <img
                    className="inline-block pr-2"
                    style={{ height: 20 }}
                    src={require(`../assets/icons/cart.svg`)}
                    alt="cart SVG"
                  />
                  {customerOrder ? customerOrder.cartItems?.length : 0}
                </Link>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
